import Send from '../../cp.client.js'

export default {
    getHandWritingList (param) {
        return Send({
            url: '/cp/handWriting/list',
            method: 'get',
            params: {
                porCtr: param.porCtr,
                porPlc: param.porPlc,
                dlyCtr: param.dlyCtr,
                dlyPlc: param.dlyPlc,
                itemGrp: param.itemGrp,
                item: param.item,
                bkgShipper: param.bkgShipper,
                actShipper: param.actShipper,
                hcneShipper: param.hcneShipper,
                cneeShipper: param.cneeShipper,
                profile: param.profile,
                availDate: param.availDate
            }
        })
    },
    saveHandWriting (data) {
        const req = {
            list: []
        }
        req.list = data
        return Send({
            url: '/cp/handWriting/save',
            method: 'put',
            data: req
        })
    },
    deleteHandWriting (data) {
        const req = {
            list: []
        }
        req.list = data
        return Send({
            url: '/cp/handWriting/delete',
            method: 'put',
            data: req
        })
    }
}
