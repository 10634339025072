var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content full" },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("cp.CTRL010G030.001"))),
      ]),
      _c("div", { staticClass: "content_box mt10 clearfix" }, [
        _c("div", { staticClass: "col_5 float_left pr20" }, [
          _c("h2", { staticClass: "content_title" }, [
            _vm._v(_vm._s(_vm.$t("cp.CTRL010G030.002"))),
          ]),
          _c("table", { staticClass: "tbl_row" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL010G030.003")))]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.factorNm,
                        expression: "factorNm",
                      },
                    ],
                    ref: "factorNmInput",
                    staticClass: "wid200",
                    attrs: { type: "text" },
                    domProps: { value: _vm.factorNm },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.factorNm = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("td", { staticClass: "border_left_none text_right" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button blue sh",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.search.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cp.COMMON.001")))]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", {
            staticClass: "mt10",
            staticStyle: { width: "100%", height: "581px" },
            attrs: { id: "realgrid" },
          }),
        ]),
        _c("div", { staticClass: "col_5 float_right" }, [
          _c("h2", { staticClass: "content_title" }, [_vm._v(" ")]),
          _c("table", { staticClass: "tbl_row" }, [
            _vm._m(1),
            _c("tbody", [
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("cp.CTRL010G030.004")) + " "),
                  _c("span", [_vm._v("*")]),
                ]),
                _c("td", { attrs: { colspan: "11" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.factorCd,
                        expression: "send.factorCd",
                      },
                    ],
                    ref: "factorCdInput",
                    attrs: {
                      id: "factorCd",
                      type: "text",
                      readonly: !_vm.send.exist,
                    },
                    domProps: { value: _vm.send.factorCd },
                    on: {
                      blur: _vm.factorCdVali,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "factorCd", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("cp.CTRL010G030.003")) + " "),
                  _c("span", [_vm._v("*")]),
                ]),
                _c("td", { attrs: { colspan: "5" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.factorNm,
                        expression: "send.factorNm",
                      },
                    ],
                    ref: "factorNm",
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.factorNm },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "factorNm", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("cp.CTRL010G030.005")) + " "),
                  _c("span", [_vm._v("*")]),
                ]),
                _c("td", { attrs: { colspan: "5" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.factorEnm,
                        expression: "send.factorEnm",
                      },
                    ],
                    ref: "factorEnm",
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.factorEnm },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "factorEnm", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_center", attrs: { colspan: "12" } },
                  [_vm._v(_vm._s(_vm.$t("cp.CTRL010G030.006")))]
                ),
              ]),
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("cp.CTRL010G030.007")) + " "),
                  _c("span", [_vm._v("*")]),
                ]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.send.periodUseYn,
                          expression: "send.periodUseYn",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.send,
                            "periodUseYn",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _c("option", { attrs: { value: "Y" } }, [_vm._v("Y")]),
                      _c("option", { attrs: { value: "N" } }, [_vm._v("N")]),
                    ]
                  ),
                ]),
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("cp.CTRL010G030.010")) + " "),
                  _c("span", [_vm._v("*")]),
                ]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.send.inputUseYn,
                          expression: "send.inputUseYn",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.send,
                            "inputUseYn",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _c("option", { attrs: { value: "Y" } }, [_vm._v("Y")]),
                      _c("option", { attrs: { value: "N" } }, [_vm._v("N")]),
                    ]
                  ),
                ]),
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("cp.CTRL010G030.011")) + " "),
                  _c("span", [_vm._v("*")]),
                ]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.send.routeLimitYn,
                          expression: "send.routeLimitYn",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.send,
                            "routeLimitYn",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _c("option", { attrs: { value: "Y" } }, [_vm._v("Y")]),
                      _c("option", { attrs: { value: "N" } }, [_vm._v("N")]),
                    ]
                  ),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL010G030.012")))]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.send.task,
                          expression: "send.task",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.send,
                            "task",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.taskList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.detailCd } },
                          [_vm._v(_vm._s(item.detailNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_center", attrs: { colspan: "12" } },
                  [
                    _vm._v(_vm._s(_vm.$t("cp.CTRL010G030.013")) + " "),
                    _c("span", [_vm._v("*")]),
                  ]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "border_left_none", attrs: { colspan: "4" } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.send.refTable1,
                          expression: "send.refTable1",
                        },
                      ],
                      ref: "refTable1",
                      attrs: { type: "text" },
                      domProps: { value: _vm.send.refTable1 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.send, "refTable1", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
                _c("td", { attrs: { colspan: "4" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.refTable2,
                        expression: "send.refTable2",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.refTable2 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "refTable2", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("td", { attrs: { colspan: "4" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.refTable3,
                        expression: "send.refTable3",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.refTable3 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "refTable3", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_center", attrs: { colspan: "12" } },
                  [
                    _vm._v(_vm._s(_vm.$t("cp.CTRL010G030.014")) + " "),
                    _c("span", [_vm._v("*")]),
                  ]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "border_left_none", attrs: { colspan: "4" } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.send.refColumn1,
                          expression: "send.refColumn1",
                        },
                      ],
                      ref: "refColumn1",
                      attrs: { type: "text" },
                      domProps: { value: _vm.send.refColumn1 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.send, "refColumn1", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
                _c("td", { attrs: { colspan: "4" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.refColumn2,
                        expression: "send.refColumn2",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.refColumn2 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "refColumn2", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("td", { attrs: { colspan: "4" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.refColumn3,
                        expression: "send.refColumn3",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.refColumn3 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "refColumn3", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "border_left_none", attrs: { colspan: "4" } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.send.refColumn4,
                          expression: "send.refColumn4",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.send.refColumn4 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.send, "refColumn4", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
                _c("td", { attrs: { colspan: "4" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.refColumn5,
                        expression: "send.refColumn5",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.refColumn5 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "refColumn5", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("td", { attrs: { colspan: "4" } }),
              ]),
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_center", attrs: { colspan: "12" } },
                  [_vm._v(_vm._s(_vm.$t("cp.CTRL010G030.015")))]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "border_left_none", attrs: { colspan: "4" } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.send.refWhere1,
                          expression: "send.refWhere1",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.send.refWhere1 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.send, "refWhere1", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
                _c("td", { attrs: { colspan: "4" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.refWhere2,
                        expression: "send.refWhere2",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.refWhere2 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "refWhere2", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("td", { attrs: { colspan: "4" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.refWhere3,
                        expression: "send.refWhere3",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.refWhere3 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "refWhere3", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_center", attrs: { colspan: "12" } },
                  [_vm._v(_vm._s(_vm.$t("cp.CTRL010G030.016")))]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "border_left_none", attrs: { colspan: "4" } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.send.refGroup1,
                          expression: "send.refGroup1",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.send.refGroup1 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.send, "refGroup1", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
                _c("td", { attrs: { colspan: "4" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.refGroup2,
                        expression: "send.refGroup2",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.refGroup2 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "refGroup2", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("td", { attrs: { colspan: "4" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.refGroup3,
                        expression: "send.refGroup3",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.refGroup3 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "refGroup3", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("cp.CTRL010G030.017")) + " "),
                  _c("span", [_vm._v("*")]),
                ]),
                _c("td", { attrs: { colspan: "11" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.logic1,
                        expression: "send.logic1",
                      },
                    ],
                    ref: "logic1",
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.logic1 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "logic1", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL010G030.018")))]),
                _c("td", { attrs: { colspan: "11" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.logic2,
                        expression: "send.logic2",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.logic2 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "logic2", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("cp.CTRL010G030.019")) + " "),
                  _c("span", [_vm._v("*")]),
                ]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.send.factorType,
                          expression: "send.factorType",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.send,
                            "factorType",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.factorTypeList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.detailCd } },
                          [_vm._v(_vm._s(item.detailNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL010G030.020")))]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.caseValue,
                        expression: "send.caseValue",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.caseValue },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "caseValue", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL010G030.021")))]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.caseUnit,
                        expression: "send.caseUnit",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.caseUnit },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "caseUnit", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("cp.CTRL010G030.022")) + " "),
                  _c("span", [_vm._v("*")]),
                ]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.send.queryType,
                          expression: "send.queryType",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.send,
                            "queryType",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.queryTypeList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.detailCd } },
                          [_vm._v(_vm._s(item.detailNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL010G030.023")))]),
                _c("td", { attrs: { colspan: "5" } }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.remark,
                        expression: "send.remark",
                      },
                    ],
                    domProps: { value: _vm.send.remark },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "remark", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL010G030.024")))]),
                _c("td", { attrs: { colspan: "5" } }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.remarkEn,
                        expression: "send.remarkEn",
                      },
                    ],
                    domProps: { value: _vm.send.remarkEn },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "remarkEn", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "flex_box mt10" }, [
        _c(
          "a",
          {
            staticClass: "button blue lg mr5 ml_auto",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.new_init.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("cp.COMMON.003")))]
        ),
        _c(
          "a",
          {
            staticClass: "button blue lg mr5",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.save.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("cp.COMMON.004")))]
        ),
        _c(
          "a",
          {
            staticClass: "button blue lg",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.deleteFactor.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("cp.COMMON.010")))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "100px" } }),
      _c("col"),
      _c("col", { attrs: { width: "100px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "110" } }),
      _c("col"),
      _c("col"),
      _c("col", { attrs: { width: "110" } }),
      _c("col"),
      _c("col"),
      _c("col", { attrs: { width: "110" } }),
      _c("col"),
      _c("col"),
      _c("col", { attrs: { width: "110" } }),
      _c("col"),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }