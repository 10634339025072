import Send from '../../cp.client.js'

export default {
  scenarioActionList (scenarioCd) {
      return Send({
          url: '/cp/action/list',
          method: 'get',
          params: {
              scenarioCd: scenarioCd
          }
      })
  },
  saveScenarioAction (data) {
      return Send({
          url: '/cp/action/save',
          method: 'put',
          data: data
      })
  },
  saveScenarioConfirmed (scenarioCd) {
      return Send({
          url: '/cp/action/confirmed',
          method: 'put',
          params: {
              scenarioCd: scenarioCd
          }
      })
  },
  selectRvsdDpoDt (data) {
      return Send({
          url: '/cp/action/selectRvsdDpoDt',
          method: 'put',
          data: data
      })
  },
  selectCloseDt (data) {
    return Send({
      url: '/cp/action/selectCloseDt',
      method: 'put',
      data: data
    })
  },
  selectPopFreRateNo (data) {
    return Send({
      url: '/cp/action/selectPopFreRateNo',
      method: 'put',
      data: data
    })
  }
}
