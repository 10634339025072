var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content full" },
    [
      _c("e-breadcrumbs"),
      _c(
        "win-layer-pop",
        { staticClass: "sample_popup_list" },
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.parentInfo },
                on: { close: _vm.closePopup },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.001"))),
      ]),
      _c("div", { staticClass: "content_box mt10" }, [
        _c("table", { staticClass: "tbl_search" }, [
          _vm._m(0),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.002")))]),
              _c("td", [_vm._v(_vm._s(_vm.scenarioCd))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.003")))]),
              _c("td", [_vm._v(_vm._s(_vm.targetInfo.scenarioNm))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.004")))]),
              _c("td", [_vm._v(_vm._s(_vm.targetInfo.createUser))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.005")))]),
              _c("td", [_vm._v(_vm._s(_vm.targetInfo.category))]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content_box mt10" }, [
        _c("h2", { staticClass: "content_title" }, [
          _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.006"))),
        ]),
        _c("table", { staticClass: "tbl_col mt10" }, [
          _vm._m(1),
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.007")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.008")))]),
              _c("th", [
                _c("div", [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("cp.CTRL020G080.009").replace("\n", "<br>")
                      ),
                    },
                  }),
                  _vm._v("  "),
                  _c("span", { staticClass: "position_relative" }, [
                    _c(
                      "button",
                      {
                        staticClass: "tbl_icon help",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.isShowHelp = !_vm.isShowHelp
                          },
                        },
                      },
                      [_vm._v("help")]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowHelp,
                            expression: "isShowHelp",
                          },
                        ],
                        staticClass: "tooltip_wrap short position_absolute",
                        staticStyle: {
                          "min-width": "200px",
                          left: "0",
                          top: "24px",
                        },
                      },
                      [
                        _c("div", { staticClass: "cont" }, [
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.isShowHelp = false
                                },
                              },
                            },
                            [_vm._v("close")]
                          ),
                          _c(
                            "ul",
                            {
                              staticClass: "bul_list_sm t2",
                              staticStyle: { "white-space": "nowrap" },
                            },
                            [
                              _c("li", [
                                _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.010"))),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.011")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.012")))]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.013"))),
                _c("br"),
                _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.015"))),
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.013"))),
                _c("br"),
                _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.016"))),
              ]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.017")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.018")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.019")))]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.014"))),
                _c("br"),
                _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.015"))),
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.014"))),
                _c("br"),
                _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.016"))),
              ]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.020")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.021")))]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.022"))),
                _c("br"),
                _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.023"))),
              ]),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.022"))),
                _c("br"),
                _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.024"))),
              ]),
              _c("th", [
                _c(
                  "button",
                  {
                    staticClass: "tbl_icon plus",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.addActionRows()
                      },
                    },
                  },
                  [_vm._v("plus")]
                ),
              ]),
            ]),
          ]),
          _c(
            "tbody",
            _vm._l(_vm.send.action, function (acItem, acIdx) {
              return _c("tr", { key: acIdx }, [
                _c("td", [
                  _c("div", { staticClass: "tbl_form" }, [
                    _c("span", { staticClass: "dv" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: acItem.actionNm,
                            expression: "acItem.actionNm",
                          },
                        ],
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: acItem.actionNm },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(acItem, "actionNm", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "dv wid20" }, [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon search",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openPopup("ActionListPop", acIdx)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("cp.COMMON.001")))]
                      ),
                    ]),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: acItem.periodDiv,
                          expression: "acItem.periodDiv",
                        },
                      ],
                      attrs: { disabled: acItem.ckPeriodDev !== "Y" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            acItem,
                            "periodDiv",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(
                        _vm.eachPeriodGbnList[acItem.actionCd],
                        function (item, idx) {
                          return _c(
                            "option",
                            { key: idx, domProps: { value: item.detailCd } },
                            [_vm._v(_vm._s(item.detailNm))]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c("span", { staticClass: "dv" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: acItem.updateApplyYn,
                          expression: "acItem.updateApplyYn",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "acUpdateApply" + acIdx,
                        "true-value": "Y",
                        "false-value": "",
                        disabled: acItem.ckUpdateApplyDate !== "Y",
                      },
                      domProps: {
                        checked: Array.isArray(acItem.updateApplyYn)
                          ? _vm._i(acItem.updateApplyYn, null) > -1
                          : _vm._q(acItem.updateApplyYn, "Y"),
                      },
                      on: {
                        change: function ($event) {
                          var $$a = acItem.updateApplyYn,
                            $$el = $event.target,
                            $$c = $$el.checked ? "Y" : ""
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  acItem,
                                  "updateApplyYn",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  acItem,
                                  "updateApplyYn",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(acItem, "updateApplyYn", $$c)
                          }
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "acUpdateApply" + acIdx } }, [
                      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
                    ]),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "input_calendar short" },
                    [
                      _c("e-date-picker", {
                        staticStyle: { width: "90px" },
                        attrs: { disabled: acItem.inEtdDate !== "Y" },
                        model: {
                          value: acItem.applyFr,
                          callback: function ($$v) {
                            _vm.$set(acItem, "applyFr", $$v)
                          },
                          expression: "acItem.applyFr",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "input_calendar short" },
                    [
                      _c("e-date-picker", {
                        staticStyle: { width: "90px" },
                        attrs: { disabled: acItem.inEtaDate !== "Y" },
                        model: {
                          value: acItem.applyTo,
                          callback: function ($$v) {
                            _vm.$set(acItem, "applyTo", $$v)
                          },
                          expression: "acItem.applyTo",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: acItem.porCtr,
                          expression: "acItem.porCtr",
                        },
                      ],
                      attrs: { disabled: acItem.ckPorCtr !== "Y" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              acItem,
                              "porCtr",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            ;[_vm.getHarborList(acItem.porCtr, acIdx)]
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.countryList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.ctrCd } },
                          [_vm._v(_vm._s(item.ctrEnm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: acItem.porPlc,
                          expression: "acItem.porPlc",
                        },
                      ],
                      attrs: { disabled: acItem.inPor !== "Y" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            acItem,
                            "porPlc",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(acItem.harborList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.plcCd } },
                          [_vm._v(_vm._s(item.plcNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: acItem.ts1,
                          expression: "acItem.ts1",
                        },
                      ],
                      attrs: { disabled: acItem.inTs1 !== "Y" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            acItem,
                            "ts1",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.harborAllList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.plcCd } },
                          [_vm._v(_vm._s(item.plcNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: acItem.ts2,
                          expression: "acItem.ts2",
                        },
                      ],
                      attrs: { disabled: acItem.inTs2 !== "Y" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            acItem,
                            "ts2",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.harborAllList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.plcCd } },
                          [_vm._v(_vm._s(item.plcNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: acItem.ts3,
                          expression: "acItem.ts3",
                        },
                      ],
                      attrs: { disabled: acItem.inTs3 !== "Y" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            acItem,
                            "ts3",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.harborAllList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.plcCd } },
                          [_vm._v(_vm._s(item.plcNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: acItem.dlyCtr,
                          expression: "acItem.dlyCtr",
                        },
                      ],
                      attrs: { disabled: acItem.ckDlyCtr !== "Y" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              acItem,
                              "dlyCtr",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.getHarborList2(acItem.dlyCtr, acIdx)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.countryList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.ctrCd } },
                          [_vm._v(_vm._s(item.ctrEnm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: acItem.dlyPlc,
                          expression: "acItem.dlyPlc",
                        },
                      ],
                      attrs: { disabled: acItem.inDly !== "Y" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            acItem,
                            "dlyPlc",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(acItem.harborList2, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.plcCd } },
                          [_vm._v(_vm._s(item.plcNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c("div", { staticClass: "tbl_form" }, [
                    _c("span", { staticClass: "dv" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: acItem.rtVesVoyage,
                            expression: "acItem.rtVesVoyage",
                          },
                        ],
                        attrs: {
                          type: "text",
                          disabled: acItem.inRtVesVoyage !== "Y",
                          readonly: "",
                        },
                        domProps: { value: acItem.rtVesVoyage },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(acItem, "rtVesVoyage", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "dv wid20" }, [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon search",
                          attrs: { disabled: acItem.inRtVesVoyage !== "Y" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openPopup("RtVesVoyagePop", acIdx)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("cp.COMMON.001")))]
                      ),
                    ]),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "tbl_form" }, [
                    _c("span", { staticClass: "dv" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: acItem.cntrSizeTypeDgoog,
                            expression: "acItem.cntrSizeTypeDgoog",
                          },
                        ],
                        attrs: {
                          type: "text",
                          disabled: acItem.inCntrSizeTypeDgoog !== "Y",
                          readonly: "",
                        },
                        domProps: { value: acItem.cntrSizeTypeDgoog },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              acItem,
                              "cntrSizeTypeDgoog",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "dv wid20" }, [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon search",
                          attrs: {
                            disabled: acItem.inCntrSizeTypeDgoog !== "Y",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openPopup(
                                "CntrSizeTypeDgoogPop",
                                acIdx
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("cp.COMMON.001")))]
                      ),
                    ]),
                  ]),
                ]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: acItem.bsaRate,
                        expression: "acItem.bsaRate",
                      },
                    ],
                    staticStyle: { width: "40px" },
                    attrs: { disabled: acItem.ckBsaRate !== "Y" },
                    domProps: { value: acItem.bsaRate },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(acItem, "bsaRate", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: acItem.bsaSign,
                          expression: "acItem.bsaSign",
                        },
                      ],
                      attrs: { disabled: acItem.ckBsaSign !== "Y" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            acItem,
                            "bsaSign",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.bsaESList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.detailCd } },
                          [_vm._v(_vm._s(item.detailNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "tbl_icon minus",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.removeActionRows(acIdx)
                        },
                      },
                    },
                    [_vm._v("minus")]
                  ),
                ]),
              ])
            }),
            0
          ),
        ]),
        _c("h2", { staticClass: "content_title" }, [
          _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.025"))),
        ]),
        _c("table", { staticClass: "tbl_col mt10" }, [
          _vm._m(2),
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.026")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.027")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.028")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.029")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.030")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.031")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.032")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.033")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.034")))]),
              _c("th", [
                _c(
                  "button",
                  {
                    staticClass: "tbl_icon plus",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.addDeviceRows()
                      },
                    },
                  },
                  [_vm._v("plus")]
                ),
              ]),
            ]),
          ]),
          _c(
            "tbody",
            _vm._l(_vm.send.device, function (dvItem, dvIdx) {
              return _c("tr", { key: dvIdx }, [
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: dvItem.porCtr,
                          expression: "dvItem.porCtr",
                        },
                      ],
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              dvItem,
                              "porCtr",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            ;[
                              _vm.getDeviceHarborList(dvItem.porCtr, dvIdx),
                              _vm.getDeviceTsList(
                                dvItem.porCtr,
                                dvItem.porPlc,
                                dvIdx
                              ),
                            ]
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.countryList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.ctrCd } },
                          [_vm._v(_vm._s(item.ctrEnm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: dvItem.porPlc,
                          expression: "dvItem.porPlc",
                        },
                      ],
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              dvItem,
                              "porPlc",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.getDeviceTsList(
                              dvItem.porCtr,
                              dvItem.porPlc,
                              dvIdx
                            )
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(dvItem.harborList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.plcCd } },
                          [_vm._v(_vm._s(item.plcNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: dvItem.tml,
                          expression: "dvItem.tml",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            dvItem,
                            "tml",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(dvItem.tsList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.trmlCd } },
                          [_vm._v(_vm._s(item.trmlNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: dvItem.cntrSize,
                          expression: "dvItem.cntrSize",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            dvItem,
                            "cntrSize",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.cntrSizeList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.detailCd } },
                          [_vm._v(_vm._s(item.detailNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: dvItem.cntrType,
                          expression: "dvItem.cntrType",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            dvItem,
                            "cntrType",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.cntrTypeList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.detailCd } },
                          [_vm._v(_vm._s(item.detailNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: dvItem.baseComp,
                          expression: "dvItem.baseComp",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            dvItem,
                            "baseComp",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.stdItemList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.detailCd } },
                          [_vm._v(_vm._s(item.detailNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: dvItem.equalSign,
                          expression: "dvItem.equalSign",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            dvItem,
                            "equalSign",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.equalSignList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.detailCd } },
                          [_vm._v(_vm._s(item.detailNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c("div", { staticClass: "tbl_form" }, [
                    _c("span", { staticClass: "dv" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: dvItem.objtComp,
                              expression: "dvItem.objtComp",
                            },
                          ],
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  dvItem,
                                  "objtComp",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                dvItem.keyinVal = ""
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                          ]),
                          _vm._l(_vm.targetItemList, function (item, idx) {
                            return _c(
                              "option",
                              { key: idx, domProps: { value: item.detailCd } },
                              [_vm._v(_vm._s(item.detailNm))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: dvItem.objtComp === "T05",
                            expression: "dvItem.objtComp === 'T05'",
                          },
                        ],
                        staticClass: "dv wid100",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: dvItem.keyinVal,
                              expression: "dvItem.keyinVal",
                            },
                          ],
                          staticStyle: { padding: "0 2px" },
                          attrs: { type: "text" },
                          domProps: { value: dvItem.keyinVal },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(dvItem, "keyinVal", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: dvItem.cntrSign,
                          expression: "dvItem.cntrSign",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            dvItem,
                            "cntrSign",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.deviceCtlGbnList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.detailCd } },
                          [_vm._v(_vm._s(item.detailNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "tbl_icon minus",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.removeDeviceRows(dvIdx)
                        },
                      },
                    },
                    [_vm._v("minus")]
                  ),
                ]),
              ])
            }),
            0
          ),
        ]),
        _c("h2", { staticClass: "content_title" }, [
          _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.035"))),
        ]),
        _c("table", { staticClass: "tbl_col mt10" }, [
          _vm._m(3),
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.036")))]),
              _c("th", { attrs: { colspan: "4" } }, [
                _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.037"))),
              ]),
              _c("th", { attrs: { colspan: "4" } }, [
                _vm._v(_vm._s(_vm.$t("cp.CTRL020G080.038"))),
              ]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.039")))]),
              _c("th", [
                _c(
                  "button",
                  {
                    staticClass: "tbl_icon plus",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.addPromotionRows()
                      },
                    },
                  },
                  [_vm._v("plus")]
                ),
              ]),
            ]),
          ]),
          _c(
            "tbody",
            _vm._l(_vm.send.promotion, function (prItem, prIdx) {
              return _c("tr", { key: prIdx, staticClass: "cp-parentnode" }, [
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: prItem.inOutCd,
                          expression: "prItem.inOutCd",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            prItem,
                            "inOutCd",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.pcList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.detailCd } },
                          [_vm._v(_vm._s(item.detailNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: prItem.ofCntrSize01,
                          expression: "prItem.ofCntrSize01",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            prItem,
                            "ofCntrSize01",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.cntrSizeList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.detailCd } },
                          [_vm._v(_vm._s(item.detailNm))]
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: prItem.ofCntrSize02,
                          expression: "prItem.ofCntrSize02",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            prItem,
                            "ofCntrSize02",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.cntrSizeList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.detailCd } },
                          [_vm._v(_vm._s(item.detailNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: prItem.ofCntrType01,
                          expression: "prItem.ofCntrType01",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            prItem,
                            "ofCntrType01",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.promoCntrTypeList, function (item, idx) {
                      return _c(
                        "option",
                        { key: idx, domProps: { value: item.detailCd } },
                        [_vm._v(_vm._s(item.detailNm))]
                      )
                    }),
                    0
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: prItem.ofCntrType02,
                          expression: "prItem.ofCntrType02",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            prItem,
                            "ofCntrType02",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.promoCntrTypeList, function (item, idx) {
                      return _c(
                        "option",
                        { key: idx, domProps: { value: item.detailCd } },
                        [_vm._v(_vm._s(item.detailNm))]
                      )
                    }),
                    0
                  ),
                ]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: prItem.ofDcRate01,
                        expression: "prItem.ofDcRate01",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: prItem.ofDcRate01 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(prItem, "ofDcRate01", $event.target.value)
                      },
                    },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: prItem.ofDcRate02,
                        expression: "prItem.ofDcRate02",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: prItem.ofDcRate02 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(prItem, "ofDcRate02", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: prItem.ofDcType01,
                          expression: "prItem.ofDcType01",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            prItem,
                            "ofDcType01",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _c("option", { attrs: { value: "%" } }, [_vm._v("%")]),
                      _c("option", { attrs: { value: "$" } }, [_vm._v("$")]),
                    ]
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: prItem.ofDcType02,
                          expression: "prItem.ofDcType02",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            prItem,
                            "ofDcType02",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _c("option", { attrs: { value: "%" } }, [_vm._v("%")]),
                      _c("option", { attrs: { value: "$" } }, [_vm._v("$")]),
                    ]
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: prItem.dtCntrSize01,
                          expression: "prItem.dtCntrSize01",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            prItem,
                            "dtCntrSize01",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.cntrSizeList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.detailCd } },
                          [_vm._v(_vm._s(item.detailNm))]
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: prItem.dtCntrSize02,
                          expression: "prItem.dtCntrSize02",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            prItem,
                            "dtCntrSize02",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.cntrSizeList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.detailCd } },
                          [_vm._v(_vm._s(item.detailNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: prItem.dtCntrType01,
                          expression: "prItem.dtCntrType01",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            prItem,
                            "dtCntrType01",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.promoCntrTypeList, function (item, idx) {
                      return _c(
                        "option",
                        { key: idx, domProps: { value: item.detailCd } },
                        [_vm._v(_vm._s(item.detailNm))]
                      )
                    }),
                    0
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: prItem.dtCntrType02,
                          expression: "prItem.dtCntrType02",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            prItem,
                            "dtCntrType02",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.promoCntrTypeList, function (item, idx) {
                      return _c(
                        "option",
                        { key: idx, domProps: { value: item.detailCd } },
                        [_vm._v(_vm._s(item.detailNm))]
                      )
                    }),
                    0
                  ),
                ]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: prItem.dtDcRate01,
                        expression: "prItem.dtDcRate01",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: prItem.dtDcRate01 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(prItem, "dtDcRate01", $event.target.value)
                      },
                    },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: prItem.dtDcRate02,
                        expression: "prItem.dtDcRate02",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: prItem.dtDcRate02 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(prItem, "dtDcRate02", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: prItem.dtDcType01,
                          expression: "prItem.dtDcType01",
                        },
                      ],
                      attrs: { required: true },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            prItem,
                            "dtDcType01",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [_c("option", { attrs: { value: "$" } }, [_vm._v("$")])]
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: prItem.dtDcType02,
                          expression: "prItem.dtDcType02",
                        },
                      ],
                      attrs: { required: true },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            prItem,
                            "dtDcType02",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [_c("option", { attrs: { value: "$" } }, [_vm._v("$")])]
                  ),
                ]),
                _c("td", [
                  _c("span", { staticClass: "dv" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: prItem.salesCt,
                          expression: "prItem.salesCt",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "prSalesEmail" + prIdx,
                        "true-value": "Y",
                        "false-value": "N",
                        default: "N",
                      },
                      domProps: {
                        checked: Array.isArray(prItem.salesCt)
                          ? _vm._i(prItem.salesCt, null) > -1
                          : _vm._q(prItem.salesCt, "Y"),
                      },
                      on: {
                        change: function ($event) {
                          var $$a = prItem.salesCt,
                            $$el = $event.target,
                            $$c = $$el.checked ? "Y" : "N"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(prItem, "salesCt", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  prItem,
                                  "salesCt",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(prItem, "salesCt", $$c)
                          }
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "prSalesEmail" + prIdx } }, [
                      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
                    ]),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "tbl_icon minus",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.removePromotionRows(prIdx)
                        },
                      },
                    },
                    [_vm._v("minus")]
                  ),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
      _c("div", { staticClass: "flex_box mt10" }, [
        _c(
          "a",
          {
            staticClass: "button blue lg mr5 ml_auto",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.save.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.067")))]
        ),
        _c(
          "a",
          {
            staticClass: "button blue lg mr5",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.confirmed.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("cp.CTRL020G080.040")))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "100px" } }),
      _c("col"),
      _c("col", { attrs: { width: "80px" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "3%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "11%" } }),
      _c("col", { attrs: { width: "11%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "4%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "4%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }