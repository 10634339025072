import Send from '../../cp.client.js'

export default {
    groupList () {
        return Send({
            url: '/cp/commCode/groupList',
            method: 'get'
        })
    },
    detailList (groupCd) {
        return Send({
            url: '/cp/commCode/detailList',
            method: 'get',
            params: {
                groupCd: groupCd
            }
        })
    },
    save (data) {
        return Send({
            url: '/cp/commCode/save',
            method: 'put',
            data: data
        })
    }
}
