<template>
  <div class="content full">
    <e-breadcrumbs />
    <win-layer-pop class="sample_popup_list">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closePopup"
        :parent-info="parentInfo"
      />
    </win-layer-pop>
    <h1 class="page_title">{{ $t('cp.CTRL010G020.001') }}</h1>
    <div class="content_box mt10 clearfix">
      <div class="col_6 float_left pr20">
        <h2 class="content_title">{{ $t('cp.CTRL010G020.002') }}</h2>
        <div id="realgrid" class="mt10" style="width: 100%; height: 450px" />
      </div>
      <div class="col_4 float_right">
        <h2 class="content_title">&nbsp;</h2>
        <table class="tbl_row">
          <colgroup>
            <col width="140"><col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('cp.CTRL010G020.003') }} <span>*</span></th>
              <td><input type="text" id="actionCd" v-model="send.actionCd" ref="actionCdInput" @blur="actionCdVali" :readonly="!send.exist"></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL010G020.004') }}</th>
              <td><input type="text" v-model="send.actionNm"></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL010G020.014') }}</th>
              <td><input type="text" v-model="send.actionEnm"></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL010G020.005') }}</th>
              <td><input type="text" v-model="send.shootPoint"></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL010G020.015') }}</th>
              <td><input type="text" v-model="send.shootPointEn"></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL010G020.006') }}</th>
              <td><input type="text" v-model="send.shootResult"></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL010G020.016') }}</th>
              <td><input type="text" v-model="send.shootResultEn"></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL010G020.007') }}</th>
              <td>
                <select v-model="send.category">
                  <option value="">{{ $t('cp.COMMON.002') }}</option>
                  <option v-for="(item, idx) in categoryList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL010G020.008') }}</th>
              <td>
                <div class="tbl_form">
                  <span class="dv">
                    <input type="text" v-model="send.apiCd" readonly>
                  </span>
                  <span class="dv wid20">
                    <button class="tbl_icon search" @click.prevent="openPopup('ApiListPop')">{{ $t('cp.COMMON.001') }}</button>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex_box mt10">
      <a class="button blue lg mr5 ml_auto" href="#" @click.prevent="new_init">{{ $t('cp.COMMON.003') }}</a>
      <a class="button blue lg mr5" href="#" @click.prevent="save">{{ $t('cp.COMMON.004') }}</a>
      <a class="button blue lg" href="#" @click.prevent="deleteAction">{{ $t('cp.COMMON.010') }}</a>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import { GridView, LocalDataProvider } from 'realgrid'
import actionListMng from '@/api/rest/cp/actionListMng'
import cpCommonInfo from '@/api/rest/cp/cpCommonInfo'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'actionCd', dataType: 'text' },
  { fieldName: 'actionNm', dataType: 'text' },
  { fieldName: 'actionEnm', dataType: 'text' },
  { fieldName: 'shootPoint', dataType: 'text' },
  { fieldName: 'shootPointEn', dataType: 'text' },
  { fieldName: 'shootResult', dataType: 'text' },
  { fieldName: 'shootResultEn', dataType: 'text' },
  { fieldName: 'category', dataType: 'text' },
  { fieldName: 'categoryNm', dataType: 'text' },
  { fieldName: 'apiCd', dataType: 'text' },
  { fieldName: 'apiFunction', dataType: 'text' }
]

export const columns = [
  { name: 'actionCd', fieldName: 'actionCd', type: 'text', header: { text: app.$t('cp.CTRL010G020.003') }, editable: false, width: 100 },
  { name: 'actionNm', fieldName: 'actionNm', type: 'text', header: { text: app.$t('cp.CTRL010G020.004') }, editable: false, width: 150, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'actionEnm', fieldName: 'actionEnm', type: 'text', header: { text: app.$t('cp.CTRL010G020.014') }, editable: false, width: 150, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'shootPoint', fieldName: 'shootPoint', type: 'text', header: { text: app.$t('cp.CTRL010G020.005') }, editable: false, width: 170, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'shootPointEn', fieldName: 'shootPointEn', type: 'text', header: { text: app.$t('cp.CTRL010G020.015') }, editable: false, width: 170, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'shootResult', fieldName: 'shootResult', type: 'text', header: { text: app.$t('cp.CTRL010G020.006') }, editable: false, width: 170, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'shootResultEn', fieldName: 'shootResultEn', type: 'text', header: { text: app.$t('cp.CTRL010G020.016') }, editable: false, width: 170, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'categoryNm', fieldName: 'categoryNm', type: 'text', header: { text: app.$t('cp.CTRL010G020.007') }, editable: false, width: 150 },
  { name: 'apiFunction', fieldName: 'apiFunction', type: 'text', header: { text: app.$t('cp.CTRL010G020.008') }, editable: false, width: 200 }
]

export default {
  name: 'ActionListMng',
  components: {
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    EBreadcrumbs,
    ApiListPop: () => import('@/pages/cp/popup/ApiListPop')
  },
  data () {
    return {
      customComponent: null,
      parentInfo: {
        apiCd: ''
      },
      categoryList: [],
      gridList: [],
      send: {
        exist: false,
        actionCd: '',
        actionNm: '',
        actionEnm: '',
        shootPoint: '',
        shootPointEn: '',
        shootResult: '',
        shootResultEn: '',
        category: '',
        apiCd: ''
      },
      currentActionCd: null
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    cpCommonInfo.cpRole().then(response => {
      let cpAdmin = response.data.cpAdmin
      let cpUser = response.data.cpUser
      if (cpAdmin === 'Y') {
        //접근 권한 있음.
      } else {
        //접근 권한 없으므로 main 으로...
        this.$router.push('/main')
        //if (cpUser === 'Y') {
        //  this.$router.push('/main')
        //}
      }
    }).catch(err => {
      console.log(err)
    })
  },
  mounted: function () {
    console.log('mounted')
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.setDataSource(provider)
    gridView.header.heights = [30]
    gridView.setDisplayOptions({
      rowHeight: 35,
      fitStyle: 'even',
      hscrollBar: true,
      selectionStyle: 'rows',
      columnMovable: false
    })
    gridView.footers.visible = false
    gridView.setStateBar({
      visible: false
    })
    gridView.setRowIndicator({
      visible: false
    })
    gridView.setCheckBar({
      visible: false,
      exclusive: false
    })

    // realgrid 정렬 관련 옵션
    gridView.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })

    const $vm = this
    $vm.getCommonCodeList('CP00') //카테고리
    $vm.search()

    gridView.onCellClicked = (_grid, clickData) => {
      if (clickData.cellType === 'data') {
        $vm.send.exist = false
        $vm.getGridInfo(clickData.dataRow)
      }
    }
  },
  methods: {
    actionCdVali () {
      if (this.send.actionCd !== '' && this.send.exist === true) {
        actionListMng.actionCdVali(this.send.actionCd).then(response => {
          if (response.data === 1) {
            this.$ekmtcCommon.alertCallback(this.$t('cp.CTRL010G020.009'), () => {
              this.send.actionCd = ''
              document.getElementById('actionCd').focus()
            })
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    openPopup (compNm, idx) {
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose()
      }
      this.parentInfo.apiCd = this.send.apiCd
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.sample_popup_list')
    },
    closePopup (obj) {
      if (obj === undefined || obj === '' || obj === null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.sample_popup_list')
        return
      }
      this.send.apiCd = obj
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.sample_popup_list')
    },
    getCommonCodeList (code) {
      cpCommonInfo.cpCommonCodeList(code).then(response => {
        this.categoryList = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    search () {
      actionListMng.list().then(response => {
        this.gridList = response.data
        let defaultIdxRow = this.currentActionCd ? this.gridList.map((row) => row.actionCd).indexOf(this.currentActionCd) : 0
        this.send.exist = false
        this.currentActionCd = null
        provider.setRows(this.gridList)
        if (this.gridList.length > 0) {
          gridView.setCurrent({ dataRow: defaultIdxRow })
          this.getGridInfo(defaultIdxRow)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    save () {
      if (this.send.actionCd === '') {
        this.$ekmtcCommon.alertCallback(this.$t('cp.CTRL010G020.010'), () => {
          this.$refs.actionCdInput.focus()
        })
        return
      }

      actionListMng.save(this.send).then(() => {
        this.currentActionCd = this.send.actionCd
        this.search()
        this.openAlert(this.$t('cp.CTRL010G020.011'))
      }).catch(err => {
        console.log(err)
      })
    },
    deleteAction: function () {
      this.$ekmtcCommon.confirmCallBack(this.$t('cp.CTRL010G020.012'), () => {
        actionListMng.delete(this.send.actionCd).then(response => {
          this.search()
          this.openAlert(this.$t('cp.CTRL010G020.013'))
        }).catch(err => {
          console.log(err)
        })
      })
    },
    new_init () {
      gridView.clearCurrent()
      this.send.exist = true
      this.send.actionCd = ''
      this.send.actionNm = ''
      this.send.actionEnm = ''
      this.send.shootPoint = ''
      this.send.shootPointEn = ''
      this.send.shootResult = ''
      this.send.shootResultEn = ''
      this.send.category = ''
      this.send.apiCd = ''
      this.$refs.actionCdInput.focus()
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('cp.CTRL010G020.002'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    getGridInfo (idx) {
      this.send.actionCd = this.gridList[idx].actionCd
      this.send.actionNm = this.gridList[idx].actionNm
      this.send.actionEnm = this.gridList[idx].actionEnm
      this.send.shootPoint = this.gridList[idx].shootPoint
      this.send.shootPointEn = this.gridList[idx].shootPointEn
      this.send.shootResult = this.gridList[idx].shootResult
      this.send.shootResultEn = this.gridList[idx].shootResultEn
      this.send.category = this.gridList[idx].category
      this.send.apiCd = this.gridList[idx].apiCd
    }
  }
}
</script>
