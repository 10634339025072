<template>
  <div class="content full">
    <e-breadcrumbs />
    <win-layer-pop class="sample_popup_list">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closePopup"
        :parent-info="parentInfo"
      />
    </win-layer-pop>
    <h1 class="page_title">{{ $t('cp.CTRL020G140.001') }}</h1>
    <div class="content_box mt10">
      <table class="tbl_search">
        <colgroup>
          <col width="100px">
          <col>
          <col width="90px">
          <col>
          <col width="100px">
          <col>
          <col width="100px">
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('cp.CTRL020G140.002') }}</th>
            <td>
              <select v-model="param.category">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in actCateList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
            </td>
            <th>{{ $t('cp.CTRL020G140.003') }}</th>
            <td>
              <input type="text" v-model="param.createUser" @keydown.enter="select">
            </td>
            <th>{{ $t('cp.CTRL020G140.004') }}</th>
            <td>
              <input type="text" v-model="param.scenarioNm" @keydown.enter="select">
            </td>
            <td class="text_right">
            </td>
          </tr>
          <tr>
            <th>{{ $t('cp.CTRL020G140.005') }}</th>
            <td>
              <div class="form_wrap t2">
                <e-date-range-picker-simple @change="changeDateRange" />
              </div>
            </td>
            <th>{{ $t('cp.CTRL020G140.006') }}</th>
            <td>
              <div class="tbl_form">
                <span class="dv">
                  <select v-model="param.porCtr" @change="getHarborList(param.porCtr)">
                    <option value="">{{ $t('cp.COMMON.002') }}</option>
                    <option v-for="(item, idx) in countryList" :key="idx" :value="item.ctrCd">{{ item.ctrEnm }}</option>
                  </select>
                </span>
                <span class="dv">
                  <select v-model="param.porPlc">
                    <option value="">{{ $t('cp.COMMON.002') }}</option>
                    <option v-for="(item, idx) in harborList" :key="idx" :value="item.plcCd">{{ item.plcNm }}</option>
                  </select>
                </span>
              </div>
            </td>
            <th>{{ $t('cp.CTRL020G140.007') }}</th>
            <td>
              <div class="tbl_form">
                <span class="dv">
                  <select v-model="param.dlyCtr" @change="getHarborList2(param.dlyCtr)">
                    <option value="">{{ $t('cp.COMMON.002') }}</option>
                    <option v-for="(item, idx) in countryList" :key="idx" :value="item.ctrCd">{{ item.ctrEnm }}</option>
                  </select>
                </span>
                <span class="dv">
                  <select v-model="param.dlyPlc">
                    <option value="">{{ $t('cp.COMMON.002') }}</option>
                    <option v-for="(item, idx) in harborList2" :key="idx" :value="item.plcCd">{{ item.plcNm }}</option>
                  </select>
                </span>
              </div>
            </td>
            <td class="text_right">
              <a class="button blue sh" href="#" @click.prevent="select">{{ $t('cp.COMMON.001') }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="content_box mt10">
      <div id="realgrid" class="mt5" style="width: 100%; height: 300px" />
      <table class="tbl_row mt10">
        <colgroup>
          <col width="130">
          <col>
          <col width="130">
          <col>
          <col width="15%">
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('cp.CTRL020G140.008') }}</th>
            <td>
              <input type="radio" id="shipper1" name="shipper" v-model="belowParam.selectFlag" value="T" checked>
              <label for="shipper1" class="mr10"><span></span>{{ $t('cp.CTRL020G140.009') }}</label>
              <input type="radio" id="shipper2" name="shipper" v-model="belowParam.selectFlag" value="R">
              <label for="shipper2"><span></span>{{ $t('cp.CTRL020G140.010') }}</label>
              <input type="radio" id="shipper3" name="shipper" v-model="belowParam.selectFlag" value="A">
              <label for="shipper3"><span></span>{{ $t('cp.CTRL020G140.011') }}</label>
            </td>
            <th>{{ $t('cp.CTRL020G140.012') }}</th>
            <td>
              <input type="radio" id="option1" name="option" v-model="belowParam.optionFlag" value="E" checked>
              <label for="option1" class="mr10"><span></span>{{ $t('cp.CTRL020G140.013') }}</label>
              <input type="radio" id="option2" name="option" v-model="belowParam.optionFlag" value="I">
              <label for="option2"><span></span>{{ $t('cp.CTRL020G140.014') }}</label>
            </td>
            <td class="border_left_none"></td>
          </tr>
        </tbody>
      </table>
      <table class="tbl_row mt10">
        <colgroup>
          <col width="130">
          <col>
          <col width="130">
          <col>
          <col width="15%">
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('cp.CTRL020G140.015') }}</th>
            <td>
              <select v-model="belowParam.periodDiv1" @change="belowParam.periodDiv2=belowParam.periodDiv1" class="wid120">
                <option value="P01">{{ $t('cp.CTRL020G140.016') }}</option>
                <option value="P02">{{ $t('cp.CTRL020G140.017') }}</option>
              </select>
              <div class="form_wrap t2 d_inline_block ml10">
                <e-date-picker v-model="belowParam.periodFrA" />
                <span> ~ </span>
                <e-date-picker v-model="belowParam.periodToA" />
              </div>
            </td>
            <th>{{ $t('cp.CTRL020G140.018') }}</th>
            <td>
              <select v-model="belowParam.periodDiv2" @change="belowParam.periodDiv1=belowParam.periodDiv2" class="wid120">
                <option value="P01">{{ $t('cp.CTRL020G140.016') }}</option>
                <option value="P02">{{ $t('cp.CTRL020G140.017') }}</option>
              </select>
              <div class="form_wrap t2 d_inline_block ml10">
                <e-date-picker v-model="belowParam.periodFrB" />
                <span> ~ </span>
                <e-date-picker v-model="belowParam.periodToB" />
              </div>
            </td>
            <td class="border_left_none text_right">
              <a class="button blue sh mr5" :class="{'disabled': !isExistResult}" href="#" @click.prevent="exportExcel" :disabled="!isExistResult">{{ $t('cp.CTRL020G140.019') }}</a>
              <a class="button blue sh" :class="{'disabled': isInitial}" href="#" @click.prevent="selectFunc">{{ $t('cp.CTRL020G140.020') }}</a>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="srtabmenu tbl_col mt10">
        <thead>
          <tr class="srtabmenu_itemname">
            <th v-for="(item, key, idx) in itemInfo" :key="idx">{{ item.name }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="srtabmenu_itemchk">
            <td v-for="(item, key, idx) in itemInfo" :key="idx" class="chk">
              <input
                type="checkbox"
                :id="'item'+(idx+1)"
                v-model="item.checked"
                @click="readonlyItemchk"
                @change="sortItemOrder(key)"
              >
              <label :for="'item'+(idx+1)"></label>
            </td>
          </tr>
        </tbody>
      </table>
      <div id="realgrid2" class="srgrid_container mt10 srgrid_hidden" style="width: 100%; height: 580px;" />
      <div class="srgraph_container mt10">
        <div class="srgraph_content">
          <div class="srgraph_dashboard">
            <div
              v-for="(item, key, idx) in itemDataE"
              :key="idx"
              :id="'srgraph_' + key"
              class="srgraph_unit mb10"
              :class="{srgraph_cleartag: !itemInfo[key].checked}"
              :style="{order: itemOrder.indexOf(key)}"
              @dblclick="uncheckItem(key)"
            >
              <h2 class="srgraph_unittitle srgraph_centertext">{{ item.name }}</h2>
              <div class="srgraph_unitrow srgraph_valA">
                <div class="srgraph_axistitle">{{ $t('cp.CTRL020G140.015') }}</div>
                <div class="srgraph_leftbar">
                  <div class="srgraph_baseline"></div>
                  <p class="srgraph_value" :class="{srgraph_hidden: item.valA >= 0}">{{ convertFormatNum(item.valA, 'val') }}</p>
                  <div class="srgraph_bar srgraph_normalL" :style="{width: getNormalBarWidth('A', 'left', item)}"></div>
                </div>
                <div class="srgraph_rightbar">
                  <div class="srgraph_bar srgraph_normalR" :style="{width: getNormalBarWidth('A', 'right', item)}"></div>
                  <p class="srgraph_value" :class="{srgraph_hidden: item.valA < 0}">{{ convertFormatNum(item.valA, 'val') }}</p>
                </div>
              </div>
              <div class="srgraph_unitrow srgraph_valB">
                <div class="srgraph_axistitle">{{ $t('cp.CTRL020G140.018') }}</div>
                <div class="srgraph_leftbar">
                  <div class="srgraph_baseline"></div>
                  <p class="srgraph_value" :class="{srgraph_hidden: item.valB >= 0}">{{ convertFormatNum(item.valB, 'val') }}</p>
                  <div class="srgraph_bar srgraph_normalL" :style="{width: getNormalBarWidth('B', 'left', item)}"></div>
                </div>
                <div class="srgraph_rightbar">
                  <div class="srgraph_bar srgraph_normalR" :style="{width: getNormalBarWidth('B', 'right', item)}"></div>
                  <p class="srgraph_value" :class="{srgraph_hidden: item.valB < 0}">{{ convertFormatNum(item.valB, 'val') }}</p>
                </div>
              </div>
              <div class="srgraph_unitrow srgraph_rate">
                <div class="srgraph_axistitle">{{ $t('cp.CTRL020G140.021') }}</div>
                <div class="srgraph_leftbar">
                  <div class="srgraph_baseline"></div>
                  <div class="srgraph_basevalue">0%</div>
                  <p class="srgraph_value" :class="{srgraph_hidden: item.rate >= 0}">{{ convertFormatNum(item.rate, 'rate') }}</p>
                  <div class="srgraph_bar srgraph_negative" :style="{width: getRateBarWidth('left', item)}"></div>
                </div>
                <div class="srgraph_rightbar">
                  <div class="srgraph_bar srgraph_positive" :style="{width: getRateBarWidth('right', item)}"></div>
                  <p class="srgraph_value" :class="{srgraph_hidden: item.rate < 0}">{{ convertFormatNum(item.rate, 'rate') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="srgraph_stats">
          <div class="srgraph_statshead" :class="{srgraph_cleartag: Object.values(itemInfo).filter((row) => row.checked).length === 0 }">
            <div class="srgraph_centertext">{{ $t('cp.CTRL020G140.022') }}</div>
            <div class="srgraph_centertext srgraph_newline">{{ convertFormatPeriod(belowParam.periodFrA, belowParam.periodToA, $t('cp.CTRL020G140.015')) }}</div>
            <div class="srgraph_centertext srgraph_newline">{{ convertFormatPeriod(belowParam.periodFrB, belowParam.periodToB, $t('cp.CTRL020G140.018')) }}</div>
            <div class="srgraph_centertext">{{ $t('cp.CTRL020G140.021') }}</div>
          </div>
          <div class="srgraph_statsbody">
            <div
              v-for="(item, key, idx) in itemDataE"
              :key="idx"
              :id="'srgraph_' + key"
              class="srgraph_statsrow"
              :class="{srgraph_cleartag: !itemInfo[key].checked}"
              :style="{order: itemOrder.indexOf(key)}"
              @mouseover="highlightSrgraph(key)"
              @mouseleave="unhighlightSrgraph(key)"
              @dblclick="uncheckItem(key)"
            >
              <div class="srgraph_statsname srgraph_centertext">{{ item.name }}</div>
              <div class="srgraph_statsvalue" :class="{'srgraph_emptyvalue': item.valA === null && isExistResult}">{{ convertFormatNum(item.valA, 'val') }}</div>
              <div class="srgraph_statsvalue" :class="{'srgraph_emptyvalue': item.valB === null && isExistResult}">{{ convertFormatNum(item.valB, 'val') }}</div>
              <div class="srgraph_statsvalue" :class="{'srgraph_emptyvalue': item.rate === null && isExistResult}">{{ convertFormatNum(item.rate, 'rate') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="realgrid3" style="width: 1px; height: 1px; display: none;" />
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import { rootComputed } from '@/store/helpers'
import scenarioResult from '@/api/rest/cp/scenarioResult'
import cpCommonInfo from '@/api/rest/cp/cpCommonInfo'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import JSZip from 'jszip'

window.JSZip = window.JSZip || JSZip
let gridView = GridView
let provider = LocalDataProvider
let gridView2 = GridView
let provider2 = LocalDataProvider
let gridView3 = GridView
let provider3 = LocalDataProvider
const fields = [
  { fieldName: 'scenarioCd', dataType: 'text' },
  { fieldName: 'scenarioNm', dataType: 'text' },
  { fieldName: 'remark', dataType: 'text' },
  { fieldName: 'createUser', dataType: 'text' },
  { fieldName: 'effectFr', dataType: 'text' },
  { fieldName: 'effectTo', dataType: 'text' },
  { fieldName: 'porCtr', dataType: 'text' },
  { fieldName: 'porPlc', dataType: 'text' },
  { fieldName: 'dlyCtr', dataType: 'text' },
  { fieldName: 'dlyPlc', dataType: 'text' },
  { fieldName: 'searchTarget', dataType: 'text' }
]
const columns = [
  { name: 'scenarioCd', fieldName: 'scenarioCd', header: { text: app.$t('cp.CTRL020G140.023') }, editable: false, width: 50 },
  { name: 'scenarioNm', fieldName: 'scenarioNm', header: { text: app.$t('cp.CTRL020G140.004') }, editable: false, width: 105, styleName: 'text_left' },
  { name: 'remark', fieldName: 'remark', header: { text: app.$t('cp.CTRL020G140.024') }, editable: false, width: 120, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'createUser', fieldName: 'createUser', header: { text: app.$t('cp.CTRL020G140.003') }, editable: false, width: 40 },
  { name: 'effectFr', fieldName: 'effectFr', header: { text: app.$t('cp.CTRL020G140.025') }, editable: false, width: 50 },
  { name: 'effectTo', fieldName: 'effectTo', header: { text: app.$t('cp.CTRL020G140.026') }, editable: false, width: 50 },
  { name: 'porCtr', fieldName: 'porCtr', header: { text: app.$t('cp.CTRL020G140.027') }, editable: false, width: 40, renderer: { showTooltip: true } },
  { name: 'porPlc', fieldName: 'porPlc', header: { text: app.$t('cp.CTRL020G140.028') }, editable: false, width: 40, renderer: { showTooltip: true } },
  { name: 'dlyCtr', fieldName: 'dlyCtr', header: { text: app.$t('cp.CTRL020G140.029') }, editable: false, width: 40, renderer: { showTooltip: true } },
  { name: 'dlyPlc', fieldName: 'dlyPlc', header: { text: app.$t('cp.CTRL020G140.030') }, editable: false, width: 40, renderer: { showTooltip: true } },
  {
    name: 'searchTarget',
    fieldName: 'searchTarget',
    header: { text: app.$t('cp.CTRL020G140.031') },
    editable: false,
    width: 35,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const dataRow = cell.item.dataRow
        return `<button class="tbl_icon search ml5" onClick="func.openPopup('${dataRow}')">${app.$t('cp.COMMON.001')}</button>`
      }
    }
  }
]
const fields2 = [
  { fieldName: 'bkgShipper', dataType: 'text' },
  { fieldName: 'bkgShipperNm', dataType: 'text' },
  { fieldName: 'valA1', dataType: 'number' },
  { fieldName: 'valB1', dataType: 'number' },
  { fieldName: 'rate1', dataType: 'number' },
  { fieldName: 'valA2', dataType: 'number' },
  { fieldName: 'valB2', dataType: 'number' },
  { fieldName: 'rate2', dataType: 'number' },
  { fieldName: 'valA3', dataType: 'number' },
  { fieldName: 'valB3', dataType: 'number' },
  { fieldName: 'rate3', dataType: 'number' },
  { fieldName: 'valA4', dataType: 'number' },
  { fieldName: 'valB4', dataType: 'number' },
  { fieldName: 'rate4', dataType: 'number' },
  { fieldName: 'valA5', dataType: 'number' },
  { fieldName: 'valB5', dataType: 'number' },
  { fieldName: 'rate5', dataType: 'number' },
  { fieldName: 'valA6', dataType: 'number' },
  { fieldName: 'valB6', dataType: 'number' },
  { fieldName: 'rate6', dataType: 'number' },
  { fieldName: 'valA7', dataType: 'number' },
  { fieldName: 'valB7', dataType: 'number' },
  { fieldName: 'rate7', dataType: 'number' },
  { fieldName: 'valA8', dataType: 'number' },
  { fieldName: 'valB8', dataType: 'number' },
  { fieldName: 'rate8', dataType: 'number' },
  { fieldName: 'valA9', dataType: 'number' },
  { fieldName: 'valB9', dataType: 'number' },
  { fieldName: 'rate9', dataType: 'number' }
]
const columns2 = [
  { name: 'bkgShipper', fieldName: 'bkgShipper', header: { text: app.$t('cp.CTRL020G140.032') }, editable: false, width: 120, visible: true },
  { name: 'bkgShipperNm', fieldName: 'bkgShipperNm', header: { text: app.$t('cp.CTRL020G140.033') }, editable: false, width: 200, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'valA1', fieldName: 'valA1', header: { text: app.$t('cp.CTRL020G140.015') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valB1', fieldName: 'valB1', header: { text: app.$t('cp.CTRL020G140.018') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'rate1', fieldName: 'rate1', header: { text: app.$t('cp.CTRL020G140.021') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valA2', fieldName: 'valA2', header: { text: app.$t('cp.CTRL020G140.015') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valB2', fieldName: 'valB2', header: { text: app.$t('cp.CTRL020G140.018') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'rate2', fieldName: 'rate2', header: { text: app.$t('cp.CTRL020G140.021') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valA3', fieldName: 'valA3', header: { text: app.$t('cp.CTRL020G140.015') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valB3', fieldName: 'valB3', header: { text: app.$t('cp.CTRL020G140.018') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'rate3', fieldName: 'rate3', header: { text: app.$t('cp.CTRL020G140.021') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valA4', fieldName: 'valA4', header: { text: app.$t('cp.CTRL020G140.015') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valB4', fieldName: 'valB4', header: { text: app.$t('cp.CTRL020G140.018') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'rate4', fieldName: 'rate4', header: { text: app.$t('cp.CTRL020G140.021') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valA5', fieldName: 'valA5', header: { text: app.$t('cp.CTRL020G140.015') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valB5', fieldName: 'valB5', header: { text: app.$t('cp.CTRL020G140.018') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'rate5', fieldName: 'rate5', header: { text: app.$t('cp.CTRL020G140.021') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valA6', fieldName: 'valA6', header: { text: app.$t('cp.CTRL020G140.015') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valB6', fieldName: 'valB6', header: { text: app.$t('cp.CTRL020G140.018') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'rate6', fieldName: 'rate6', header: { text: app.$t('cp.CTRL020G140.021') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valA7', fieldName: 'valA7', header: { text: app.$t('cp.CTRL020G140.015') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valB7', fieldName: 'valB7', header: { text: app.$t('cp.CTRL020G140.018') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'rate7', fieldName: 'rate7', header: { text: app.$t('cp.CTRL020G140.021') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valA8', fieldName: 'valA8', header: { text: app.$t('cp.CTRL020G140.015') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valB8', fieldName: 'valB8', header: { text: app.$t('cp.CTRL020G140.018') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'rate8', fieldName: 'rate8', header: { text: app.$t('cp.CTRL020G140.021') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valA9', fieldName: 'valA9', header: { text: app.$t('cp.CTRL020G140.015') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'valB9', fieldName: 'valB9', header: { text: app.$t('cp.CTRL020G140.018') }, editable: false, width: 90, styleName: 'text_right' },
  { name: 'rate9', fieldName: 'rate9', header: { text: app.$t('cp.CTRL020G140.021') }, editable: false, width: 90, styleName: 'text_right' }
]

const layout2 = [
  'bkgShipper',
  'bkgShipperNm',
  { name: 'item1', direction: 'horizontal', items: ['valA1', 'valB1', 'rate1'], header: { text: app.$t('cp.CTRL020G140.034') } },
  { name: 'item2', direction: 'horizontal', items: ['valA2', 'valB2', 'rate2'], header: { text: app.$t('cp.CTRL020G140.035') } },
  { name: 'item3', direction: 'horizontal', items: ['valA3', 'valB3', 'rate3'], header: { text: app.$t('cp.CTRL020G140.036') } },
  { name: 'item4', direction: 'horizontal', items: ['valA4', 'valB4', 'rate4'], header: { text: app.$t('cp.CTRL020G140.037') } },
  { name: 'item5', direction: 'horizontal', items: ['valA5', 'valB5', 'rate5'], header: { text: app.$t('cp.CTRL020G140.038') } },
  { name: 'item6', direction: 'horizontal', items: ['valA6', 'valB6', 'rate6'], header: { text: app.$t('cp.CTRL020G140.039') } },
  { name: 'item7', direction: 'horizontal', items: ['valA7', 'valB7', 'rate7'], header: { text: app.$t('cp.CTRL020G140.040') } },
  { name: 'item8', direction: 'horizontal', items: ['valA8', 'valB8', 'rate8'], header: { text: app.$t('cp.CTRL020G140.041') } },
  { name: 'item9', direction: 'horizontal', items: ['valA9', 'valB9', 'rate9'], header: { text: app.$t('cp.CTRL020G140.042') } }
]

export default {
  name: 'ScenarioResultList',
  components: {
    EBreadcrumbs,
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    EDatePicker: () => import('@/components/common/EDatePicker'),
    EDateRangePickerSimple: () => import('@/components/common/EDateRangePickerSimple'),
    ScenarioResultPop: () => import('@/pages/cp/popup/ScenarioResultPop')
  },
  data: function () {
    return {
      customComponent: null,
      parentInfo: {
        scenarioCd: ''
      },
      list: [{}],
      param: {
        category: '',
        createUser: '',
        scenarioNm: '',
        effectFr: '',
        effectTo: '',
        porCtr: '',
        porPlc: '',
        dlyCtr: '',
        dlyPlc: ''
      },
      actCateList: [],
      countryList: [],
      countryList2: [],
      harborList: [],
      harborList2: [],
      effectFr: '',
      belowParam: {
        scenarioCd: '',
        scenarioNm: '',
        selectFlag: 'T',
        optionFlag: 'E',
        periodDiv1: 'P01',
        periodDiv2: 'P01',
        periodFrA: '',
        periodToA: '',
        periodFrB: '',
        periodToB: ''
      },
      itemInfo: {
        item1: { name: this.$t('cp.CTRL020G140.034'), checked: true },
        item2: { name: this.$t('cp.CTRL020G140.035'), checked: true },
        item3: { name: this.$t('cp.CTRL020G140.036'), checked: true },
        item4: { name: this.$t('cp.CTRL020G140.037'), checked: true },
        item5: { name: this.$t('cp.CTRL020G140.038'), checked: true },
        item6: { name: this.$t('cp.CTRL020G140.039'), checked: true },
        item7: { name: this.$t('cp.CTRL020G140.040'), checked: true },
        item8: { name: this.$t('cp.CTRL020G140.041'), checked: true },
        item9: { name: this.$t('cp.CTRL020G140.042'), checked: true }
      },
      itemOrder: [
        'item1',
        'item2',
        'item3',
        'item4',
        'item5',
        'item6',
        'item7',
        'item8',
        'item9'
      ],
      itemDataE: {
        item1: { valA: null, valB: null, rate: null, name: this.$t('cp.CTRL020G140.034') },
        item2: { valA: null, valB: null, rate: null, name: this.$t('cp.CTRL020G140.035') },
        item3: { valA: null, valB: null, rate: null, name: this.$t('cp.CTRL020G140.036') },
        item4: { valA: null, valB: null, rate: null, name: this.$t('cp.CTRL020G140.037') },
        item5: { valA: null, valB: null, rate: null, name: this.$t('cp.CTRL020G140.038') },
        item6: { valA: null, valB: null, rate: null, name: this.$t('cp.CTRL020G140.039') },
        item7: { valA: null, valB: null, rate: null, name: this.$t('cp.CTRL020G140.040') },
        item8: { valA: null, valB: null, rate: null, name: this.$t('cp.CTRL020G140.041') },
        item9: { valA: null, valB: null, rate: null, name: this.$t('cp.CTRL020G140.042') }
      },
      itemDataI: [
        {
          bkgShipper: '',
          bkgShipperNm: '',
          valA1: null,
          valB1: null,
          rate1: null,
          valA2: null,
          valB2: null,
          rate2: null,
          valA3: null,
          valB3: null,
          rate3: null,
          valA4: null,
          valB4: null,
          rate4: null,
          valA5: null,
          valB5: null,
          rate5: null,
          valA6: null,
          valB6: null,
          rate6: null,
          valA7: null,
          valB7: null,
          rate7: null,
          valA8: null,
          valB8: null,
          rate8: null,
          valA9: null,
          valB9: null,
          rate9: null
        }
      ],
      excelInfo: {
        scenarioCd: '',
        scenarioNm: '',
        selectFlagTxt: '',
        optionFlagTxt: '',
        optionFlag: '',
        periodDiv: '',
        periodFrA: '',
        periodToA: '',
        periodFrB: '',
        periodToB: ''
      },
      isInitial: true,
      isExistResult: false
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    //alert(this.auth.userName)
    //alert(this.auth.userId)
    //alert(JSON.stringify(this.auth))
    //this.send.createUser = this.auth.userId
    //alert(this.send.createUser)
  },
  mounted: function () {
    const $vm = this
    window.func = this
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    // 그리고 헤더 높이 조정
    gridView.header.heights = [45]
    gridView.displayOptions.rowHeight = 34
    gridView.displayOptions.fitStyle = 'even'
    gridView.footers.visible = false
    gridView.setStateBar({
      visible: false
    })
    gridView.setCheckBar({
      visible: false
    })
    gridView.setRowIndicator({
      visible: false
    })
    gridView.footers.visible = false
    // 그리드 필드 생성
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.displayOptions.selectionStyle = 'rows'

    gridView.setSortingOptions({
      style: 'inclusive',
      showSortOrder: true
    })
    gridView.columnByName('searchTarget').sortable = false

    gridView.onShowTooltip = function (grid, idx, value) {
      const columns = ['remark', 'porCtr', 'porPlc', 'dlyCtr', 'dlyPlc']
      if (columns.includes(idx.column)) {
        let breakLine = ''
        let oneLine = ''
        let oneLineByteLength = 0

        for (const token of value) {
          oneLine += token

          // 한글과 한글 외 텍스트의 너비 계산
          const unicode = token.charCodeAt(0)
          if (unicode > 127 || (unicode > 64 && unicode < 91)) {
            oneLineByteLength += 2
          } else {
            oneLineByteLength += 1
          }

          // 조건에 따른 개행
          if (token === '\n' || (token === ' ' && oneLineByteLength > 70)) {
            breakLine += oneLine + (token === '\n' ? '' : ' \n')
            oneLine = ''
            oneLineByteLength = 0
          }
        }
        breakLine += oneLine
        return breakLine
      }
    }

    provider2 = new LocalDataProvider(true)
    gridView2 = new GridView('realgrid2')
    gridView2.setDataSource(provider2)
    // 그리고 헤더 높이 조정
    gridView2.header.heights = [23, 22]
    gridView2.setDisplayOptions({
      rowHeight: 34,
      fitStyle: 'none',
      columnMovable: false,
      columnResizable: false,
      selectionStyle: 'rows'
    })
    gridView2.footers.visible = false
    gridView2.setStateBar({
      visible: false
    })
    gridView2.setCheckBar({
      visible: false
    })
    gridView2.setRowIndicator({
      visible: false
    })
    gridView2.footers.visible = false
    // 그리드 필드 생성
    provider2.setFields(fields2)
    gridView2.setColumns(columns2)
    gridView2.setColumnLayout(layout2)
    gridView2.fixedOptions.colCount = 2
    gridView2.fixedOptions.colBarWidth = 0

    gridView2.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })

    gridView2.onCellDblClicked = function (grid, clickData) {
      if (clickData.column !== 'bkgShipper' && clickData.column !== 'bkgShipperNm') {
        let name = ''
        if (clickData.cellType === 'header' && clickData.column.includes('item')) {
          name = clickData.column
          $vm.uncheckItem(name)
        } else if (clickData.cellType === 'data') {
          name = layout2.slice(2).filter(obj => obj.items.includes(clickData.column))[0].name
          $vm.uncheckItem(name)
        }
      }
    }

    // 엑셀 다운로드용 리얼 그리드 설정
    const fields3 = [
      { fieldName: 'name', dataType: 'text' },
      { fieldName: 'valA', dataType: 'number' },
      { fieldName: 'valB', dataType: 'number' },
      { fieldName: 'rate', dataType: 'number' },
      { fieldName: 'checked', dataType: 'boolean' }
    ]
    const columns3 = [
      { name: 'name', fieldName: 'name', header: { text: app.$t('cp.CTRL020G140.022') }, width: 190, styleName: 'rg-header' },
      { name: 'valA', fieldName: 'valA', header: { text: app.$t('cp.CTRL020G140.015') }, width: 110, styleName: 'text_right', excelFormat: '#,##0.0' },
      { name: 'valB', fieldName: 'valB', header: { text: app.$t('cp.CTRL020G140.018') }, width: 110, styleName: 'text_right', excelFormat: '#,##0.0' },
      { name: 'rate', fieldName: 'rate', header: { text: app.$t('cp.CTRL020G140.021') }, width: 110, styleName: 'text_right', excelFormat: '#,##0.0"%"' },
      { name: 'checked', fieldName: 'checked', header: { text: app.$t('cp.CTRL020G140.043') }, visible: false }
    ]

    provider3 = new LocalDataProvider(true)
    gridView3 = new GridView('realgrid3')
    gridView3.setDataSource(provider3)
    gridView.header.heights = [45]
    gridView3.displayOptions.fitStyle = 'even'
    gridView3.setDisplayOptions({
      rowHeight: 34,
      fitStyle: 'even'
    })
    provider3.setFields(fields3)
    gridView3.setColumns(columns3)
    gridView3.displayOptions.selectionStyle = 'rows'

    $vm.getActCateList()
    $vm.getCountryList()
  },
  methods: {
    openAlert: function (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('cp.COMMON.005'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    getCountryList: function () {
      cpCommonInfo.countryList().then(response => {
        this.countryList = response.data
        this.countryList2 = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    getActCateList: function () {
      cpCommonInfo.cpCommonCodeList('CP00').then(response => {
        this.actCateList = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    getHarborList: function (val) {
      cpCommonInfo.harborList(val).then(response => {
        this.harborList = response.data
        this.param.porPlc = ''
      }).catch(err => {
        console.log(err)
      })
    },
    getHarborList2: function (val) {
      cpCommonInfo.harborList(val).then(response => {
        this.harborList2 = response.data
        this.param.dlyPlc = ''
      }).catch(err => {
        console.log(err)
      })
    },
    select: function () {
      scenarioResult.list(this.param).then(response => {
        this.list = response.data
        if (this.list.length > 0) {
          this.isInitial = false
          provider.setRows(this.list)
          gridView.setCurrent({ itemIndex: 0 })
        } else {
          this.$ekmtcCommon.alertCallback(this.$t('cp.CTRL020G140.044'), () => {
            return false
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    changeDateRange (s, d) {
      this.param.effectFr = s
      this.param.effectTo = d
    },
    openPopup (dataRow) {
      const scenarioCd = provider.getValue(dataRow, 'scenarioCd')
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose()
      }
      this.parentInfo.scenarioCd = scenarioCd
      this.customComponent = 'ScenarioResultPop'
      this.$ekmtcCommon.layerOpen('.sample_popup_list')
    },
    closePopup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.sample_popup_list')
    },
    selectFunc: function () {
      this.belowParam.scenarioCd = gridView.getValue(gridView.getCurrent().itemIndex, 'scenarioCd')
      this.belowParam.scenarioNm = gridView.getValue(gridView.getCurrent().itemIndex, 'scenarioNm')

      if (this.belowParam.scenarioCd === '') {
        this.openAlert(this.$t('cp.CTRL020G140.045'))
        return
      }

      if (this.belowParam.periodFrA === '' || this.belowParam.periodToA === '' || this.belowParam.periodFrB === '' || this.belowParam.periodToB === '') {
        this.openAlert(this.$t('cp.CTRL020G140.046'))
        return
      }

      if (this.belowParam.periodFrA > this.belowParam.periodToA) {
        this.openAlert(this.$t('cp.CTRL020G140.047'))
        return
      }

      if (this.belowParam.periodFrB > this.belowParam.periodToB) {
        this.openAlert(this.$t('cp.CTRL020G140.048'))
        return
      }

      scenarioResult.sfGetScenarioResult(this.belowParam).then(response => {
        this.isExistResult = true
        if (this.belowParam.optionFlag === 'E') {
          document.querySelector('.srgraph_container').classList.remove('srgraph_hidden')
          document.querySelector('.srgrid_container').classList.add('srgrid_hidden')
        } else if (this.belowParam.optionFlag === 'I') {
          document.querySelector('.srgraph_container').classList.add('srgraph_hidden')
          document.querySelector('.srgrid_container').classList.remove('srgrid_hidden')
        }

        this.mappingData(response.data)
        this.getExcelInfo()
      }).catch(err => {
        console.log(err)
      })
    },
    readonlyItemchk: function ($e) {
      if (!this.isExistResult) {
        $e.target.checked = true
      }
    },
    getNormalBarWidth: function (period, side, item) {
      const valA = item.valA
      const valB = item.valB
      const maxVal = valA >= valB ? valA : valB
      const minVal = valA >= valB ? valB : valA
      const baseVal = minVal < 0 ? minVal : 0
      const scale = 8.8

      // 정상적인 범주에서 벗어난 수치에 대한 일괄 처리
      if (valA === null && valB === null) {
        return '0px'
      }
      if (valA < 0 && valB < 0 && valA === valB && side === 'left') {
        return `${scale}vw`
      }

      if (side === 'left') {
        if (period === 'A' && valA >= 0) {
          return '0px'
        } else if (period === 'A' && valA < 0) {
          return `${parseFloat(Math.abs(valA / (maxVal - baseVal) * scale))}vw`
        } else if (period === 'B' && valB >= 0) {
          return '0px'
        } else if (period === 'B' && valB < 0) {
          return `${parseFloat(Math.abs(valB / (maxVal - baseVal) * scale))}vw`
        }
      } else { // side === 'right'
        if (period === 'A' && valA > 0) {
          return `${parseFloat(Math.abs(valA / (maxVal - baseVal) * scale))}vw`
        } else if (period === 'A' && valA <= 0) {
          return '0px'
        } else if (period === 'B' && valB > 0) {
          return `${parseFloat(Math.abs(valB / (maxVal - baseVal) * scale))}vw`
        } else if (period === 'B' && valB <= 0) {
          return '0px'
        }
      }
    },
    getRateBarWidth: function (side, item) {
      const rate = item.rate
      const scale = 3.5

      if (side === 'left') {
        if (rate <= -100) {
          return `${scale}vw`
        } else if (rate > -100 && rate <= 0) {
          return `${Math.abs(rate) / 100 * scale}vw`
        } else { // rate >= 100
          return '0px'
        }
      } else { // side === 'right'
        if (rate >= 100) {
          return `${scale}vw`
        } else if (rate >= 0 && rate < 100) {
          return `${Math.abs(rate) / 100 * scale}vw`
        } else { // rate < 0
          return '0px'
        }
      }
    },
    mappingData: function (data) {
      if (this.belowParam.optionFlag === 'E') {
        const parseData = function (value) {
          return isNaN(value) ? null : Number(value)
        }
        this.itemDataE = {
          item1: { valA: parseData(data[0]?.valA1), valB: parseData(data[0]?.valB1), rate: parseData(data[0]?.rate1), name: this.$t('cp.CTRL020G140.034') },
          item2: { valA: parseData(data[0]?.valA2), valB: parseData(data[0]?.valB2), rate: parseData(data[0]?.rate2), name: this.$t('cp.CTRL020G140.035') },
          item3: { valA: parseData(data[0]?.valA3), valB: parseData(data[0]?.valB3), rate: parseData(data[0]?.rate3), name: this.$t('cp.CTRL020G140.036') },
          item4: { valA: parseData(data[0]?.valA4), valB: parseData(data[0]?.valB4), rate: parseData(data[0]?.rate4), name: this.$t('cp.CTRL020G140.037') },
          item5: { valA: parseData(data[0]?.valA5), valB: parseData(data[0]?.valB5), rate: parseData(data[0]?.rate5), name: this.$t('cp.CTRL020G140.038') },
          item6: { valA: parseData(data[0]?.valA6), valB: parseData(data[0]?.valB6), rate: parseData(data[0]?.rate6), name: this.$t('cp.CTRL020G140.039') },
          item7: { valA: parseData(data[0]?.valA7), valB: parseData(data[0]?.valB7), rate: parseData(data[0]?.rate7), name: this.$t('cp.CTRL020G140.040') },
          item8: { valA: parseData(data[0]?.valA8), valB: parseData(data[0]?.valB8), rate: parseData(data[0]?.rate8), name: this.$t('cp.CTRL020G140.041') },
          item9: { valA: parseData(data[0]?.valA9), valB: parseData(data[0]?.valB9), rate: parseData(data[0]?.rate9), name: this.$t('cp.CTRL020G140.042') }
        }

        this.itemDataI = []
        provider2.setRows(this.itemDataI)
      } else if (this.belowParam.optionFlag === 'I') {
        const keyArr = [
          'bkgShipper',
          'bkgShipperNm',
          'valA1',
          'valB1',
          'rate1',
          'valA2',
          'valB2',
          'rate2',
          'valA3',
          'valB3',
          'rate3',
          'valA4',
          'valB4',
          'rate4',
          'valA5',
          'valB5',
          'rate5',
          'valA6',
          'valB6',
          'rate6',
          'valA7',
          'valB7',
          'rate7',
          'valA8',
          'valB8',
          'rate8',
          'valA9',
          'valB9',
          'rate9'
        ]
        const result = []

        for (const obj of data) {
          const tmp = {}
          for (const key of keyArr) {
            tmp[key] = !isNaN(obj[key]) ? parseFloat(obj[key]) : obj[key] !== undefined ? obj[key] : null
          }
          result.push(tmp)
        }

        this.itemDataI = result
        provider2.setRows(this.itemDataI)
        this.itemDataE = {}
      }
    },
    uncheckItem: function (item) {
      if (this.isExistResult) {
        this.itemInfo[item].checked = false
        this.unhighlightSrgraph(item)
        this.sortItemOrder(item)
      }

      if (document.selection && document.selection.empty) {
        document.selection.empty()
      } else if (window.getSelection) {
        window.getSelection().removeAllRanges()
      }
    },
    highlightSrgraph: function (key) {
      if (this.isExistResult) {
        document.querySelectorAll(`#srgraph_${key} .srgraph_bar`).forEach((elem) => {
          elem.classList.add('srgraph_highlightbar')
        })
      }
    },
    unhighlightSrgraph: function (key) {
      if (this.isExistResult) {
        document.querySelectorAll(`#srgraph_${key} .srgraph_bar`).forEach((elem) => {
          elem.classList.remove('srgraph_highlightbar')
        })
      }
    },
    convertFormatPeriod: function (periodFr, periodTo, defaultText) {
      if (this.isExistResult) {
        return `${periodFr.replace(/(\d{4})(\d{1,2}(?=\d{2})|\d{1,2}(?=\d{1}))(\d{1,2}).*/, '$1.$2.$3')} ~\n   ${periodTo.replace(/(\d{4})(\d{1,2}(?=\d{2})|\d{1,2}(?=\d{1}))(\d{1,2}).*/, '$1.$2.$3')}`
      } else {
        return defaultText
      }
    },
    convertFormatNum: function (num, type) {
      if (num === null || num === undefined || num === '') {
        return ''
      } else {
        const intAdjust = Number.isInteger(num) ? '.0' : ''
        const perSign = type === 'rate' ? ' %' : ''
        return num.toLocaleString() + intAdjust + perSign
      }
    },
    sortItemOrder: function (item) {
      if (this.isExistResult) {
        this.itemOrder.splice(this.itemOrder.indexOf(item), 1)
        this.itemOrder.push(item)
        const checkedList = this.itemOrder.filter((elem) => this.itemInfo[elem].checked === true)
        const uncheckedList = this.itemOrder.filter((elem) => this.itemInfo[elem].checked === false)
        this.itemOrder = [...checkedList, ...uncheckedList]

        const sortedLayout = ['bkgShipper', 'bkgShipperNm']
        for (const item of this.itemOrder) {
          sortedLayout.push(layout2.slice(2).filter(obj => obj.name === item)[0])
        }
        gridView2.setColumnLayout(sortedLayout)

        for (const elem of Object.entries(this.itemInfo)) {
          const [item, info] = elem
          if (info.checked) {
            gridView2.layoutByName(item).visible = true
          } else {
            gridView2.layoutByName(item).visible = false
          }
        }

        if (checkedList.length !== 0) {
          gridView2.setVisible(true)
        } else {
          gridView2.setVisible(false)
        }
      }
    },
    getExcelInfo: function () {
      this.excelInfo.scenarioCd = this.belowParam.scenarioCd
      this.excelInfo.scenarioNm = this.belowParam.scenarioNm
      this.excelInfo.selectFlagTxt = Array.from(document.getElementsByName('shipper')).filter(tag => tag.checked)[0].labels[0].innerText
      this.excelInfo.optionFlagTxt = Array.from(document.getElementsByName('option')).filter(tag => tag.checked)[0].labels[0].innerText
      this.excelInfo.optionFlag = this.belowParam.optionFlag
      this.excelInfo.periodDiv = this.belowParam.periodDiv1 === 'P01' ? this.$t('cp.CTRL020G140.016') : this.$t('cp.CTRL020G140.017')
      this.excelInfo.periodFrA = this.belowParam.periodFrA.replace(/(\d{4})(\d{1,2}(?=\d{2})|\d{1,2}(?=\d{1}))(\d{1,2}).*/, '$1.$2.$3')
      this.excelInfo.periodToA = this.belowParam.periodToA.replace(/(\d{4})(\d{1,2}(?=\d{2})|\d{1,2}(?=\d{1}))(\d{1,2}).*/, '$1.$2.$3')
      this.excelInfo.periodFrB = this.belowParam.periodFrB.replace(/(\d{4})(\d{1,2}(?=\d{2})|\d{1,2}(?=\d{1}))(\d{1,2}).*/, '$1.$2.$3')
      this.excelInfo.periodToB = this.belowParam.periodToB.replace(/(\d{4})(\d{1,2}(?=\d{2})|\d{1,2}(?=\d{1}))(\d{1,2}).*/, '$1.$2.$3')
    },
    exportExcel: function () {
      const checkedList = this.itemOrder.filter((elem) => this.itemInfo[elem].checked === true)
      if (checkedList.length === 0) {
        this.openAlert(this.$t('cp.CTRL020G140.049'))
        return
      }

      const date = new Date()
      const formatDate = date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2) + '_' + ('0' + date.getHours()).slice(-2) + ('0' + date.getMinutes()).slice(-2) + ('0' + date.getSeconds()).slice(-2)

      const title = this.excelInfo.scenarioNm
      const subTitle = `${this.excelInfo.selectFlagTxt} / [${this.$t('cp.CTRL020G140.015')}] ${this.excelInfo.periodDiv}: ${this.excelInfo.periodFrA}~${this.excelInfo.periodToA}, [${this.$t('cp.CTRL020G140.018')}] ${this.excelInfo.periodDiv}: ${this.excelInfo.periodFrB}~${this.excelInfo.periodToB}`
      const fileName = `${this.excelInfo.scenarioCd}_${this.excelInfo.scenarioNm}_${formatDate}.xlsx`
      const sheetName = `${this.excelInfo.optionFlagTxt}`

      if (this.excelInfo.optionFlag === 'E') {
        const excelData = []
        this.itemOrder.map((key) => {
          if (this.itemInfo[key].checked) {
            excelData.push(this.itemDataE[key])
          }
        })

        provider3.setRows(excelData)
        gridView3.exportGrid({
          type: 'excel',
          target: 'local',
          documentTitle: {
            message: title,
            visible: true,
            spaceTop: 0,
            spaceBottom: 0,
            height: 30,
            styleName: 'excelTitle'
          },
          documentSubtitle: {
            message: subTitle,
            spaceBottom: 1,
            visible: true,
            height: 20
          },
          fileName: fileName,
          showProgess: true,
          progressMessage: this.$t('cp.CTRL020G140.050'),
          indicator: 'hidden',
          applyFixed: false,
          header: 'visible',
          footer: 'hidden',
          pagingAllItems: true,
          separateRows: true,
          styleName: 'excelTitle',
          exportGrids: [
            { grid: gridView3, sheetName: sheetName }
          ]
        })
      } else if (this.excelInfo.optionFlag === 'I') {
        gridView2.exportGrid({
          type: 'excel',
          target: 'local',
          documentTitle: {
            message: title,
            visible: true,
            spaceTop: 0,
            spaceBottom: 0,
            height: 30,
            styleName: 'excelTitle'
          },
          documentSubtitle: {
            message: subTitle,
            spaceBottom: 1,
            visible: true,
            height: 20
          },
          fileName: fileName,
          showProgess: true,
          progressMessage: this.$t('cp.CTRL020G140.050'),
          indicator: 'hidden',
          applyFixed: false,
          header: 'visible',
          footer: 'hidden',
          pagingAllItems: true,
          separateRows: true,
          styleName: 'excelTitle',
          exportGrids: [
            { grid: gridView2, sheetName: sheetName }
          ]
        })
      }
      this.openAlert(this.$t('cp.CTRL020G140.051'))
    }
  }
}
</script>
<style>
  /* sr : Scenario Result */
  .srtabmenu {
    table-layout: fixed;
  }

  .srtabmenu {
    border: 1px solid #e1e3eb !important;
  }

  .srtabmenu th {
    text-align: center;
  }

  .srtabmenu_itemchk td {
    text-align: center;
  }

  .srgraph_container {
    display: flex;
    padding: 10px;
    border: 1px solid #e1e3eb;
    gap: 10px;
  }

  .srgraph_content {
    width: 70%;
  }

  .srgraph_dashboard {
    display: flex;
    flex-wrap: wrap;
    height: inherit;
    gap: 10px;
  }

  .srgraph_unit {
    flex-basis: calc(33.33% - 6.7px);
    display: flex;
    height: 170px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .srgraph_unittitle {
    position: relative;
    width: 100%;
    height: 33px;
    border-top: 1px solid #e1e3eb;
    border-bottom: 1px solid #e1e3eb;
    background-color: #f6f9ff;
    color: #075bb9;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  .srgraph_unitrow {
    display: grid;
    position: relative;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
  }

  .srgraph_valA,
  .srgraph_valB {
    grid-template-columns: 2fr 1.5fr 6.5fr;
  }

  .srgraph_rate {
    grid-template-columns: 2fr 4fr 4fr;
  }

  .srgraph_leftbar {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }

  .srgraph_baseline {
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 27px;
    background-color: #e2e2e2;
  }

  .srgraph_basevalue {
    position: absolute;
    right: -11px;
    bottom: -17px;
    color: #777777;
    font-size: 12px;
  }

  .srgraph_rightbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  .srgraph_axistitle {
    font-size: 14px;
    line-height: 30px;
  }

  .srgraph_bar {
    width: 0px;
    height: 20px;
    opacity: 1;
    transition: width 0.5s ease-in, opacity 0.2s ease-out;
  }

  .srgraph_highlightbar {
    opacity: 0.7;
  }

  .srgraph_bar:hover {
    opacity: 0.7;
  }

  .srgraph_normalL {
    max-width: 20px;
  }
  .srgraph_normalR {
    max-width: 169px;
  }

  .srgraph_negative,
  .srgraph_positive {
    max-width: 67px;
  }

  .srgraph_normalL {
    background-color: #888888;
  }

  .srgraph_normalR {
    background-color: #888888;
  }

  .srgraph_negative {
    background-color: #0083df;
  }

  .srgraph_positive {
    background-color: #dc584e;
  }

  .srgraph_stats {
    width: 30%;
  }

  .srgraph_statshead,
  .srgraph_statsrow {
    display: grid;
    grid-template-columns: 1fr 1.1fr 1.1fr 0.8fr;
  }

  .srgraph_statshead {
    height: 66px;
    border-top: 1px solid #e1e3eb;
    border-bottom: 1px solid #e1e3eb;
    background: #f6f9ff;
    color: #075bb9;
    font-weight: 500;
    text-align: center;
  }

  .srgraph_statshead div:not(.srgraph_statshead div:first-child) {
    border-left: 1px solid #e1e3eb;
  }

  .srgraph_statsbody {
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 66px);
  }

  .srgraph_statsrow {
    text-align: center;
    flex-basis: 100%;
    border-bottom: 1px solid #e1e3eb;
  }

  .srgraph_statsvalue {
    display: flex;
    padding: 10px;
    border-left: 1px solid #e1e3eb;
    text-align: right;
    justify-content: flex-end;
    align-items: center;
  }

  .srgraph_statsname {
    padding-left: 5px;
    border-left: 0;
    background: #f6f9ff;
    color: #075bb9;
    font-weight: 500;
  }

  .srgraph_newline {
    white-space: pre;
  }

  .srgrid_hidden,
  .srgraph_hidden {
    display: none;
  }

  .srgraph_cleartag {
    visibility: hidden;
  }

  .srgraph_emptyvalue::after {
    content: '-';
  }

  .srgraph_centertext {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .excelTitle {
    font-size: 18px;
    font-weight: bold;
  }
</style>
