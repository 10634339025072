var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content full" },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("cp.CTRL010G010.001"))),
      ]),
      _c("div", { staticClass: "content_box mt10 clearfix" }, [
        _c("div", { staticClass: "col_6 float_left pr20" }, [
          _c("div", { staticClass: "flex_box mt20" }, [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("cp.CTRL010G010.002"))),
            ]),
            _c("span", { staticClass: "ml_auto cal_btn" }, [
              _c(
                "a",
                {
                  staticClass: "button sm",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addM.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cp.COMMON.009")))]
              ),
              _c(
                "a",
                {
                  staticClass: "button sm ml2",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.delM.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cp.COMMON.010")))]
              ),
            ]),
          ]),
          _c("div", {
            staticStyle: { width: "100%", height: "550px" },
            attrs: { id: "realgrid" },
          }),
        ]),
        _c("div", { staticClass: "col_4 float_right" }, [
          _c("div", { staticClass: "flex_box mt20" }, [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("cp.CTRL010G010.003"))),
            ]),
            _c("span", { staticClass: "ml_auto cal_btn" }, [
              _c(
                "a",
                {
                  staticClass: "button sm",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addD.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cp.COMMON.009")))]
              ),
              _c(
                "a",
                {
                  staticClass: "button sm ml2",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.delD.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cp.COMMON.010")))]
              ),
            ]),
          ]),
          _c("div", {
            staticStyle: { width: "100%", height: "550px" },
            attrs: { id: "realgrid2" },
          }),
        ]),
      ]),
      _c("div", { staticClass: "flex_box mt10" }, [
        _c(
          "a",
          {
            staticClass: "button blue lg mr5 ml_auto",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.asyncAlert(_vm.$t("cp.CTRL010G010.004"))
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("cp.COMMON.004")))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }