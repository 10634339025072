<template>
  <div class="content full">
    <e-breadcrumbs />
    <win-layer-pop class="sample_popup_list">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closePopup"
        :parent-info="parentInfo"
      />
    </win-layer-pop>
    <h1 class="page_title">{{ $t('cp.CTRL020G080.001') }}</h1>
    <div class="content_box mt10">
      <table class="tbl_search">
        <colgroup>
          <col width="100px">
          <col>
          <col width="80px">
          <col>
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('cp.CTRL020G080.002') }}</th>
            <td>{{ scenarioCd }}</td>
            <th>{{ $t('cp.CTRL020G080.003') }}</th>
            <td>{{ targetInfo.scenarioNm }}</td>
          </tr>
          <tr>
            <th>{{ $t('cp.CTRL020G080.004') }}</th>
            <td>{{ targetInfo.createUser }}</td>
            <th>{{ $t('cp.CTRL020G080.005') }}</th>
            <td>{{ targetInfo.category }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="content_box mt10">
      <h2 class="content_title">{{ $t('cp.CTRL020G080.006') }}</h2>
      <table class="tbl_col mt10">
        <colgroup>
          <col width="10%">
          <col width="5%">
          <col width="6%">
          <col width="5%">
          <col width="5%">
          <col width="4%">
          <col width="4%">
          <col width="4%">
          <col width="4%">
          <col width="4%">
          <col width="5%">
          <col width="5%">
          <col width="14%">
          <col width="14%">
          <col width="4%">
          <col width="6%">
          <col width="3%">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('cp.CTRL020G080.007') }}</th>
            <th>{{ $t('cp.CTRL020G080.008') }}</th>
            <th>
              <div>
                <span v-html="$t('cp.CTRL020G080.009').replace('\n', '<br>')"></span>&nbsp;
                <span class="position_relative">
                  <button type="button" class="tbl_icon help" @click="isShowHelp = !isShowHelp">help</button>
                  <div v-show="isShowHelp" class="tooltip_wrap short position_absolute" style="min-width:200px; left:0; top:24px;">
                    <div class="cont">
                      <button type="button" class="close" @click="isShowHelp = false">close</button>
                      <ul class="bul_list_sm t2" style="white-space: nowrap">
                        <li>{{ $t('cp.CTRL020G080.010') }}</li>
                      </ul>
                    </div>
                  </div>
                </span>
              </div>
            </th>
            <th>{{ $t('cp.CTRL020G080.011') }}</th>
            <th>{{ $t('cp.CTRL020G080.012') }}</th>
            <th>{{ $t('cp.CTRL020G080.013') }}<br>{{ $t('cp.CTRL020G080.015') }}</th>
            <th>{{ $t('cp.CTRL020G080.013') }}<br>{{ $t('cp.CTRL020G080.016') }}</th>
            <th>{{ $t('cp.CTRL020G080.017') }}</th>
            <th>{{ $t('cp.CTRL020G080.018') }}</th>
            <th>{{ $t('cp.CTRL020G080.019') }}</th>
            <th>{{ $t('cp.CTRL020G080.014') }}<br>{{ $t('cp.CTRL020G080.015') }}</th>
            <th>{{ $t('cp.CTRL020G080.014') }}<br>{{ $t('cp.CTRL020G080.016') }}</th>
            <th>{{ $t('cp.CTRL020G080.020') }}</th>
            <th>{{ $t('cp.CTRL020G080.021') }}</th>
            <th>{{ $t('cp.CTRL020G080.022') }}<br>{{ $t('cp.CTRL020G080.023') }}</th>
            <th>{{ $t('cp.CTRL020G080.022') }}<br>{{ $t('cp.CTRL020G080.024') }}</th>
            <th><button class="tbl_icon plus" @click.prevent="addActionRows()">plus</button></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(acItem, acIdx) in send.action" :key="acIdx">
            <td>
              <div class="tbl_form">
                <span class="dv">
                  <input type="text" v-model="acItem.actionNm" readonly>
                </span>
                <span class="dv wid20">
                  <button class="tbl_icon search" @click.prevent="openPopup('ActionListPop', acIdx)">{{ $t('cp.COMMON.001') }}</button>
                </span>
              </div>
            </td>
            <td>
              <select v-model="acItem.periodDiv" :disabled="acItem.ckPeriodDev !=='Y'">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in eachPeriodGbnList[acItem.actionCd]" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
            </td>
            <td>
              <span class="dv">
                <input type="checkbox" :id="'acUpdateApply' + acIdx" v-model="acItem.updateApplyYn" true-value="Y" false-value="" :disabled="acItem.ckUpdateApplyDate !== 'Y'"><label :for="'acUpdateApply' + acIdx"><span class="offscreen">No</span></label>
              </span>
            </td>
            <td>
              <div class="input_calendar short">
                <e-date-picker v-model="acItem.applyFr" style="width:90px" :disabled="acItem.inEtdDate !== 'Y'" />
              </div>
            </td>
            <td>
              <div class="input_calendar short">
                <e-date-picker v-model="acItem.applyTo" style="width:90px" :disabled="acItem.inEtaDate !== 'Y'" />
              </div>
            </td>
            <td>
              <select v-model="acItem.porCtr" @change="[getHarborList(acItem.porCtr, acIdx)]" :disabled="acItem.ckPorCtr!=='Y'">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in countryList" :key="idx" :value="item.ctrCd">{{ item.ctrEnm }}</option>
              </select>
            </td>
            <td>
              <select v-model="acItem.porPlc" :disabled="acItem.inPor!=='Y'">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in acItem.harborList" :key="idx" :value="item.plcCd">{{ item.plcNm }}</option>
              </select>
            </td>
            <td>
              <select v-model="acItem.ts1" :disabled="acItem.inTs1!=='Y'">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in harborAllList" :key="idx" :value="item.plcCd">{{ item.plcNm }}</option>
              </select>
            </td>
            <td>
              <select v-model="acItem.ts2" :disabled="acItem.inTs2!=='Y'">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in harborAllList" :key="idx" :value="item.plcCd">{{ item.plcNm }}</option>
              </select>
            </td>
            <td>
              <select v-model="acItem.ts3" :disabled="acItem.inTs3!=='Y'">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in harborAllList" :key="idx" :value="item.plcCd">{{ item.plcNm }}</option>
              </select>
            </td>
            <td>
              <select v-model="acItem.dlyCtr" @change="getHarborList2(acItem.dlyCtr, acIdx)" :disabled="acItem.ckDlyCtr!=='Y'">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in countryList" :key="idx" :value="item.ctrCd">{{ item.ctrEnm }}</option>
              </select>
            </td>
            <td>
              <select v-model="acItem.dlyPlc" :disabled="acItem.inDly!=='Y'">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in acItem.harborList2" :key="idx" :value="item.plcCd">{{ item.plcNm }}</option>
              </select>
            </td>
            <td>
              <div class="tbl_form">
                <span class="dv">
                  <input type="text" v-model="acItem.rtVesVoyage" :disabled="acItem.inRtVesVoyage!=='Y'" readonly>
                </span>
                <span class="dv wid20">
                  <button class="tbl_icon search" @click.prevent="openPopup('RtVesVoyagePop', acIdx)" :disabled="acItem.inRtVesVoyage!=='Y'">{{ $t('cp.COMMON.001') }}</button>
                </span>
              </div>
            </td>
            <td>
              <div class="tbl_form">
                <span class="dv">
                  <input type="text" v-model="acItem.cntrSizeTypeDgoog" :disabled="acItem.inCntrSizeTypeDgoog!=='Y'" readonly>
                </span>
                <span class="dv wid20">
                  <button class="tbl_icon search" @click.prevent="openPopup('CntrSizeTypeDgoogPop', acIdx)" :disabled="acItem.inCntrSizeTypeDgoog!=='Y'">{{ $t('cp.COMMON.001') }}</button>
                </span>
              </div>
            </td>
            <td><input v-model="acItem.bsaRate" style="width:40px" :disabled="acItem.ckBsaRate!=='Y'"></td>
            <td>
              <select v-model="acItem.bsaSign" :disabled="acItem.ckBsaSign!=='Y'">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in bsaESList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
            </td>
            <td><button class="tbl_icon minus" @click.prevent="removeActionRows(acIdx)">minus</button></td>
          </tr>
        </tbody>
      </table>
      <h2 class="content_title">{{ $t('cp.CTRL020G080.025') }}</h2>
      <table class="tbl_col mt10">
        <colgroup>
          <col width="10%">
          <col width="10%">
          <col width="10%">
          <col width="10%">
          <col width="10%">
          <col width="11%">
          <col width="11%">
          <col width="12%">
          <col width="12%">
          <col width="4%">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('cp.CTRL020G080.026') }}</th>
            <th>{{ $t('cp.CTRL020G080.027') }}</th>
            <th>{{ $t('cp.CTRL020G080.028') }}</th>
            <th>{{ $t('cp.CTRL020G080.029') }}</th>
            <th>{{ $t('cp.CTRL020G080.030') }}</th>
            <th>{{ $t('cp.CTRL020G080.031') }}</th>
            <th>{{ $t('cp.CTRL020G080.032') }}</th>
            <th>{{ $t('cp.CTRL020G080.033') }}</th>
            <th>{{ $t('cp.CTRL020G080.034') }}</th>
            <th><button class="tbl_icon plus" @click.prevent="addDeviceRows()">plus</button></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(dvItem, dvIdx) in send.device" :key="dvIdx">
            <td>
              <select v-model="dvItem.porCtr" @change="[getDeviceHarborList(dvItem.porCtr, dvIdx),getDeviceTsList(dvItem.porCtr, dvItem.porPlc, dvIdx)]">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in countryList" :key="idx" :value="item.ctrCd">{{ item.ctrEnm }}</option>
              </select>
            </td>
            <td>
              <select v-model="dvItem.porPlc" @change="getDeviceTsList(dvItem.porCtr, dvItem.porPlc, dvIdx)">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in dvItem.harborList" :key="idx" :value="item.plcCd">{{ item.plcNm }}</option>
              </select>
            </td>
            <td>
              <select v-model="dvItem.tml">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in dvItem.tsList" :key="idx" :value="item.trmlCd">{{ item.trmlNm }}</option>
              </select>
            </td>
            <td>
              <select v-model="dvItem.cntrSize">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in cntrSizeList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
            </td>
            <td>
              <select v-model="dvItem.cntrType">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in cntrTypeList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
            </td>
            <td>
              <select v-model="dvItem.baseComp">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in stdItemList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
            </td>
            <td>
              <select v-model="dvItem.equalSign">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in equalSignList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
            </td>
            <td>
              <div class="tbl_form">
                <span class="dv">
                  <select v-model="dvItem.objtComp" @change="dvItem.keyinVal=''">
                    <option value="">{{ $t('cp.COMMON.002') }}</option>
                    <option v-for="(item, idx) in targetItemList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
                  </select>
                </span>
                <span class="dv wid100" v-show="dvItem.objtComp === 'T05'"><input type="text" v-model="dvItem.keyinVal" style="padding:0 2px;"></span>
              </div>
            </td>
            <td>
              <select v-model="dvItem.cntrSign">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in deviceCtlGbnList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
            </td>
            <td><button class="tbl_icon minus" @click.prevent="removeDeviceRows(dvIdx)">minus</button></td>
          </tr>
        </tbody>
      </table>
      <h2 class="content_title">{{ $t('cp.CTRL020G080.035') }}</h2>
      <table class="tbl_col mt10">
        <colgroup>
          <col width="7%">
          <col width="8%">
          <col width="8%">
          <col width="8%">
          <col width="8%">
          <col width="8%">
          <col width="8%">
          <col width="8%">
          <col width="8%">
          <col width="10%">
          <col width="4%">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('cp.CTRL020G080.036') }}</th>
            <th colspan="4">{{ $t('cp.CTRL020G080.037') }}</th>
            <th colspan="4">{{ $t('cp.CTRL020G080.038') }}</th>
            <th>{{ $t('cp.CTRL020G080.039') }}</th>
            <th><button class="tbl_icon plus" @click.prevent="addPromotionRows()">plus</button></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(prItem, prIdx) in send.promotion" :key="prIdx" class="cp-parentnode">
            <td>
              <select v-model="prItem.inOutCd">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in pcList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
            </td>
            <td>
              <select v-model="prItem.ofCntrSize01">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in cntrSizeList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
              <select v-model="prItem.ofCntrSize02">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in cntrSizeList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
            </td>
            <td>
              <select v-model="prItem.ofCntrType01">
                <option v-for="(item, idx) in promoCntrTypeList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
              <select v-model="prItem.ofCntrType02">
                <option v-for="(item, idx) in promoCntrTypeList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
            </td>
            <td>
              <input type="text" v-model="prItem.ofDcRate01">
              <input type="text" v-model="prItem.ofDcRate02">
            </td>
            <td>
              <select v-model="prItem.ofDcType01">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option value="%">%</option>
                <option value="$">$</option>
              </select>
              <select v-model="prItem.ofDcType02">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option value="%">%</option>
                <option value="$">$</option>
              </select>
            </td>
            <td>
              <select v-model="prItem.dtCntrSize01">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in cntrSizeList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
              <select v-model="prItem.dtCntrSize02">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in cntrSizeList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
            </td>
            <td>
              <select v-model="prItem.dtCntrType01">
                <option v-for="(item, idx) in promoCntrTypeList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
              <select v-model="prItem.dtCntrType02">
                <option v-for="(item, idx) in promoCntrTypeList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
            </td>
            <td>
              <input type="text" v-model="prItem.dtDcRate01">
              <input type="text" v-model="prItem.dtDcRate02">
            </td>
            <td>
              <select v-model="prItem.dtDcType01" :required="true">
                <option value="$">$</option>
              </select>
              <select v-model="prItem.dtDcType02" :required="true">
                <option value="$">$</option>
              </select>
            </td>
            <td>
              <span class="dv">
                <input type="checkbox" :id="'prSalesEmail' + prIdx" v-model="prItem.salesCt" true-value="Y" false-value="N" default="N"><label :for="'prSalesEmail' + prIdx"><span class="offscreen">No</span></label>
              </span>
            </td>
            <td>
              <button class="tbl_icon minus" @click.prevent="removePromotionRows(prIdx)">minus</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex_box mt10">
      <a class="button blue lg mr5 ml_auto" href="#" @click.prevent="save">{{ $t('cp.CTRL020G080.067') }}</a>
      <a class="button blue lg mr5" href="#" @click.prevent="confirmed">{{ $t('cp.CTRL020G080.040') }}</a>
    </div>
  </div>
</template>

<script>
import scenarioTargetSet from '@/api/rest/cp/scenarioTargetSet'
import scenarioAction from '@/api/rest/cp/scenarioAction'
import cpCommonInfo from '@/api/rest/cp/cpCommonInfo'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'

export default {
  name: 'ScenarioAction',
  components: {
    EBreadcrumbs,
    EDatePicker: () => import('@/components/common/EDatePicker'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    ActionListPop: () => import('@/pages/cp/popup/ActionListPop'),
    RtVesVoyagePop: () => import('@/pages/cp/popup/RtVesVoyagePop'),
    CntrSizeTypeDgoogPop: () => import('@/pages/cp/popup/CntrSizeTypeDgoogPop')
  },
  data: function () {
    return {
      isShowHelp: false,
      customComponent: null,
      parentInfo: {
        actionCd: '',
        pRtVesVoyage: '',
        pCntrSizeTypeDgoog: '',
        pIdx: -1,
        usedActionCdList: [],
        porPlc: '',
        dlyPlc: '',
        porCtr: '',
        dlyCtr: ''
      },
      scenarioCd: '',
      targetInfo: {
        scenarioNm: '',
        createUser: '',
        categoryCd: '',
        category: ''
      },
      eachPeriodGbnList: [],
      countryList: [],
      fairwayList: [],
      shipNmList: [],
      cntrSizeList: [],
      cntrTypeList: [],
      promoCntrTypeList: [],
      dgoogList: [],
      bsaESList: [],
      portList: [],
      terminalList: [],
      stdItemList: [],
      equalSignList: [],
      targetItemList: [],
      deviceCtlGbnList: [],
      harborAllList: [],
      pcList: [],
      send: {
        action: [{
          scenarioCd: '',
          actionCd: '',
          actionNm: '',
          actionNo: 1,
          periodDiv: '',
          updateApplyYn: '',
          applyFr: '',
          applyTo: '',
          porCtr: '',
          porPlc: '',
          ts1: '',
          ts2: '',
          ts3: '',
          dlyCtr: '',
          dlyPlc: '',
          rtVesVoyage: '',
          cntrSizeTypeDgoog: '',
          bsaRate: '',
          bsaSign: '',
          harborList: [],
          harborList2: [],
          tsList: [],
          ckPeriodDev: '',
          ckUpdateApplyDate: '',
          inEtdDate: '',
          inEtaDate: '',
          ckPorCtr: '',
          inPor: '',
          inTs1: '',
          inTs2: '',
          inTs3: '',
          ckDlyCtr: '',
          inDly: '',
          inRtVesVoyage: '',
          inCntrSizeTypeDgoog: '',
          ckBsaRate: '',
          ckBsaSign: ''
        }],
        device: [{
          scenarioCd: '',
          invNo: 1,
          porCtr: '',
          porPlc: '',
          tml: '',
          cntrSize: '',
          cntrType: '',
          cntrDiv: '',
          cntrLev: '',
          baseComp: '',
          equalSign: '',
          objtComp: '',
          keyinVal: '',
          cntrSign: '',
          harborList: [],
          tsList: []
        }],
        promotion: [{
          scenarioCd: '',
          promoNo: 1,
          inOutCd: '',
          ofCntrSize01: '',
          ofCntrType01: 'ST1',
          ofDcRate01: '',
          ofDcType01: '',
          ofCntrSize02: '',
          ofCntrType02: 'ST1',
          ofDcRate02: '',
          ofDcType02: '',
          dtCntrSize01: '',
          dtCntrType01: 'ST1',
          dtDcRate01: '',
          dtDcType01: '$',
          dtCntrSize02: '',
          dtCntrType02: 'ST1',
          dtDcRate02: '',
          dtDcType02: '$',
          salesCt: ''
        }]
      },
      popIdx: 0
    }
  },
  watch: {
  },
  created () {
  },
  mounted: function () {
    this.scenarioCd = this.$route.params.scenarioCd
    this.send.action[0].scenarioCd = this.$route.params.scenarioCd
    this.send.device[0].scenarioCd = this.$route.params.scenarioCd
    this.send.promotion[0].scenarioCd = this.$route.params.scenarioCd

    const $vm = this

    $vm.getTargetInfo() //시나리오정보
    $vm.getCountryList() //나라
    $vm.getEachPeriodGbnList() //기간구분
    $vm.getFairwayList() //항로
    $vm.getShipNmList() //선명
    $vm.getCommonCodeList('CP11') //CNTR SIZE
    $vm.getCommonCodeList('CP12') //CNTR TYPE
    $vm.getCommonCodeList('CP13') //DG/OOG
    $vm.getCommonCodeList('CP08') //BSA 등호
    $vm.getCommonCodeList('CP03') //기준항목
    $vm.getCommonCodeList('CP05') //등호
    $vm.getCommonCodeList('CP04') //대상항목
    $vm.getCommonCodeList('CP09') //기기컨트롤구분
    $vm.getCommonCodeList('CP16') // P/C구분 (지불유형)
    $vm.getScenarioActionList() //시나리오 액션, 기기, 프로모션 정보 조회
    $vm.getHarborLAllist()
  },
  methods: {
    getCommonCodeList: function (code) {
      cpCommonInfo.cpCommonCodeList(code).then(response => {
        if (code === 'CP11') {
          this.cntrSizeList = response.data
        } else if (code === 'CP12') {
          this.cntrTypeList = response.data

          response.data.forEach((item) => {
            if (item.detailNm === 'GP') {
              this.promoCntrTypeList.push(item)
            }
          })
        } else if (code === 'CP13') {
          this.dgoogList = response.data
        } else if (code === 'CP08') {
          this.bsaESList = response.data
        } else if (code === 'CP03') {
          this.stdItemList = response.data
        } else if (code === 'CP05') {
          this.equalSignList = response.data.filter(elem => !['S07', 'S08', 'S09', 'S10'].includes(elem.detailCd))
        } else if (code === 'CP04') {
          this.targetItemList = response.data
        } else if (code === 'CP09') {
          this.deviceCtlGbnList = response.data
        } else if (code === 'CP16') {
          this.pcList = response.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async getEachPeriodGbnList () {
      // 시나리오 액션 화면 각 항목 활성, 비활성화 체크를 아래 쿼리 조회로 구분함
      // 비활성화 필요한 경우 아래 쿼리 살펴볼것
      cpCommonInfo.actionList().then(response => {
        this.eachPeriodGbnList = Object.fromEntries(response.data.map((row) => [row.actionCd, row.ckPeriodDev]))

        Object.entries(this.eachPeriodGbnList).forEach((elem, idx) => {
          const [actionCd, ckPeriodDev] = elem
          if (!ckPeriodDev) {
            this.eachPeriodGbnList[actionCd] = []
          } else if (['Q201'].includes(actionCd)) {
            this.eachPeriodGbnList[actionCd] = [{ detailCd: 'P03', detailNm: this.$t('cp.CTRL020G080.041') }]
          } else if (['Q301'].includes(actionCd)) {
            this.eachPeriodGbnList[actionCd] = [{ detailCd: 'P05', detailNm: this.$t('cp.CTRL020G080.042') }]
          } else {
            this.eachPeriodGbnList[actionCd] = [{ detailCd: 'P01', detailNm: this.$t('cp.CTRL020G080.043') }, { detailCd: 'P02', detailNm: this.$t('cp.CTRL020G080.044') }]
          }
        })
      }).catch(err => {
        console.log(err)
      })
    },
    getTargetInfo: function () {
      scenarioTargetSet.getTargetInfo(this.scenarioCd).then(response => {
        this.targetInfo = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    getScenarioActionList: function () {
      scenarioAction.scenarioActionList(this.scenarioCd).then(response => {
        if (response.data.action.length > 0) {
          this.send.action = response.data.action
        }
        if (response.data.device.length > 0) {
          this.send.device = response.data.device
        }
        if (response.data.promotion.length > 0) {
          this.send.promotion = response.data.promotion
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getCountryList: function () {
      cpCommonInfo.countryList().then(response => {
        this.countryList = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    getHarborLAllist: function () {
      cpCommonInfo.harborList('').then(response => {
        this.harborAllList = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    getHarborList: function (val, i) {
      cpCommonInfo.harborList(val).then(response => {
        this.send.action[i].harborList = response.data
        this.send.action[i].porPlc = ''
      }).catch(err => {
        console.log(err)
      })
    },
    getTsList: function (countryCd, harborCd, idx) {
      cpCommonInfo.terminalList(countryCd, harborCd).then(response => {
        this.send.action[idx].tsList = response.data
        this.send.action[idx].ts1 = ''
        this.send.action[idx].ts2 = ''
        this.send.action[idx].ts3 = ''
      }).catch(err => {
        console.log(err)
      })
    },
    getHarborList2: function (val, i) {
      cpCommonInfo.harborList(val).then(response => {
        console.log(JSON.stringify(response.data))
        this.send.action[i].harborList2 = response.data
        this.send.action[i].dlyPlc = ''
      }).catch(err => {
        console.log(err)
      })
    },
    getFairwayList: function () {
      cpCommonInfo.fairwayList().then(response => {
        this.fairwayList = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    getShipNmList: function () {
      cpCommonInfo.shipNmList().then(response => {
        this.shipNmList = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    getDeviceHarborList: function (val, i) {
      cpCommonInfo.harborList(val).then(response => {
        const portList = response.data.filter(harbor => harbor.plcCatCd === '01')
        this.send.device[i].harborList = portList
        this.send.device[i].porPlc = ''
      }).catch(err => {
        console.log(err)
      })
    },
    getDeviceTsList: function (countryCd, harborCd, idx) {
      cpCommonInfo.terminalList(countryCd, harborCd).then(response => {
        this.send.device[idx].tsList = response.data
        this.send.device[idx].tml = ''
      }).catch(err => {
        console.log(err)
      })
    },
    openAlert: function (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('cp.COMMON.005'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    openPopup (compNm, idx) {
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose()
      }

      if (compNm === 'RtVesVoyagePop') {
        if (this.send.action[idx].porCtr === '') {
          this.openAlert(this.$t('cp.CTRL020G080.045'))
          return
        }

        if (this.send.action[idx].porPlc === '') {
          this.openAlert(this.$t('cp.CTRL020G080.046'))
          return
        }

        if (this.send.action[idx].dlyCtr === '') {
          this.openAlert(this.$t('cp.CTRL020G080.047'))
          return
        }

        if (this.send.action[idx].dlyPlc === '') {
          this.openAlert(this.$t('cp.CTRL020G080.048'))
          return
        }
      }

      this.parentInfo.actionCd = this.send.action[idx].actionCd
      this.parentInfo.pRtVesVoyage = this.send.action[idx].rtVesVoyage
      this.parentInfo.pCntrSizeTypeDgoog = this.send.action[idx].cntrSizeTypeDgoog
      this.parentInfo.pIdx = idx
      this.parentInfo.usedActionCdList = []
      this.parentInfo.porPlc = this.send.action[idx].porPlc
      this.parentInfo.dlyPlc = this.send.action[idx].dlyPlc
      this.parentInfo.porCtr = this.send.action[idx].porCtr
      this.parentInfo.dlyCtr = this.send.action[idx].dlyCtr

      for (const action of this.send.action) {
        const actionCd = action.actionCd
        if (actionCd) { // 공백 대응
          this.parentInfo.usedActionCdList.push(actionCd)
        }
      }
      this.customComponent = compNm
      this.popIdx = idx
      this.$ekmtcCommon.layerOpen('.sample_popup_list')
    },
    closePopup (obj) {
      if (obj === undefined || obj === '' || obj === null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.sample_popup_list')
        return
      }
      if (this.customComponent === 'RtVesVoyagePop') {
        // 항로정보 결과 데이터의 /true 값은 프로모션 운임 10$이하인 경우를 구분하기 위한 값이며,
        // 특정 제약 조건시 사용하기 위해 만들었고, 현재 결과값만 받고 있음.
        if (obj.includes('/true')) {
          obj = obj.substring(0, obj.indexOf('/true'))
          this.send.action[this.popIdx].rtVesVoyage = String(obj)
        } else {
          this.send.action[this.popIdx].rtVesVoyage = String(obj)
        }
      } else if (this.customComponent === 'CntrSizeTypeDgoogPop') {
        this.send.action[this.popIdx].cntrSizeTypeDgoog = String(obj)
      } else if (this.customComponent === 'ActionListPop') {
        const pIdx = this.parentInfo.pIdx
        this.send.action[pIdx].actionCd = obj.actionCd
        this.send.action[pIdx].actionNm = obj.actionNm
        this.send.action[pIdx].periodDiv = ''
        this.send.action[pIdx].updateApplyYn = this.send.action[pIdx].updateApplyYn && obj.ckUpdateApplyDate ? 'Y' : ''
        this.send.action[pIdx].applyFr = ''
        this.send.action[pIdx].applyTo = ''
        this.send.action[pIdx].porCtr = ''
        this.send.action[pIdx].porPlc = ''
        this.send.action[pIdx].ts1 = ''
        this.send.action[pIdx].ts2 = ''
        this.send.action[pIdx].ts3 = ''
        this.send.action[pIdx].dlyCtr = ''
        this.send.action[pIdx].dlyPlc = ''
        this.send.action[pIdx].rtVesVoyage = ''
        this.send.action[pIdx].cntrSizeTypeDgoog = ''
        this.send.action[pIdx].bsaRate = ''
        this.send.action[pIdx].bsaSign = ''
        this.send.action[pIdx].ckPeriodDev = obj.ckPeriodDev
        this.send.action[pIdx].ckUpdateApplyDate = obj.ckUpdateApplyDate
        this.send.action[pIdx].inEtdDate = obj.inEtdDate
        this.send.action[pIdx].inEtaDate = obj.inEtaDate
        this.send.action[pIdx].ckPorCtr = obj.ckPorCtr
        this.send.action[pIdx].inPor = obj.inPor
        this.send.action[pIdx].inTs1 = obj.inTs1
        this.send.action[pIdx].inTs2 = obj.inTs2
        this.send.action[pIdx].inTs3 = obj.inTs3
        this.send.action[pIdx].ckDlyCtr = obj.ckDlyCtr
        this.send.action[pIdx].inDly = obj.inDly
        this.send.action[pIdx].inRtVesVoyage = obj.inRtVesVoyage
        this.send.action[pIdx].inCntrSizeTypeDgoog = obj.inCntrSizeTypeDgoog
        this.send.action[pIdx].ckBsaRate = obj.ckBsaRate ? obj.ckBsaRate : ''
        this.send.action[pIdx].ckBsaSign = obj.ckBsaSign ? obj.ckBsaSign : ''
      }
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.sample_popup_list')
    },
    addActionRows () {
      this.send.action.push({
        scenarioCd: this.scenarioCd,
        actionCd: '',
        actionNo: this.send.action.length + 1,
        periodDiv: '',
        updateApplyYn: '',
        applyFr: '',
        applyTo: '',
        porCtr: '',
        porPlc: '',
        ts1: '',
        ts2: '',
        ts3: '',
        dlyCtr: '',
        dlyPlc: '',
        rtVesVoyage: '',
        cntrSizeTypeDgoog: '',
        bsaRate: '',
        bsaSign: '',
        harborList: [],
        harborList2: [],
        tsList: [],
        ckPeriodDev: '',
        ckUpdateApplyDate: '',
        inEtdDate: '',
        inEtaDate: '',
        ckPorCtr: '',
        inPor: '',
        inTs1: '',
        inTs2: '',
        inTs3: '',
        ckDlyCtr: '',
        inDly: '',
        inRtVesVoyage: '',
        inCntrSizeTypeDgoog: '',
        ckBsaRate: '',
        ckBsaSign: ''
      })
    },
    removeActionRows (idx) {
      if (this.send.action.length === 1) {
        return
      }
      this.send.action.splice(idx, 1)
      this.resetActionNo()
    },
    resetActionNo () {
      const actions = [...this.send.action]
      let actionNo = 1
      for (const row of actions) {
        row.actionNo = actionNo
        actionNo++
      }
    },
    addDeviceRows () {
      this.send.device.push({
        scenarioCd: this.scenarioCd,
        invNo: this.send.device.length + 1,
        porCtr: '',
        porPlc: '',
        tml: '',
        cntrSize: '',
        cntrType: '',
        cntrDiv: '',
        cntrLev: '',
        baseComp: '',
        equalSign: '',
        objtComp: '',
        keyinVal: '',
        cntrSign: '',
        harborList: [],
        tsList: []
      })
    },
    removeDeviceRows (idx) {
      console.log(this.send.device.length)
      if (this.send.device.length === 1) {
        this.send.device[idx].invNo = 1
        this.send.device[idx].porCtr = ''
        this.send.device[idx].porPlc = ''
        this.send.device[idx].tml = ''
        this.send.device[idx].cntrSize = ''
        this.send.device[idx].cntrType = ''
        this.send.device[idx].cntrDiv = ''
        this.send.device[idx].cntrLev = ''
        this.send.device[idx].baseComp = ''
        this.send.device[idx].equalSign = ''
        this.send.device[idx].objtComp = ''
        this.send.device[idx].keyinVal = ''
        this.send.device[idx].cntrSign = ''
        this.send.device[idx].harborList = []
        this.send.device[idx].tsList = []
        return
      }
      this.send.device.splice(idx, 1)
      this.resetInvNo()
    },
    resetInvNo () {
      const device = [...this.send.device]
      let invNo = 1
      for (const row of device) {
        row.invNo = invNo
        invNo++
      }
    },
    async asyncAlert (msg, typ) {
      let obj = {
        alertType: 'normal',
        showXclose: true,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: this.$t('cp.COMMON.007'),
        customConfirmBtnText: this.$t('cp.COMMON.006'),
        customConfirmBtnClass: 'button blue lg',
        useConfirmBtn: true,
        title: this.$t('cp.CTRL020G080.049'),
        message: msg,
        type: 'info'
      }
      if (await this.$ekmtcCommon.asyncAlert(obj)) {
        if (typ === 'entry') {
          this.entryAction()
        } else if (typ === 'target') {
          this.target()
        }
      }
    },
    checkRows () {
      this.resetActionNo()
      this.resetInvNo()
      this.resetPromoNo()

      const actions = [...this.send.action]
      for (const row of actions) {
        if (row.actionCd === '') {
          this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.050'), [row.actionNo]))
          return
        }

        if (row.ckPeriodDev === 'Y' && row.periodDiv === '') {
          this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.051'), [row.actionNo]))
          return
        }

        if (row.inEtdDate === 'Y' && row.applyFr === '') {
          this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.052'), [row.actionNo]))
          return
        }

        if (row.inEtaDate === 'Y' && row.applyTo === '') {
          this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.053'), [row.actionNo]))
          return
        }

        if (row.applyFr > row.applyTo) {
          this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.054'), [row.actionNo]))
          return
        }

        // 프로모션 항목 선택시 필수값 체크
        if (row.actionCd === 'P101') {
          const promotion = [...this.send.promotion]
          for (const row of promotion) {
            if (row.inOutCd === '') { // p/c
              this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.055'), [row.promoNo]))
              return
            }

            if (row.ofDcRate01 !== '' || row.ofDcRate02 !== '') {
              if (row.ofCntrSize01 === '' || row.ofCntrSize02 === '') {
                this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.056'), [row.promoNo]))
                return
              }

              if (row.ofCntrType01 === '' || row.ofCntrType02 === '') {
                this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.057'), [row.promoNo]))
                return
              }

              if (row.ofDcType01 === '' || row.ofDcType02 === '') {
                this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.058'), [row.promoNo]))
                return
              }
            }

            if (row.dtDcRate01 !== '' || row.dtDcRate02 !== '') {
              if (row.dtCntrSize01 === '' || row.dtCntrSize02 === '') {
                this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.059'), [row.promoNo]))
                return
              }

              if (row.dtCntrType01 === '' || row.dtCntrType02 === '') {
                this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.060'), [row.promoNo]))
                return
              }
            }
          }
        }
      }

      const device = [...this.send.device]
      for (const row of device) {
        if (row.baseComp !== '') {
          if (row.equalSign === '') {
            this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.061'), [row.invNo]))
            return
          } else if (row.objtComp === '') {
            this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.062'), [row.invNo]))
            return
          }
        }

        if (row.equalSign !== '') {
          if (row.baseComp === '') {
            this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.063'), [row.invNo]))
            return
          } else if (row.objtComp === '') {
            this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.064'), [row.invNo]))
            return
          }
        }

        if (row.objtComp !== '') {
          if (row.baseComp === '') {
            this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.063'), [row.invNo]))
            return
          } else if (row.equalSign === '') {
            this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.061'), [row.invNo]))
            return
          }
        }

        if (row.objtComp === 'T05' && row.keyinVal === '') {
          this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G080.064'), [row.invNo]))
          return
        }
      }

      return true
    },
    save () {
      if (this.checkRows()) {
        scenarioAction.saveScenarioAction(this.send).then(response => {
          this.openAlert(this.$t('cp.CTRL020G080.065'))
        }).catch(err => {
          console.log(err)
        })
      }
    },
    async confirmed () {
      if (this.checkRows()) {
        await scenarioAction.saveScenarioAction(this.send).then(response => {
          this.openAlert(this.$t('cp.CTRL020G080.065'))
        }).catch(err => {
          console.log(err)
        })

        await scenarioAction.saveScenarioConfirmed(this.scenarioCd).then(response => {
          this.openAlert(this.$t('cp.CTRL020G080.066'))
        }).catch(err => {
          console.log(err)
        })
      }
    },
    addPromotionRows () {
      this.send.promotion.push({
        scenarioCd: this.scenarioCd,
        promoNo: this.send.promotion.length + 1,
        inOutCd: '',
        ofCntrSize01: '',
        ofCntrSize02: '',
        ofCntrType01: 'ST1',
        ofCntrType02: 'ST1',
        ofDcRate01: '',
        ofDcRate02: '',
        ofDcType01: '',
        ofDcType02: '',
        dtCntrSize01: '',
        dtCntrSize02: '',
        dtCntrType01: 'ST1',
        dtCntrType02: 'ST1',
        dtDcRate01: '',
        dtDcRate02: '',
        dtDcType01: '$',
        dtDcType02: '$',
        salesCt: ''
      })
    },
    removePromotionRows (idx) {
      console.log(this.send.promotion.length)
      if (this.send.promotion.length === 1) {
        this.send.promotion[idx].promoNo = 1
        this.send.promotion[idx].inOutCd = ''
        this.send.promotion[idx].ofCntrSize01 = ''
        this.send.promotion[idx].ofCntrType01 = ''
        this.send.promotion[idx].ofDcRate01 = ''
        this.send.promotion[idx].ofDcType01 = ''
        this.send.promotion[idx].ofCntrSize02 = ''
        this.send.promotion[idx].ofCntrType02 = ''
        this.send.promotion[idx].ofDcRate02 = ''
        this.send.promotion[idx].ofDcType02 = ''
        this.send.promotion[idx].dtCntrSize01 = ''
        this.send.promotion[idx].dtCntrType01 = ''
        this.send.promotion[idx].dtDcRate01 = ''
        this.send.promotion[idx].dtDcType01 = ''
        this.send.promotion[idx].dtCntrSize02 = ''
        this.send.promotion[idx].dtCntrType02 = ''
        this.send.promotion[idx].dtDcRate02 = ''
        this.send.promotion[idx].dtDcType02 = ''
        this.send.promotion[idx].salesCt = ''
        return
      }
      this.send.promotion.splice(idx, 1)
    },
    resetPromoNo () {
      const promotion = [...this.send.promotion]
      let promoNo = 1
      for (const row of promotion) {
        row.promoNo = promoNo
        promoNo++
      }
    },
    convertCPLocale (expr, strs) {
      const regex = /\${.*?\}/g
      const patternList = expr.match(regex)
      for (let i = 0; i < patternList.length; i++) {
        if (strs[i] !== undefined) {
          expr = expr.replace(patternList[i], strs[i])
        }
      }
      return expr
    }
  }
}
</script>
<style>
  .cp-parentnode select+select,
  .cp-parentnode input[type="text"]+input[type="text"] {
    margin-top: 5px !important;
    margin-left: 0px !important;
  }
</style>
