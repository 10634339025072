import Send from '../../cp.client.js'

export default {
    list () {
        return Send({
            url: '/cp/actionList/list',
            method: 'get'
        })
    },
    apiList () {
        return Send({
            url: '/cp/actionList/apiList',
            method: 'get'
        })
    },
    save (data) {
        return Send({
            url: '/cp/actionList/save',
            method: 'put',
            data: data
        })
    },
    actionCdVali (param) {
        return Send({
            url: '/cp/actionList/actionCdVali',
            method: 'get',
            params: {
                actionCd: param
            }
        })
    },
    delete (param) {
        return Send({
            url: '/cp/actionList/delete',
            method: 'get',
            params: {
                actionCd: param
            }
        })
    }
}
