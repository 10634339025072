<template>
  <div class="content full">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('cp.CTRL010G030.001') }}</h1>
    <div class="content_box mt10 clearfix">
      <div class="col_5 float_left pr20">
        <h2 class="content_title">{{ $t('cp.CTRL010G030.002') }}</h2>
        <table class="tbl_row">
          <colgroup>
            <col width="100px">
            <col>
            <col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('cp.CTRL010G030.003') }}</th>
              <td>
                <input type="text" class="wid200" v-model="factorNm" ref="factorNmInput" @keydown.enter="search">
              </td>
              <td class="border_left_none text_right">
                <a class="button blue sh" href="#" @click.prevent="search">{{ $t('cp.COMMON.001') }}</a>
              </td>
            </tr>
          </tbody>
        </table>
        <div id="realgrid" class="mt10" style="width: 100%; height: 581px" />
      </div>
      <div class="col_5 float_right">
        <h2 class="content_title">&nbsp;</h2>
        <table class="tbl_row">
          <colgroup>
            <col width="110">
            <col>
            <col>
            <col width="110">
            <col>
            <col>
            <col width="110">
            <col>
            <col>
            <col width="110">
            <col>
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('cp.CTRL010G030.004') }} <span>*</span></th>
              <td colspan="11"><input id="factorCd" type="text" v-model="send.factorCd" ref="factorCdInput" @blur="factorCdVali" :readonly="!send.exist"></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL010G030.003') }} <span>*</span></th>
              <td colspan="5"><input type="text" v-model="send.factorNm" ref="factorNm"></td>
              <th>{{ $t('cp.CTRL010G030.005') }} <span>*</span></th>
              <td colspan="5"><input type="text" v-model="send.factorEnm" ref="factorEnm"></td>
            </tr>
            <tr>
              <th colspan="12" class="text_center">{{ $t('cp.CTRL010G030.006') }}</th>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL010G030.007') }} <span>*</span></th>
              <td colspan="2">
                <select v-model="send.periodUseYn">
                  <option value="">{{ $t('cp.COMMON.002') }}</option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
              </td>
              <th>{{ $t('cp.CTRL010G030.010') }} <span>*</span></th>
              <td colspan="2">
                <select v-model="send.inputUseYn">
                  <option value="">{{ $t('cp.COMMON.002') }}</option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
              </td>
              <th>{{ $t('cp.CTRL010G030.011') }} <span>*</span></th>
              <td colspan="2">
                <select v-model="send.routeLimitYn">
                  <option value="">{{ $t('cp.COMMON.002') }}</option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
              </td>
              <th>{{ $t('cp.CTRL010G030.012') }}</th>
              <td colspan="2">
                <select v-model="send.task">
                  <option value="">{{ $t('cp.COMMON.002') }}</option>
                  <option v-for="(item, idx) in taskList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th colspan="12" class="text_center">{{ $t('cp.CTRL010G030.013') }} <span>*</span></th>
            </tr>
            <tr>
              <td colspan="4" class="border_left_none"><input type="text" v-model="send.refTable1" ref="refTable1"></td>
              <td colspan="4"><input type="text" v-model="send.refTable2"></td>
              <td colspan="4"><input type="text" v-model="send.refTable3"></td>
            </tr>
            <tr>
              <th colspan="12" class="text_center">{{ $t('cp.CTRL010G030.014') }} <span>*</span></th>
            </tr>
            <tr>
              <td colspan="4" class="border_left_none"><input type="text" v-model="send.refColumn1" ref="refColumn1"></td>
              <td colspan="4"><input type="text" v-model="send.refColumn2"></td>
              <td colspan="4"><input type="text" v-model="send.refColumn3"></td>
            </tr>
            <tr>
              <td colspan="4" class="border_left_none"><input type="text" v-model="send.refColumn4"></td>
              <td colspan="4"><input type="text" v-model="send.refColumn5"></td>
              <td colspan="4"></td>
            </tr>
            <tr>
              <th colspan="12" class="text_center">{{ $t('cp.CTRL010G030.015') }}</th>
            </tr>
            <tr>
              <td colspan="4" class="border_left_none"><input type="text" v-model="send.refWhere1"></td>
              <td colspan="4"><input type="text" v-model="send.refWhere2"></td>
              <td colspan="4"><input type="text" v-model="send.refWhere3"></td>
            </tr>
            <tr>
              <th colspan="12" class="text_center">{{ $t('cp.CTRL010G030.016') }}</th>
            </tr>
            <tr>
              <td colspan="4" class="border_left_none"><input type="text" v-model="send.refGroup1"></td>
              <td colspan="4"><input type="text" v-model="send.refGroup2"></td>
              <td colspan="4"><input type="text" v-model="send.refGroup3"></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL010G030.017') }} <span>*</span></th>
              <td colspan="11"><input type="text" v-model="send.logic1" ref="logic1"></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL010G030.018') }}</th>
              <td colspan="11"><input type="text" v-model="send.logic2"></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL010G030.019') }} <span>*</span></th>
              <td colspan="2">
                <select v-model="send.factorType">
                  <option value="">{{ $t('cp.COMMON.002') }}</option>
                  <option v-for="(item, idx) in factorTypeList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
                </select>
              </td>
              <th>{{ $t('cp.CTRL010G030.020') }}</th>
              <td colspan="2">
                <input type="text" v-model="send.caseValue">
              </td>
              <th>{{ $t('cp.CTRL010G030.021') }}</th>
              <td colspan="2">
                <input type="text" v-model="send.caseUnit">
              </td>
              <th>{{ $t('cp.CTRL010G030.022') }} <span>*</span></th>
              <td colspan="2">
                <select v-model="send.queryType">
                  <option value="">{{ $t('cp.COMMON.002') }}</option>
                  <option v-for="(item, idx) in queryTypeList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL010G030.023') }}</th>
              <td colspan="5">
                <textarea v-model="send.remark"></textarea>
              </td>
              <th>{{ $t('cp.CTRL010G030.024') }}</th>
              <td colspan="5">
                <textarea v-model="send.remarkEn"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex_box mt10">
      <a class="button blue lg mr5 ml_auto" href="#" @click.prevent="new_init">{{ $t('cp.COMMON.003') }}</a>
      <a class="button blue lg mr5" href="#" @click.prevent="save">{{ $t('cp.COMMON.004') }}</a>
      <a class="button blue lg" href="#" @click.prevent="deleteFactor">{{ $t('cp.COMMON.010') }}</a>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import { GridView, LocalDataProvider } from 'realgrid'
import factorCodeMng from '@/api/rest/cp/factorCodeMng'
import cpCommonInfo from '@/api/rest/cp/cpCommonInfo'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'factorCd', dataType: 'text' },
  { fieldName: 'factorNm', dataType: 'text' },
  { fieldName: 'factorEnm', dataType: 'text' },
  { fieldName: 'remark', dataType: 'text' },
  { fieldName: 'remarkEn', dataType: 'text' },
  { fieldName: 'caseValue', dataType: 'text' },
  { fieldName: 'caseUnit', dataType: 'text' },
  { fieldName: 'periodUseYn', dataType: 'text' },
  { fieldName: 'inputUseYn', dataType: 'text' },
  { fieldName: 'factorType', dataType: 'text' },
  { fieldName: 'routeLimitYn', dataType: 'text' },
  { fieldName: 'task', dataType: 'text' },
  { fieldName: 'refTable1', dataType: 'text' },
  { fieldName: 'refTable2', dataType: 'text' },
  { fieldName: 'refTable3', dataType: 'text' },
  { fieldName: 'refColumn1', dataType: 'text' },
  { fieldName: 'refColumn2', dataType: 'text' },
  { fieldName: 'refColumn3', dataType: 'text' },
  { fieldName: 'refColumn4', dataType: 'text' },
  { fieldName: 'refColumn5', dataType: 'text' },
  { fieldName: 'refWhere1', dataType: 'text' },
  { fieldName: 'refWhere2', dataType: 'text' },
  { fieldName: 'refWhere3', dataType: 'text' },
  { fieldName: 'refGroup1', dataType: 'text' },
  { fieldName: 'refGroup2', dataType: 'text' },
  { fieldName: 'refGroup3', dataType: 'text' },
  { fieldName: 'logic1', dataType: 'text' },
  { fieldName: 'logic2', dataType: 'text' },
  { fieldName: 'queryType', dataType: 'text' }
]

export const columns = [
  { name: 'factorCd', fieldName: 'factorCd', type: 'text', header: { text: app.$t('cp.CTRL010G030.004') }, editable: false, width: 100 },
  { name: 'factorNm', fieldName: 'factorNm', type: 'text', header: { text: app.$t('cp.CTRL010G030.003') }, editable: false, width: 150, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'factorEnm', fieldName: 'factorEnm', type: 'text', header: { text: app.$t('cp.CTRL010G030.005') }, editable: false, width: 150, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'remark', fieldName: 'remark', type: 'text', header: { text: app.$t('cp.CTRL010G030.025') }, editable: false, width: 240, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'remarkEn', fieldName: 'remarkEn', type: 'text', header: { text: app.$t('cp.CTRL010G030.026') }, editable: false, width: 240, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'caseValue', fieldName: 'caseValue', type: 'text', header: { text: app.$t('cp.CTRL010G030.027') }, editable: false, width: 70 },
  { name: 'caseUnit', fieldName: 'caseUnit', type: 'text', header: { text: app.$t('cp.CTRL010G030.028') }, editable: false, width: 70 }
]

export default {
  name: 'FactorCodeMng',
  components: {
    EBreadcrumbs
  },
  data: function () {
    return {
      customComponent: null,
      taskList: [],
      factorTypeList: [],
      queryTypeList: [],
      gridList: [],
      factorNm: '',
      send: {
        exist: false,
        factorCd: '',
        factorNm: '',
        factorEnm: '',
        periodUseYn: '',
        inputUseYn: '',
        factorType: '',
        routeLimitYn: '',
        task: '',
        refTable1: '',
        refTable2: '',
        refTable3: '',
        refColumn1: '',
        refColumn2: '',
        refColumn3: '',
        refColumn4: '',
        refColumn5: '',
        refWhere1: '',
        refWhere2: '',
        refWhere3: '',
        refGroup1: '',
        refGroup2: '',
        refGroup3: '',
        logic1: '',
        logic2: '',
        caseValue: '',
        caseUnit: '',
        queryType: '',
        remark: '',
        remarkEn: ''
      },
      currentFactorCd: null
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    //alert(this.auth.userName)
    //alert(this.auth.userId)
    //alert(JSON.stringify(this.auth))
    //this.send.createUser = this.auth.userId
    //alert(this.send.createUser)
    cpCommonInfo.cpRole().then(response => {
      let cpAdmin = response.data.cpAdmin
      let cpUser = response.data.cpUser
      if (cpAdmin === 'Y') {
        //접근 권한 있음.
      } else {
        //접근 권한 없으므로 main 으로...
        this.$router.push('/main')
        //if (cpUser === 'Y') {
        //  this.$router.push('/main')
        //}
      }
    }).catch(err => {
      console.log(err)
    })
  },
  mounted: function () {
    console.log('mounted')
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.setDataSource(provider)

    // 그리고 헤더 높이 조정
    gridView.header.heights = [30]
    gridView.displayOptions.rowHeight = 34
    gridView.displayOptions.fitStyle = 'none'
    gridView.displayOptions.hscrollBar = true
    gridView.footers.visible = false
    gridView.setStateBar({
      visible: false
    })
    gridView.setRowIndicator({
      visible: false
    })
    gridView.setCheckBar({
      visible: false,
      exclusive: false
    })
    gridView.displayOptions.selectionStyle = 'rows'

    // realgrid 정렬 관련 옵션
    gridView.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })

    const $vm = this

    $vm.getCommonCodeList('CP10') //업무구분타입
    $vm.getCommonCodeList('CP14') //팩터값타입
    $vm.getCommonCodeList('CP15') //쿼리타입

    gridView.onCellClicked = function (grid, clickData) {
      if (clickData.cellType === 'data') {
        $vm.send.exist = false
        $vm.getGridInfo(clickData.dataRow)
      }
    }
  },
  methods: {
    factorCdVali () {
      if (this.send.factorCd !== '' && this.send.exist === true) {
        factorCodeMng.factorCdVali(this.send.factorCd).then(response => {
          if (response.data === 1) {
            this.$ekmtcCommon.alertCallback(this.$t('cp.CTRL010G030.029'), () => {
              this.send.factorCd = ''
              this.$refs.factorCdInput.focus()
            })
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    getCommonCodeList: function (code) {
      cpCommonInfo.cpCommonCodeList(code).then(response => {
        if (code === 'CP10') {
          this.taskList = response.data
        } else if (code === 'CP14') {
          this.factorTypeList = response.data
        } else if (code === 'CP15') {
          this.queryTypeList = response.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    search: function (alert = true) {
      factorCodeMng.list(this.factorNm).then(response => {
        this.gridList = response.data
        this.send.exist = false
        provider.setRows(this.gridList)
        const factorCdList = this.gridList.map((row) => row.factorCd)
        const idxRow = factorCdList.includes(this.currentFactorCd) ? factorCdList.indexOf(this.currentFactorCd) : 0
        this.currentFactorCd = null
        if (this.gridList.length > 0) {
          gridView.setCurrent({ dataRow: idxRow })
          this.getGridInfo(idxRow)
        } else if (alert) {
          this.new_init(false)
          this.$ekmtcCommon.alertCallback(this.$t('cp.CTRL010G030.030'), () => {
            this.$refs.factorNmInput.focus()
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    save: function () {
      if (this.send.factorCd === '') {
        this.$ekmtcCommon.alertCallback(this.$t('cp.CTRL010G030.031'), () => {
          this.$refs.factorCdInput.focus()
        })
        return
      }

      if (this.send.factorNm === '' || this.send.factorNm?.trim().length === 0) {
        this.$ekmtcCommon.alertCallback(this.$t('cp.CTRL010G030.032'), () => {
          this.$refs.factorNm.focus()
        })
        return
      }

      if (this.send.factorEnm === '' || this.send.factorEnm?.trim().length === 0) {
        this.$ekmtcCommon.alertCallback(this.$t('cp.CTRL010G030.032'), () => {
          this.$refs.factorEnm.focus()
        })
        return
      }

      if (this.send.periodUseYn === '') {
        this.openAlert(this.$t('cp.CTRL010G030.033'))
        return
      }

      if (this.send.inputUseYn === '') {
        this.openAlert(this.$t('cp.CTRL010G030.034'))
        return
      }

      if (this.send.routeLimitYn === '') {
        this.openAlert(this.$t('cp.CTRL010G030.035'))
        return
      }

      if (this.send.refTable1 === '') {
        this.$ekmtcCommon.alertCallback(this.$t('cp.CTRL010G030.036'), () => {
          this.$refs.refTable1.focus()
        })
        return
      }

      if (this.send.refColumn1 === '') {
        this.$ekmtcCommon.alertCallback(this.$t('cp.CTRL010G030.037'), () => {
          this.$refs.refColumn1.focus()
        })
        return
      }

      if (this.send.logic1 === '') {
        this.$ekmtcCommon.alertCallback(this.$t('cp.CTRL010G030.038'), () => {
          this.$refs.logic1.focus()
        })
        return
      }

      if (this.send.factorType === '') {
        this.openAlert(this.$t('cp.CTRL010G030.039'))
        return
      }

      if (this.send.queryType === '') {
        this.openAlert(this.$t('cp.CTRL010G030.040'))
        return
      }

      factorCodeMng.save(this.send).then(response => {
        this.currentFactorCd = this.send.factorCd
        this.search(false)
        this.openAlert(this.$t('cp.CTRL010G030.041'))
      }).catch(err => {
        console.log(err)
      })
    },
    deleteFactor: function () {
      this.$ekmtcCommon.confirmCallBack(this.$t('cp.CTRL010G030.042'), () => {
        factorCodeMng.deleteFactor(this.send.factorCd).then(response => {
          this.search()
          this.openAlert(this.$t('cp.CTRL010G030.043'))
        }).catch(err => {
          console.log(err)
        })
      })
    },
    new_init: function (focus = true) {
      gridView.clearCurrent()
      this.send.factorCd = ''
      this.send.factorNm = ''
      this.send.factorEnm = ''
      this.send.periodUseYn = ''
      this.send.inputUseYn = ''
      this.send.factorType = ''
      this.send.routeLimitYn = ''
      this.send.task = ''
      this.send.refTable1 = ''
      this.send.refTable2 = ''
      this.send.refTable3 = ''
      this.send.refColumn1 = ''
      this.send.refColumn2 = ''
      this.send.refColumn3 = ''
      this.send.refColumn4 = ''
      this.send.refColumn5 = ''
      this.send.refWhere1 = ''
      this.send.refWhere2 = ''
      this.send.refWhere3 = ''
      this.send.refGroup1 = ''
      this.send.refGroup2 = ''
      this.send.refGroup3 = ''
      this.send.logic1 = ''
      this.send.logic2 = ''
      this.send.caseValue = ''
      this.send.caseUnit = ''
      this.send.queryType = ''
      this.send.remark = ''
      this.send.remarkEn = ''
      if (focus) {
        this.send.exist = true
        this.$refs.factorCdInput.focus()
      }
    },
    openAlert: function (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('cp.COMMON.005'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    getGridInfo (idx) {
      this.send.factorCd = this.gridList[idx].factorCd
      this.send.factorNm = this.gridList[idx].factorNm
      this.send.factorEnm = this.gridList[idx].factorEnm
      this.send.periodUseYn = this.gridList[idx].periodUseYn
      this.send.inputUseYn = this.gridList[idx].inputUseYn
      this.send.factorType = this.gridList[idx].factorType
      this.send.routeLimitYn = this.gridList[idx].routeLimitYn
      this.send.task = this.gridList[idx].task
      this.send.refTable1 = this.gridList[idx].refTable1
      this.send.refTable2 = this.gridList[idx].refTable2
      this.send.refTable3 = this.gridList[idx].refTable3
      this.send.refColumn1 = this.gridList[idx].refColumn1
      this.send.refColumn2 = this.gridList[idx].refColumn2
      this.send.refColumn3 = this.gridList[idx].refColumn3
      this.send.refColumn4 = this.gridList[idx].refColumn4
      this.send.refColumn5 = this.gridList[idx].refColumn5
      this.send.refWhere1 = this.gridList[idx].refWhere1
      this.send.refWhere2 = this.gridList[idx].refWhere2
      this.send.refWhere3 = this.gridList[idx].refWhere3
      this.send.refGroup1 = this.gridList[idx].refGroup1
      this.send.refGroup2 = this.gridList[idx].refGroup2
      this.send.refGroup3 = this.gridList[idx].refGroup3
      this.send.logic1 = this.gridList[idx].logic1
      this.send.logic2 = this.gridList[idx].logic2
      this.send.caseValue = this.gridList[idx].caseValue
      this.send.caseUnit = this.gridList[idx].caseUnit
      this.send.queryType = this.gridList[idx].queryType
      this.send.remark = this.gridList[idx].remark
      this.send.remarkEn = this.gridList[idx].remarkEn
    }
  }
}
</script>
