import Send from '../../cp.client.js'

export default {
    list (param) {
        return Send({
            url: '/cp/scenarioResult/list',
            method: 'get',
            params: {
                scenarioCd: param.scenarioCd,
                scenarioNm: param.scenarioNm,
                createUser: param.createUser,
                category: param.category,
                effectFr: param.effectFr,
                effectTo: param.effectTo,
                porCtr: param.porCtr,
                porPlc: param.porPlc,
                dlyCtr: param.dlyCtr,
                dlyPlc: param.dlyPlc
            }
        })
    },
    detail (scenarioCd) {
        return Send({
            url: '/cp/scenarioResult/detail',
            method: 'get',
            params: {
                scenarioCd: scenarioCd
            }
        })
    },
    sfGetScenarioResult (param) {
        return Send({
            url: '/cp/scenarioResult/sfGetScenarioResult',
            method: 'get',
            params: {
                scenarioCd: param.scenarioCd,
                selectFlag: param.selectFlag,
                optionFlag: param.optionFlag,
                periodDiv: param.periodDiv1,
                periodFrA: param.periodFrA,
                periodToA: param.periodToA,
                periodFrB: param.periodFrB,
                periodToB: param.periodToB
            }
        })
    }
}
