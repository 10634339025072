import Send from '../../cp.client.js'

export default {
    list (factorNm) {
        return Send({
            url: '/cp/factorCode/list',
            method: 'get',
            params: {
                factorNm: factorNm
            }
        })
    },
    save (data) {
        return Send({
            url: '/cp/factorCode/save',
            method: 'put',
            data: data
        })
    },
    factorCdVali (param) {
        return Send({
            url: '/cp/factorCode/factorCdVali',
            method: 'get',
            params: {
                factorCd: param
            }
        })
    },
    deleteFactor (param) {
        return Send({
            url: '/cp/factorCode/delete',
            method: 'put',
            params: {
                factorCd: param
            }
        })
    }
}
