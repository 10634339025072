<template>
  <div class="content full">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('cp.CTRL010G010.001') }}</h1>
    <div class="content_box mt10 clearfix">
      <div class="col_6 float_left pr20">
        <div class="flex_box mt20">
          <h2 class="content_title">{{ $t('cp.CTRL010G010.002') }}</h2>
          <span class="ml_auto cal_btn">
            <a class="button sm" href="#" @click.prevent="addM">{{ $t('cp.COMMON.009') }}</a><a class="button sm ml2" href="#" @click.prevent="delM">{{ $t('cp.COMMON.010') }}</a>
          </span>
        </div>
        <div id="realgrid" style="width: 100%; height: 550px" />
      </div>
      <div class="col_4 float_right">
        <div class="flex_box mt20">
          <h2 class="content_title">{{ $t('cp.CTRL010G010.003') }}</h2>
          <span class="ml_auto cal_btn">
            <a class="button sm" href="#" @click.prevent="addD">{{ $t('cp.COMMON.009') }}</a><a class="button sm ml2" href="#" @click.prevent="delD">{{ $t('cp.COMMON.010') }}</a>
          </span>
        </div>
        <div id="realgrid2" style="width: 100%; height: 550px" />
      </div>
    </div>
    <div class="flex_box mt10">
      <a class="button blue lg mr5 ml_auto" href="#" @click.prevent="asyncAlert($t('cp.CTRL010G010.004'))">{{ $t('cp.COMMON.004') }}</a>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import commCodeMng from '@/api/rest/cp/commCodeMng'
import cpCommonInfo from '@/api/rest/cp/cpCommonInfo'

let gridView = GridView
let provider = LocalDataProvider
let gridView2 = GridView
let provider2 = LocalDataProvider

const fields = [
  { fieldName: 'state', dataType: 'text' },
  { fieldName: 'groupCd', dataType: 'text' },
  { fieldName: 'groupEnm', dataType: 'text' },
  { fieldName: 'groupNm', dataType: 'text' },
  { fieldName: 'detailCd', dataType: 'text' },
  { fieldName: 'orgDetailCd', dataType: 'text' },
  { fieldName: 'detailNm', dataType: 'text' },
  { fieldName: 'detailEnm', dataType: 'text' }
]
const columns = [
  { name: 'groupCd', fieldName: 'groupCd', header: { text: app.$t('cp.CTRL010G010.002') }, width: 100, editable: false },
  { name: 'groupNm', fieldName: 'groupNm', header: { text: app.$t('cp.CTRL010G010.005') }, width: 120, editable: false },
  { name: 'groupEnm', fieldName: 'groupEnm', header: { text: app.$t('cp.CTRL010G010.019') }, width: 150, editable: false },
  { name: 'detailCd', fieldName: 'detailCd', header: { text: app.$t('cp.CTRL010G010.006') }, width: 150 },
  { name: 'orgDetailCd', fieldName: 'orgDetailCd', header: { text: app.$t('cp.CTRL010G010.007') }, visible: false },
  { name: 'detailNm', fieldName: 'detailNm', header: { text: app.$t('cp.CTRL010G010.008') }, width: 170, styleName: 'text_left' },
  { name: 'detailEnm', fieldName: 'detailEnm', header: { text: app.$t('cp.CTRL010G010.020') }, width: 170, styleName: 'text_left' }
]

const fields2 = [
  { fieldName: 'state', dataType: 'text' },
  { fieldName: 'groupCd', dataType: 'text' },
  { fieldName: 'groupNm', dataType: 'text' },
  { fieldName: 'groupEnm', dataType: 'text' },
  { fieldName: 'detailCd', dataType: 'text' },
  { fieldName: 'orgDetailCd', dataType: 'text' },
  { fieldName: 'detailNm', dataType: 'text' },
  { fieldName: 'detailEnm', dataType: 'text' }
]
const columns2 = [
  { name: 'detailCd', fieldName: 'detailCd', header: { text: app.$t('cp.CTRL010G010.003') }, width: 100 },
  { name: 'orgDetailCd', fieldName: 'orgDetailCd', header: { text: app.$t('cp.CTRL010G010.009') }, visible: false },
  { name: 'detailNm', fieldName: 'detailNm', header: { text: app.$t('cp.CTRL010G010.010') }, width: 200, styleName: 'text_left' },
  { name: 'detailEnm', fieldName: 'detailEnm', header: { text: app.$t('cp.CTRL010G010.021') }, width: 200, styleName: 'text_left' }
]

let clickState = null

export default {
  name: 'CommonCodeMng',
  components: {
    EBreadcrumbs
  },
  props: {
  },
  data () {
    return {
      grpList: [],
      dtlList: [],
      send: {
        grpList: [],
        dtlList: []
      }
    }
  },
  created () {
    cpCommonInfo.cpRole().then(response => {
      let cpAdmin = response.data.cpAdmin
      let cpUser = response.data.cpUser
      if (cpAdmin === 'Y') {
        //접근 권한 있음.
      } else {
        //접근 권한 없으므로 main 으로...
        this.$router.push('/main')
        //if (cpUser === 'Y') {
        //  this.$router.push('/main')
        //}
      }
    }).catch(err => {
      console.log(err)
    })
  },
  mounted () {
    const $vm = this
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    gridView.header.heights = [30]
    gridView.footers.visible = false
    gridView.setDisplayOptions({
      rowHeight: 35,
      fitStyle: 'even',
      hscrollBar: true,
      selectionStyle: 'rows',
      columnMovable: false
    })
    gridView.setCheckBar({
      visible: true
    })
    // 데이터 삭제 시 행 상태만 deleted로 변경
    gridView.setOptions({
      softDeleting: true
    })
    gridView.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })
    provider.setFields(fields)
    gridView.setColumns(columns)

    provider2 = new LocalDataProvider(true)
    gridView2 = new GridView('realgrid2')
    gridView2.setDataSource(provider2)
    gridView2.header.heights = [30]
    gridView2.footers.visible = false
    gridView2.setDisplayOptions({
      rowHeight: 35,
      fitStyle: 'even',
      hscrollBar: true,
      selectionStyle: 'rows',
      columnMovable: false
    })
    gridView2.setCheckBar({
      visible: true,
      syncHeadCheck: false
    })
    // 데이터 삭제 시 행 상태만 deleted로 변경
    gridView2.setOptions({
      softDeleting: true
    })
    gridView2.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })
    provider2.setFields(fields2)
    gridView2.setColumns(columns2)

    // 세부그룹코드 변경 제한
    // 1. newValue가 기존에 존재하는 경우 (중복 방지)
    // 2. newValue가 공백인 경우 (공백값 방지)
    // 3. oldValue가 기존에 존재하는 경우 (기존의 값 변경 방지)
    gridView.onEditCommit = function (grid, cellInfo, oldValue, newValue) {
      if (cellInfo.fieldName === 'detailCd') { // 명시적 표현을 위한 중첩 if
        const orgDetailCdList = grid.getJsonRows().map((row) => row.orgDetailCd)
        if (orgDetailCdList.includes(oldValue) && oldValue !== '') {
          $vm.openAlert(this.$t('cp.CTRL010G010.011'))
        } else if (newValue === '') {
          $vm.openAlert(this.$t('cp.CTRL010G010.012'))
        } else if (orgDetailCdList.includes(newValue)) {
          $vm.openAlert(this.$t('cp.CTRL010G010.013'))
        } else {
          grid.checkRow(cellInfo.itemIndex)
          return true
        }
        grid.cancel()
        return false
      } else {
        grid.checkRow(cellInfo.itemIndex)
        return true
      }
    }

      // 상세코드 변경 제한
      gridView2.onEditCommit = function (grid2, cellInfo, oldValue, newValue) {
      if (cellInfo.fieldName === 'detailCd') { // 명시적 표현을 위한 중첩 if
        const orgDetailCdList = grid2.getJsonRows().map((row) => row.orgDetailCd)
        if (orgDetailCdList.includes(oldValue) && oldValue !== '') {
          $vm.openAlert(this.$t('cp.CTRL010G010.011'))
        } else if (newValue === '') {
          $vm.openAlert(this.$t('cp.CTRL010G010.012'))
        } else if (orgDetailCdList.includes(newValue)) {
          $vm.openAlert(this.$t('cp.CTRL010G010.013'))
        } else {
          grid2.checkRow(cellInfo.itemIndex)
          return true
        }
        grid2.cancel()
        return false
      } else {
        grid2.checkRow(cellInfo.itemIndex)
        return true
      }
    }

    gridView.onCellClicked = function (_grid, clickData) {
      if (clickData.dataRow && provider.getRowState(clickData.dataRow) === 'created') { //신규추가 로우시 디테일 조회 스킵
        let len = provider2.getJsonRows(0, -1).length - 1
        provider2.getJsonRows(0, -1).forEach((_row, index) => {
          provider2.removeRow(len - index)
        })
        return
      }

      clearTimeout(clickState)
      clickState = setTimeout(() => {
        if (clickData.cellType === 'data') {
          $vm.getDetailList(provider.getValue(clickData.dataRow, 'detailCd'))
        }
      }, 200)
    }

    gridView.onCellDblClicked = () => {
      clearTimeout(clickState)
    }

    $vm.getGroupList()
  },
  methods: {
    getGroupList () {
      commCodeMng.groupList().then(response => {
        this.grpList = response.data
        // 원본세부그룹코드 받아오기
        this.grpList.forEach((row) => {
          row.orgDetailCd = row.detailCd
        })
        provider.setRows(this.grpList)
        gridView.onDataLoadComplated = () => {
          gridView.setCheckBar({ syncHeadCheck: true })
        }

        this.getDetailList(this.grpList[0].detailCd)
        gridView.setCurrent({ dataRow: 0 }) // 포커스 유지
      }).catch(err => {
        console.log(err)
      })
    },
    getDetailList (groupCd) {
      commCodeMng.detailList(groupCd).then(response => {
        this.dtlList = response.data
        // 원본상세코드 받아오기
        this.dtlList.forEach((row) => {
          row.orgDetailCd = row.detailCd
        })
        gridView2.setCheckBar({ syncHeadCheck: false })
        provider2.setRows(this.dtlList)
        gridView2.onDataLoadComplated = () => {
          gridView2.setCheckBar({ syncHeadCheck: true })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('cp.COMMON.005'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    addM () {
      gridView.commit(false)
      gridView2.commit(false)
      provider.insertRow(0, { groupCd: 'COMM', groupNm: '공통그룹', groupEnm: 'Common Code Group', detailCd: '', orgDetailCd: '', detailNm: '', detailEnm: '' })
      // detail 초기화처리
      let len = provider2.getJsonRows(0, -1).length - 1
      provider2.getJsonRows(0, -1).forEach((row, index) => {
        provider2.removeRow(len - index)
      })
    },
    delM () {
      if (gridView.getCheckedRows().length !== 0) {
        gridView.commit(false)
        gridView2.commit(false)

        // 기존의 deleted state 초기화 (데이터 길이에 의한 인덱스로 참조)
        gridView.getJsonRows().forEach((row, idx) => {
          if (provider.getRowState(idx) === 'deleted') {
            provider.setRowState(idx, 'none')
          }
        })

        // 체크된 항목에 대하여 deleted state 새롭게 부여 (체크 항목의 위치에 의한 참조)
        gridView.getCheckedRows().forEach((row, index) => {
          if (provider.getRowState(row) === 'none') {
            provider.setRowState(row, 'deleted')
          }
        })
      } else {
        this.openAlert(this.$t('cp.CTRL010G010.017'))
      }
    },
    addD () {
      gridView.commit(false)
      gridView2.commit(false)
      provider2.insertRow(provider2.getJsonRows(0, -1).length, { groupCd: provider.getValue(gridView.getCurrent().dataRow, 'detailCd'), groupNm: provider.getValue(gridView.getCurrent().dataRow, 'detailNm'), groupEnm: provider.getValue(gridView.getCurrent().dataRow, 'detailEnm'), detailCd: '', orgDetailCd: '', detailNm: '', detailEnm: '' })
    },
    delD () {
      if (gridView2.getCheckedRows().length !== 0) {
        gridView.commit(false)
        gridView2.commit(false)

        // 기존의 deleted state 초기화 (데이터 길이에 의한 인덱스로 참조)
        gridView2.getJsonRows().forEach((_row, idx) => {
          if (provider2.getRowState(idx) === 'deleted') {
            provider2.setRowState(idx, 'none')
          }
        })

        // 체크된 항목에 대하여 deleted state 새롭게 부여 (체크 항목의 위치에 의한 참조)
        gridView2.getCheckedRows().forEach((row) => {
          if (provider2.getRowState(row) === 'none') {
            provider2.setRowState(row, 'deleted')
          }
        })
      } else {
        this.openAlert(this.$t('cp.CTRL010G010.017'))
      }
    },
    async asyncAlert (msg) {
      let obj = {
        alertType: 'normal',
        showXclose: true,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: this.$t('cp.COMMON.007'),
        customConfirmBtnText: this.$t('cp.COMMON.006'),
        customConfirmBtnClass: 'button blue lg',
        useConfirmBtn: true,
        title: this.$t('cp.CTRL010G010.001'),
        message: msg,
        type: 'info'
      }
      if (await this.$ekmtcCommon.asyncAlert(obj)) {
        this.save()
      }
    },
    save: function () {
      gridView.commit(false)
      gridView2.commit(false)

      const checkedRows = gridView.getCheckedRows() // 그룹코드(gridView)의 체크 목록
      const checkedRows2 = gridView2.getCheckedRows() // 상세코드(gridView2)의 체크 목록

      const detailCdList = gridView.getJsonRows().map((row) => row.detailCd).filter((_detailCd, idx) => checkedRows.includes(idx)) // 체크된 그룹코드 목록
      const detailCdList2 = gridView2.getJsonRows().map((row2) => row2.detailCd).filter((_detailCd, idx) => checkedRows2.includes(idx)) // 체크된 상세코드 목록

      // 체크한 그룹코드 목록 중 공백이 있는지 판별
      if (detailCdList.includes('')) {
        this.openAlert(this.$t('cp.CTRL010G010.015'))
        return
      }

      // 체크한 그룹코드 목록 중 공백이 있는지 판별
      if (detailCdList2.includes('')) {
        this.openAlert(this.$t('cp.CTRL010G010.016'))
        return
      }

      // 최소 체크 개수 판별
      if (checkedRows.length === 0 && checkedRows2.length === 0) {
        this.openAlert(this.$t('cp.CTRL010G010.017'))
        return
      }

      // 그룹코드
      provider.getAllStateRows().created.forEach((createRow) => {
        if (checkedRows.includes(createRow)) {
          provider.setValue(createRow, 'state', 'C')
        }
      })
      provider.getAllStateRows().updated.forEach((updateRow) => {
        if (checkedRows.includes(updateRow)) {
          provider.setValue(updateRow, 'state', 'U')
        }
      })
      provider.getAllStateRows().deleted.forEach((deleteRow) => {
        if (checkedRows.includes(deleteRow)) {
          provider.setValue(deleteRow, 'state', 'D')
        }
      })

      // 상세코드
      provider2.getAllStateRows().created.forEach((createRow2) => {
        if (checkedRows2.includes(createRow2)) {
          provider2.setValue(createRow2, 'state', 'C')
        }
      })
      provider2.getAllStateRows().updated.forEach((updateRow2) => {
        if (checkedRows2.includes(updateRow2)) {
          provider2.setValue(updateRow2, 'state', 'U')
        }
      })
      provider2.getAllStateRows().deleted.forEach((deleteRow2) => {
        if (checkedRows2.includes(deleteRow2)) {
          provider2.setValue(deleteRow2, 'state', 'D')
        }
      })

      this.send.grpList = provider.getJsonRows(0, -1)
      this.send.dtlList = provider2.getJsonRows(0, -1)

      commCodeMng.save(this.send).then(() => {
        this.getGroupList()
        this.openAlert(this.$t('cp.CTRL010G010.018'))
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
