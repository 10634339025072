<template>
  <div class="content full">
    <e-breadcrumbs />
    <win-layer-pop class="sample_popup_list">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closePopup"
        :parent-info="parentInfo"
      />
    </win-layer-pop>
    <h1 class="page_title">{{ $t('cp.CTRL010G050.001') }}</h1>
    <div class="content_box mt10">
      <table class="tbl_search">
        <colgroup>
          <col width="100px">
          <col>
          <col width="100px">
          <col>
          <col width="100px">
          <col>
          <col width="100px">
          <col>
          <col width="100px">
        </colgroup>
        <tbody>
          <tr>
            <th><span class="d-inline-block lh14">{{ $t('cp.CTRL010G050.002') }}<br>{{ $t('cp.CTRL010G050.004') }}</span></th>
            <td>
              <select v-model="param.porCtr" @change="getHarborList(param.porCtr)">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in countryList" :key="idx" :value="item.ctrCd">{{ item.ctrEnm }}</option>
              </select>
            </td>
            <th><span class="d-inline-block lh14">{{ $t('cp.CTRL010G050.002') }}<br>{{ $t('cp.CTRL010G050.005') }}</span></th>
            <td>
              <select v-model="param.porPlc">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in harborList" :key="idx" :value="item.plcCd">{{ item.plcNm }}</option>
              </select>
            </td>
            <th><span class="d-inline-block lh14">{{ $t('cp.CTRL010G050.003') }}<br>{{ $t('cp.CTRL010G050.004') }}</span></th>
            <td>
              <select v-model="param.dlyCtr" @change="getHarborList2(param.dlyCtr)">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in countryList" :key="idx" :value="item.ctrCd">{{ item.ctrEnm }}</option>
              </select>
            </td>
            <th><span class="d-inline-block lh14">{{ $t('cp.CTRL010G050.003') }}<br>{{ $t('cp.CTRL010G050.005') }}</span></th>
            <td>
              <select v-model="param.dlyPlc">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in harborList2" :key="idx" :value="item.plcCd">{{ item.plcNm }}</option>
              </select>
            </td>
            <td class="text_right"></td>
          </tr>
          <tr>
            <th><span class="d-inline-block lh14">{{ $t('cp.CTRL010G050.006') }}</span></th>
            <td>
              <div class="tbl_form">
                <span class="dv">
                  <input type="text" v-model="param.bkgShipper" readonly>
                </span>
                <span class="dv wid30">({{ param.bkgShipLength }})</span>
                <span class="dv wid20">
                  <button class="tbl_icon search" @click.prevent="openPopup('CstSearchListPop', 'bkShip')">{{ $t('cp.COMMON.001') }}</button>
                </span>
              </div>
            </td>
            <th><span class="d-inline-block lh14">{{ $t('cp.CTRL010G050.007') }}</span></th>
            <td>
              <div class="tbl_form">
                <span class="dv">
                  <input type="text" v-model="param.actShipper" readonly>
                </span>
                <span class="dv wid30">({{ param.actShipLength }})</span>
                <span class="dv wid20">
                  <button class="tbl_icon search" @click.prevent="openPopup('CstSearchListPop', 'actShip')">{{ $t('cp.COMMON.001') }}</button>
                </span>
              </div>
            </td>
            <th><span class="d-inline-block lh14">{{ $t('cp.CTRL010G050.008') }}</span></th>
            <td>
              <div class="tbl_form">
                <span class="dv">
                  <input type="text" v-model="param.hcneShipper" readonly>
                </span>
                <span class="dv wid30">({{ param.hcneShipLength }})</span>
                <span class="dv wid20">
                  <button class="tbl_icon search" @click.prevent="openPopup('CstSearchListPop', 'handConsi')">{{ $t('cp.COMMON.001') }}</button>
                </span>
              </div>
            </td>
            <th>{{ $t('cp.CTRL010G050.009') }}</th>
            <td>
              <div class="tbl_form">
                <span class="dv">
                  <input type="text" v-model="param.cneeShipper" readonly>
                </span>
                <span class="dv wid30">({{ param.cneeShipLength }})</span>
                <span class="dv wid20">
                  <button class="tbl_icon search" @click.prevent="openPopup('CstSearchListPop', 'consi')">{{ $t('cp.COMMON.001') }}</button>
                </span>
              </div>
            </td>
            <td class="text_right"></td>
          </tr>
          <tr>
            <th>{{ $t('cp.CTRL010G050.010') }}</th>
            <td>
              <div class="tbl_form">
                <span class="dv">
                  <input type="text" v-model="param.profile" readonly>
                </span>
                <span class="dv wid30">({{ param.profileLength }})</span>
                <span class="dv wid20">
                  <button class="tbl_icon search" @click.prevent="openPopup('ProfileListPop', 'profile')">{{ $t('cp.COMMON.001') }}</button>
                </span>
              </div>
            </td>
            <th>{{ $t('cp.CTRL010G050.011') }}</th>
            <td>
              <div class="tbl_form">
                <span class="dv"><input type="text" v-model="param.itemGrp" readonly></span>
                <span class="dv"><input type="text" v-model="param.item" readonly></span>
                <span class="dv wid30">({{ param.itemLength }})</span>
                <span class="dv wid20">
                  <button class="tbl_icon search" @click.prevent="openPopup('ItemListPop', 'item', kIidx)">{{ $t('cp.COMMON.001') }}</button>
                </span>
              </div>
            </td>
            <th>{{ $t('cp.CTRL010G050.012') }}</th>
            <td>
              <div class="input_calendar">
                <e-date-picker v-model="param.availDate" style="width:120px" />
              </div>
            </td>
            <td colspan="3" class="text_right">
              <a class="button blue sh" href="#" @click.prevent="handWritingList">{{ $t('cp.COMMON.001') }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="content_box mt10">
      <h2 class="content_title">{{ $t('cp.CTRL010G050.013') }}</h2>
      <div id="realgrid" style="width: 100%; height: 400px; margin-top: 10px;" />
      <h2 class="content_title">{{ $t('cp.CTRL010G050.014') }}</h2>
      <table class="tbl_row">
        <colgroup>
          <col width="150"><col><col width="150"><col><col width="160"><col>
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('cp.CTRL010G050.015') }}</th>
            <td>
              <select v-model="send.cstReqGrd">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option value="S">S</option>
                <option value="N">N</option>
                <option value="SCRAP">SCRAP</option>
              </select>
            </td>
            <th>{{ $t('cp.CTRL010G050.016') }}</th>
            <td>
              <select v-model="send.cntrSpplSnstvt">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option value="H">H</option>
                <option value="M">M</option>
                <option value="L">L</option>
              </select>
            </td>
            <th>{{ $t('cp.CTRL010G050.017') }}</th>
            <td>
              <select v-model="send.roundUseYn">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option value="Y">Y</option>
                <option value="N">N</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>{{ $t('cp.CTRL010G050.018') }}</th>
            <td>
              <input type="text" v-model="send.mntyTotTeu">
            </td>
            <th>{{ $t('cp.CTRL010G050.019') }}</th>
            <td>
              <input type="text" v-model="send.cmptCd">
            </td>
            <th>{{ $t('cp.CTRL010G050.020') }}</th>
            <td>
              <input type="text" v-model="send.cmptMntyTeu">
            </td>
          </tr>
          <tr>
            <th>{{ $t('cp.CTRL010G050.021') }}</th>
            <td colspan="3">
              <input type="text" v-model="send.cmptUseRsn">
            </td>
            <th>{{ $t('cp.CTRL010G050.022') }}</th>
            <td>
              <select v-model="send.rollOverPhbtYn">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option value="Y">Y</option>
                <option value="N">N</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>{{ $t('cp.CTRL010G050.023') }}</th>
            <td>
              <select v-model="send.webClosBkgPossYn">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option value="Y">Y</option>
                <option value="N">N</option>
              </select>
            </td>
            <th>{{ $t('cp.CTRL010G050.024') }}</th>
            <td>
              <select v-model="send.phbtRteCd">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in fairwayList" :key="idx" :value="item.rteCd">{{ item.rteCd }}</option>
              </select>
            </td>
            <th>{{ $t('cp.CTRL010G050.025') }}</th>
            <td>
              <select v-model="send.bidgYn">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option value="Y">Y</option>
                <option value="N">N</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>{{ $t('cp.CTRL010G050.026') }}</th>
            <td>
              <input type="text" v-model="send.fwdrActShprSalNm">
            </td>
            <th>{{ $t('cp.CTRL010G050.027') }}</th>
            <td>
              <div class="input_calendar mid">
                <e-date-picker v-model="send.strDt" style="width:120px" />
              </div>
            </td>
            <th>{{ $t('cp.CTRL010G050.028') }}</th>
            <td>
              <div class="input_calendar mid">
                <e-date-picker v-model="send.endDt" style="width:120px" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div><!-- content_box // -->
    <div class="flex_box mt10">
      <a class="button blue lg mr5 ml_auto" href="#" @click.prevent="save">{{ $t('cp.COMMON.004') }}</a>
      <a class="button blue lg" href="#" @click.prevent="deleteHW">{{ $t('cp.COMMON.010') }}</a>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import { GridView, LocalDataProvider } from 'realgrid'
import cpCommonInfo from '@/api/rest/cp/cpCommonInfo'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import handWriting from '@/api/rest/cp/handWriting'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'porCtrCd', dataType: 'text' },
  { fieldName: 'dlyCtrCd', dataType: 'text' },
  { fieldName: 'porPlcCd', dataType: 'text' },
  { fieldName: 'porPlcNm', dataType: 'text' },
  { fieldName: 'dlyPlcCd', dataType: 'text' },
  { fieldName: 'dlyPlcNm', dataType: 'text' },
  { fieldName: 'bkgShprCstNo', dataType: 'text' },
  { fieldName: 'bkgShprCstNm', dataType: 'text' },
  { fieldName: 'actShprCstNo', dataType: 'text' },
  { fieldName: 'actShprCstNm', dataType: 'text' },
  { fieldName: 'hcneCstNo', dataType: 'text' },
  { fieldName: 'hcneCstNm', dataType: 'text' },
  { fieldName: 'disActShprCstNo', dataType: 'text' },
  { fieldName: 'disActShprCstNm', dataType: 'text' },
  { fieldName: 'cstPicNo', dataType: 'text' },
  { fieldName: 'cstPicNm', dataType: 'text' },
  { fieldName: 'cmdtCd', dataType: 'text' },
  { fieldName: 'cmdtNm', dataType: 'text' },
  { fieldName: 'regYn', dataType: 'text' },
  { fieldName: 'chk', dataType: 'text' },
  { fieldName: 'cstReqGrd', dataType: 'text' },
  { fieldName: 'cntrSpplSnstvt', dataType: 'text' },
  { fieldName: 'roundUseYn', dataType: 'text' },
  { fieldName: 'mntyTotTeu', dataType: 'text' },
  { fieldName: 'rollOverPhbtYn', dataType: 'text' },
  { fieldName: 'webClosBkgPossYn', dataType: 'text' },
  { fieldName: 'phbtRteCd', dataType: 'text' },
  { fieldName: 'bidgYn', dataType: 'text' },
  { fieldName: 'fwdrActShprSalNm', dataType: 'text' },
  { fieldName: 'strDt', dataType: 'text' },
  { fieldName: 'endDt', dataType: 'text' },
  { fieldName: 'cmptCd', dataType: 'text' },
  { fieldName: 'cmptMntyTeu', dataType: 'text' },
  { fieldName: 'cmptUseRsn', dataType: 'text' },
  { fieldName: 'regYsameStrDt', dataType: 'text' }
]

export const columns = [
  { name: 'porPlcNm', fieldName: 'porPlcNm', type: 'text', header: { text: app.$t('cp.CTRL010G050.002') + app.$t('cp.CTRL010G050.005') }, editable: false, width: '70' },
  { name: 'dlyPlcNm', fieldName: 'dlyPlcNm', type: 'text', header: { text: app.$t('cp.CTRL010G050.003') + app.$t('cp.CTRL010G050.005') }, editable: false, width: '70', styleName: 'text_left' },
  { name: 'bkgShprCstNm', fieldName: 'bkgShprCstNm', type: 'text', header: { text: app.$t('cp.CTRL010G050.006') }, editable: false, width: '100', styleName: 'text_left' },
  { name: 'actShprCstNm', fieldName: 'actShprCstNm', type: 'text', header: { text: app.$t('cp.CTRL010G050.007') }, editable: false, width: '100', styleName: 'text_left' },
  { name: 'hcneCstNm', fieldName: 'hcneCstNm', type: 'text', header: { text: app.$t('cp.CTRL010G050.008') }, editable: false, width: '100', styleName: 'text_left' },
  { name: 'disActShprCstNm', fieldName: 'disActShprCstNm', type: 'text', header: { text: app.$t('cp.CTRL010G050.009') }, editable: false, width: '100', styleName: 'text_left' },
  { name: 'cstPicNm', fieldName: 'cstPicNm', type: 'text', header: { text: app.$t('cp.CTRL010G050.010') }, editable: false, width: '50', styleName: 'text_left' },
  { name: 'cmdtNm', fieldName: 'cmdtNm', type: 'text', header: { text: app.$t('cp.CTRL010G050.011') }, editable: false, width: '60', styleName: 'text_left' },
  { name: 'strDt', fieldName: 'strDt', type: 'text', header: { text: app.$t('cp.CTRL010G050.027') }, editable: false, width: '50' },
  { name: 'regYn', fieldName: 'regYn', type: 'text', header: { text: app.$t('cp.CTRL010G050.029') }, editable: false, width: '50' }
]

export default {
  name: 'HandWriting',
  components: {
    EBreadcrumbs,
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    EDatePicker: () => import('@/components/common/EDatePicker'),
    CstSearchListPop: () => import('@/pages/cp/popup/CstSearchListPop'),
    ProfileListPop: () => import('@/pages/cp/popup/ProfileListPop'),
    ItemListPop: () => import('@/pages/cp/popup/ItemListPop')
  },
  data: function () {
    return {
      customComponent: null,
      parentInfo: {
        position: '',
        ships: '',
        bkgShipper: '',
        profile: '',
        itemGrps: '',
        items: ''
      },
      columnPosition: '',
      countryList: [],
      harborList: [],
      harborList2: [],
      gridList: [],
      fairwayList: [],
      param: {
        porCtr: '',
        porPlc: '',
        dlyCtr: '',
        dlyPlc: '',
        itemGrp: '',
        item: '',
        itemLength: 0,
        bkgShipper: '',
        bkgShipLength: 0,
        actShipper: '',
        actShipLength: 0,
        hcneShipper: '',
        hcneShipLength: 0,
        cneeShipper: '',
        cneeShipLength: 0,
        profile: '',
        profileLength: 0,
        availDate: ''
      },
      send: {
        cstReqGrd: '',
        cntrSpplSnstvt: '',
        roundUseYn: '',
        mntyTotTeu: '0',
        cmptCd: '',
        rollOverPhbtYn: '',
        webClosBkgPossYn: '',
        phbtRteCd: '',
        bidgYn: '',
        fwdrActShprSalNm: '',
        strDt: '',
        endDt: '',
        cmptMntyTeu: '0',
        cmptUseRsn: ''
      }
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    'send.cmptMntyTeu': function () {
      const reg = /[^0-9]/g
      this.send.cmptMntyTeu = this.send.cmptMntyTeu.replace(reg, '').slice(0, 10)
    },
    'send.mntyTotTeu': function () {
      const reg = /[^0-9]/g
      this.send.mntyTotTeu = this.send.mntyTotTeu.replace(reg, '').slice(0, 10)
    }
  },
  created () {
    this.param.availDate = this.$ekmtcCommon.getToday().replaceAll('-', '')
    cpCommonInfo.cpRole().then(response => {
      let cpAdmin = response.data.cpAdmin
      let cpUser = response.data.cpUser
      if (cpAdmin === 'Y') {
        //접근 권한 있음.
      } else {
        //접근 권한 없으므로 main 으로...
        this.$router.push('/main')
        //if (cpUser === 'Y') {
        //  this.$router.push('/main')
        //}
      }
    }).catch(err => {
      console.log(err)
    })
  },
  mounted: function () {
    console.log('mounted')
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.setDataSource(provider)

    // 그리고 헤더 높이 조정
    gridView.header.heights = [30]
    gridView.displayOptions.rowHeight = 35
    gridView.displayOptions.fitStyle = 'even'
    gridView.footers.visible = false
    gridView.setStateBar({
      visible: false
    })
    gridView.setRowIndicator({
      visible: false
    })

    // realgrid 체크바 관련 옵션
    gridView.setCheckBar({
      visible: true,
      syncHeadCheck: true
    })
    gridView.displayOptions.selectionStyle = 'rows'

    // realgrid 정렬 관련 옵션
    gridView.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive', // 멀티 컬럼 정렬
      showSortOrder: true // 정렬 순서 표기
    })

    const $vm = this

    $vm.getCountryList() //나라
    $vm.getFairwayList()

    gridView.onCellClicked = function (grid, clickData) {
      if (clickData.cellType === 'data') {
        const data = provider.getJsonRow(clickData.dataRow)
        $vm.send.cstReqGrd = data.cstReqGrd
        $vm.send.cntrSpplSnstvt = data.cntrSpplSnstvt
        $vm.send.roundUseYn = data.roundUseYn
        $vm.send.mntyTotTeu = data.mntyTotTeu
        $vm.send.cmptCd = data.cmptCd
        $vm.send.cmptMntyTeu = data.cmptMntyTeu
        $vm.send.cmptUseRsn = data.cmptUseRsn
        $vm.send.rollOverPhbtYn = data.rollOverPhbtYn
        $vm.send.webClosBkgPossYn = data.webClosBkgPossYn
        $vm.send.phbtRteCd = data.phbtRteCd
        $vm.send.bidgYn = data.bidgYn
        $vm.send.fwdrActShprSalNm = data.fwdrActShprSalNm
        $vm.send.strDt = data.strDt
        $vm.send.endDt = data.endDt
      }
    }
  },
  methods: {
    getFairwayList: function () {
      cpCommonInfo.fairwayList().then(response => {
        this.fairwayList = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    getCountryList: function () {
      cpCommonInfo.countryList().then(response => {
        this.countryList = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    getHarborList: function (val) {
      cpCommonInfo.harborList(val).then(response => {
        this.harborList = response.data
        this.param.porPlc = ''
      }).catch(err => {
        console.log(err)
      })
    },
    getHarborList2: function (val) {
      cpCommonInfo.harborList(val).then(response => {
        this.harborList2 = response.data
        this.param.dlyPlc = ''
      }).catch(err => {
        console.log(err)
      })
    },
    openPopup (compNm, position) {
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose()
      }
      if (position === 'item') {
        this.parentInfo.itemGrps = this.param.itemGrp
        this.parentInfo.items = this.param.item
      }
      if (position === 'bkShip') {
        this.parentInfo.ships = this.param.bkgShipper
      }
      if (position === 'actShip') {
        this.parentInfo.ships = this.param.actShipper
      }
      if (position === 'handConsi') {
        this.parentInfo.ships = this.param.hcneShipper
      }
      if (position === 'consi') {
        this.parentInfo.ships = this.param.cneeShipper
      }
      if (position === 'profile') {
        this.parentInfo.bkgShipper = this.param.bkgShipper
        this.parentInfo.profile = this.param.profile
      }
      this.customComponent = compNm
      this.columnPosition = position
      this.$ekmtcCommon.layerOpen('.sample_popup_list')
    },
    closePopup (obj) {
      if (obj === undefined || obj === '' || obj === null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.sample_popup_list')
        return
      }
      if (this.columnPosition === 'item') {
        this.param.itemGrp = String(obj.itemGrps)
        this.param.item = String(obj.items)
        this.param.itemLength = obj.itemGrps.length + obj.items.length
      } else if (this.columnPosition === 'bkShip') {
        this.param.bkgShipper = String(obj)
        this.param.bkgShipLength = obj.length
        this.param.profile = ''
        this.param.profileLength = 0
      } else if (this.columnPosition === 'actShip') {
        this.param.actShipper = String(obj)
        this.param.actShipLength = obj.length
      } else if (this.columnPosition === 'handConsi') {
        this.param.hcneShipper = String(obj)
        this.param.hcneShipLength = obj.length
      } else if (this.columnPosition === 'consi') {
        this.param.cneeShipper = String(obj)
        this.param.cneeShipLength = obj.length
      } else if (this.columnPosition === 'profile') {
        this.param.profile = String(obj)
        this.param.profileLength = obj.length
      } else {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.sample_popup_list')
      }
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.sample_popup_list')
    },
    handWritingList: function () {
      // 최소 검색 조건 추가
      if (!Object.values(this.param).every(element => !element)) {
        handWriting.getHandWritingList(this.param).then(response => {
          this.gridList = response.data
          provider.setRows(this.gridList)
          gridView.clearCurrent()
        }).catch(err => {
          console.error(err)
        })
      } else {
        this.openAlert(this.$t('cp.CTRL010G050.030'))
      }
      // 헤더 체크박스의 체크 잔존 오류 수정
      gridView.checkAll(false)
    },
    openAlert: function (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('cp.COMMON.005'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    save: function () {
      const checkedRows = gridView.getCheckedRows()
      if (checkedRows.length === 0) {
        this.openAlert(this.$t('cp.CTRL010G050.031'))
        return
      }

      if (this.send.strDt !== '' && this.send.endDt !== '' && this.send.strDt > this.send.endDt) {
        this.openAlert(this.$t('cp.CTRL010G050.032'))
        return
      }

      this.$ekmtcCommon.confirmCallBack(this.$t('cp.CTRL010G050.033'), () => {
        //체크박스 데이터연결시 체크가 안되서 수동으로 그리드체크 로직 추가
        checkedRows.forEach((element, index) => {
          provider.setValue(element, 'chk', 'Y')
          provider.setValue(element, 'cstReqGrd', this.send.cstReqGrd)
          provider.setValue(element, 'cntrSpplSnstvt', this.send.cntrSpplSnstvt)
          provider.setValue(element, 'roundUseYn', this.send.roundUseYn)
          provider.setValue(element, 'mntyTotTeu', this.send.mntyTotTeu)
          provider.setValue(element, 'cmptCd', this.send.cmptCd)
          provider.setValue(element, 'rollOverPhbtYn', this.send.rollOverPhbtYn)
          provider.setValue(element, 'webClosBkgPossYn', this.send.webClosBkgPossYn)
          provider.setValue(element, 'phbtRteCd', this.send.phbtRteCd)
          provider.setValue(element, 'bidgYn', this.send.bidgYn)
          provider.setValue(element, 'fwdrActShprSalNm', this.send.fwdrActShprSalNm)
          let strDt = ''
          if (this.send.strDt === '') {
            strDt = this.$ekmtcCommon.getToday().replaceAll('-', '')
          } else {
            strDt = this.send.strDt.replaceAll('.', '')
          }
          if (provider.getValue(element, 'regYn') === 'Y' && (provider.getValue(element, 'strDt').replaceAll('.', '') === strDt)) {
            provider.setValue(element, 'regYsameStrDt', 'Y')
            provider.setValue(element, 'endDt', this.send.endDt)
          } else if (provider.getValue(element, 'regYn') === 'N' || provider.getValue(element, 'regYn') === 'Y') {
            provider.setValue(element, 'strDt', this.send.strDt)
            provider.setValue(element, 'endDt', this.send.endDt)
          }
          provider.setValue(element, 'cmptMntyTeu', this.send.cmptMntyTeu)
          provider.setValue(element, 'cmptUseRsn', this.send.cmptUseRsn)
        })

        // 체크한건만 던지기....
        let jsonData = []
        let cnt = 0
        provider.getJsonRows().forEach((ele, idx) => {
          if (ele.chk === 'Y') {
            jsonData[cnt] = ele
            cnt++
          }
          provider.setValue(idx, 'chk', '')
        })

        handWriting.saveHandWriting(jsonData).then(response => {
          this.init()
          this.openAlert(this.$t('cp.CTRL010G050.034'))
          this.handWritingList()
        }).catch(err => {
          console.log(err)
        })
      })
    },
    deleteHW: function () {
      const checkedRows = gridView.getCheckedRows()
      if (checkedRows.length === 0) {
        this.openAlert(this.$t('cp.CTRL010G050.035'))
        return
      }

      //체크박스 데이터연결시 체크가 안되서 수동으로 그리드체크 로직 추가
      checkedRows.forEach((element, index) => {
        provider.setValue(element, 'chk', 'Y')
      })

      this.$ekmtcCommon.confirmCallBack(this.$t('cp.CTRL010G050.036'), () => {
        handWriting.deleteHandWriting(provider.getJsonRows()).then(response => {
          this.init()
          this.openAlert(this.$t('cp.CTRL010G050.037'))
          this.handWritingList()
        }).catch(err => {
          console.error(err)
        })
      })
    },
    init: function () {
      this.send.cstReqGrd = ''
      this.send.cntrSpplSnstvt = ''
      this.send.roundUseYn = ''
      this.send.mntyTotTeu = '0'
      this.send.cmptCd = ''
      this.send.rollOverPhbtYn = ''
      this.send.webClosBkgPossYn = ''
      this.send.phbtRteCd = ''
      this.send.bidgYn = ''
      this.send.fwdrActShprSalNm = ''
      this.send.strDt = ''
      this.send.endDt = ''
      this.send.cmptMntyTeu = '0'
      this.send.cmptUseRsn = ''
    }
  }
}
</script>
