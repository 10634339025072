var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content full" },
    [
      _c("e-breadcrumbs"),
      _c(
        "win-layer-pop",
        { staticClass: "sample_popup_list" },
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.parentInfo },
                on: { close: _vm.closePopup },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("cp.CTRL010G020.001"))),
      ]),
      _c("div", { staticClass: "content_box mt10 clearfix" }, [
        _c("div", { staticClass: "col_6 float_left pr20" }, [
          _c("h2", { staticClass: "content_title" }, [
            _vm._v(_vm._s(_vm.$t("cp.CTRL010G020.002"))),
          ]),
          _c("div", {
            staticClass: "mt10",
            staticStyle: { width: "100%", height: "450px" },
            attrs: { id: "realgrid" },
          }),
        ]),
        _c("div", { staticClass: "col_4 float_right" }, [
          _c("h2", { staticClass: "content_title" }, [_vm._v(" ")]),
          _c("table", { staticClass: "tbl_row" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("cp.CTRL010G020.003")) + " "),
                  _c("span", [_vm._v("*")]),
                ]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.actionCd,
                        expression: "send.actionCd",
                      },
                    ],
                    ref: "actionCdInput",
                    attrs: {
                      type: "text",
                      id: "actionCd",
                      readonly: !_vm.send.exist,
                    },
                    domProps: { value: _vm.send.actionCd },
                    on: {
                      blur: _vm.actionCdVali,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "actionCd", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL010G020.004")))]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.actionNm,
                        expression: "send.actionNm",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.actionNm },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "actionNm", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL010G020.014")))]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.actionEnm,
                        expression: "send.actionEnm",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.actionEnm },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "actionEnm", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL010G020.005")))]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.shootPoint,
                        expression: "send.shootPoint",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.shootPoint },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "shootPoint", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL010G020.015")))]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.shootPointEn,
                        expression: "send.shootPointEn",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.shootPointEn },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "shootPointEn", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL010G020.006")))]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.shootResult,
                        expression: "send.shootResult",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.shootResult },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "shootResult", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL010G020.016")))]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.send.shootResultEn,
                        expression: "send.shootResultEn",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.send.shootResultEn },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.send, "shootResultEn", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL010G020.007")))]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.send.category,
                          expression: "send.category",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.send,
                            "category",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                      ]),
                      _vm._l(_vm.categoryList, function (item, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: item.detailCd } },
                          [_vm._v(_vm._s(item.detailNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL010G020.008")))]),
                _c("td", [
                  _c("div", { staticClass: "tbl_form" }, [
                    _c("span", { staticClass: "dv" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.send.apiCd,
                            expression: "send.apiCd",
                          },
                        ],
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: _vm.send.apiCd },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.send, "apiCd", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "dv wid20" }, [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon search",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openPopup("ApiListPop")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("cp.COMMON.001")))]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "flex_box mt10" }, [
        _c(
          "a",
          {
            staticClass: "button blue lg mr5 ml_auto",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.new_init.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("cp.COMMON.003")))]
        ),
        _c(
          "a",
          {
            staticClass: "button blue lg mr5",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.save.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("cp.COMMON.004")))]
        ),
        _c(
          "a",
          {
            staticClass: "button blue lg",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.deleteAction.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("cp.COMMON.010")))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "140" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }