<template>
  <div class="content full">
    <div class="schbox1">
      <table>
        <tr>
          <td width = "150px"><h1 style = "font-size:27px; font-weight:700">KMTC OBS</h1></td>
          <td width = "258px"><e-auto-complete-vessel2 @change="change1" :cd-nm="vslNm" id="vslNm" /></td>
          <td width = "100px"><a
          class="button"
          href="#/cp/vessel-location-present-condition"
          @click="integratedSearch(0)"
        >{{ $t('msg.UTIL010G010.007') }}</a><!-- 검색 --></td>
        <td width = "30px"></td>
        <td width = "150px"><h1 style = "font-size:27px; font-weight:700; margin-left: 15px;"> ROUTE CODE :</h1></td>
        <select class="wid200" style="height: 40px; font-size:18px; font-weight:400; text-align: center; margin-left: 15px;" v-model="regParams.rteCd" @change="integratedSearch(3)">
            <option value="A">{{ $t('msg.ONEX010T010.004') }}<!-- 선택 --></option>
            <option v-for="rteInfo in RteList" :key="rteInfo" :value="rteInfo">{{ rteInfo }}</option>
          </select>
        <td width = "400px"></td>
        <td width = "130px"><h1 style = "font-size:27px; font-weight:700"> 전체 선박수 : {{ markers.length}}</h1></td>
        </tr>
      </table>
    </div>
    <div class="ml23" style="display:block;" v-show="clickDetailSearch === 'Y'"></div>
    <div class="content_box mt10">
      <!-- <div :style="`height: ${mapHeight}px;`" class="map_wrap"> -->
      <div :style="`height: 630px;`" class="map_wrap">
        <l-map
          :zoom="zoom"
          :center="center"
          :options="mapOptions"
          worldCopyJump
          style="z-index: 0;"
        >
          <l-tile-layer
            :url="url"
            :attribution="attribution"
            :z-index="900"
          />
          <l-control>
            <img src="../../assets/images/schedule/kmtc_logo.gif" style="width: 150px;">
          </l-control>
          <l-control>
            <img src="img/hello2.png" style="width: 150px;">
          </l-control>
          <template v-for="(item, index) in markers">
            <v-rotated-marker
              :key="`marker-${index}`"
              :lat-lng="item.markerLatLng"
              :icon="item.vslCd.startsWith('J') ? (item.nvgSts=='0') || (item.nvgSts=='8') ? iconJ : iconJS : item.vslCd.startsWith('F') ? (item.nvgSts=='0') || (item.nvgSts=='8') ? iconF : iconFS : (item.nvgSts=='0') || (item.nvgSts=='8') ? iconK : iconKS"
              :rotation-angle="item.rotationAngle"
              :options= {opacity:item.opacity}
              @click="fnFocusMarker(item.markerLatLng, index,item.type), integratedSearch(9, index)"
            >
            </v-rotated-marker>
          </template>
          <template v-for="(item, index) in markers2">
            <v-rotated-marker
              :key="`marker2-${index}`"
              :lat-lng="item.markerLatLng"
              :icon=" iconP"
              :rotation-angle="0"

              @click="fnFocusMarker(item.markerLatLng, index,item.type)"
            >
            </v-rotated-marker>
          </template>
          <template v-for="(item, index) in markers3">
            <v-rotated-marker
              :key="`marker3-${index}`"
              :lat-lng="item.markerLatLng"
              :icon="index !== 0 ? iconDot:item.typ_spd >= 20 ? iconTR:iconTB"
              :rotation-angle="0"
            >
            <l-popup
            :key="'pupop-${index}'"
            >
            <b style="color:red">이름</b> : {{ item.typ_en_name }}<br />
            <b style="color:red">시간</b> : {{ item.typ_typtm }}<br />
            <b style="color:red">진행 방향 / 속도</b> : {{ item.typ_dir }} / {{ item.typ_spd }}m/s<br />
            <b style="color:red">정보</b> : {{ item.typ_loc }}<br />
            <b style="color:red">참고</b> : {{ item.typ_rem }}<br />
          </l-popup>
            </v-rotated-marker>
          </template>
          <l-polyline :lat-lngs="polylineN.latlngs" :color="polylineN.color" :dashArray="polylineN.dashArray" :opacity="polylineN.opacity" :weight="polylineN.weight"></l-polyline>
          <l-polyline :lat-lngs="polyline.latlngs" :color="polyline.color" :opacity="polyline.opacity" :weight="polyline.weight"></l-polyline>
          <l-polyline :lat-lngs="polyline_typhoon.typ1.latlngs" :color="polyline_typhoon.typ1.color" :dashArray="polyline_typhoon.typ1.dashArray" :opacity="polyline_typhoon.typ1.opacity" :weight="polyline_typhoon.typ1.weight"></l-polyline>
          <l-polyline :lat-lngs="polyline_typhoon.typ2.latlngs" :color="polyline_typhoon.typ2.color" :dashArray="polyline_typhoon.typ2.dashArray" :opacity="polyline_typhoon.typ2.opacity" :weight="polyline_typhoon.typ2.weight"></l-polyline>
        </l-map>
      </div>
      <table class="tbl_row mt10">
    <colgroup>
        <col width="130">
        <col>
        <col width="130">
        <col>
        <col width="15%">
    </colgroup>
    <tbody class="schbox1">
        <tr>
            <th>Operater</th>
            <td>
              <input type="radio" id="oper1" name="oper" v-model="regParams.vslType" value = 'A' @click="integratedSearch(1)">
              <label for="oper1" >ALL</label>
              <input type="radio" id="oper2" name="oper" v-model="regParams.vslType" value = 'V' @click="integratedSearch(1)">
              <label for="oper2" >VSL_ALL</label>
              <input type="radio" id="oper3" name="oper" v-model="regParams.vslType" value = 'K' @click="integratedSearch(1)">
              <label for="oper3">KMTC</label>
              <input type="radio" id="oper4" name="oper" v-model="regParams.vslType" value = 'J' @click="integratedSearch(1)">
              <label for="oper4">Joint</label>
              <input type="radio" id="oper5" name="oper" v-model="regParams.vslType" value = 'P' @click="integratedSearch(1)">
              <label for="oper5" >PORT</label>
            </td>
            <th>Navigation</th>
            <td>
              <input type="radio" id="nav1" name="nav" v-model="regParams.nvgType" value = 'A' @click="integratedSearch(2)">
              <label for="nav1" >ALL</label>
              <input type="radio" id="nav2" name="nav" v-model="regParams.nvgType" value = '0,8' @click="integratedSearch(2)">
              <label for="nav2">Sailing</label>
              <input type="radio" id="nav3" name="nav" v-model="regParams.nvgType" value = '1,5' @click="integratedSearch(2)">
              <label for="nav3">Arrived</label>
              <input type="radio" id="nav4" name="nav" v-model="regParams.nvgType" value = 'I' @click="integratedSearch(2)">
              <label for="nav4">Anomaly</label>
            </td>
            <td width = "30px"><a
              class="button"
              href="#/cp/vessel-location-present-condition"
              @click="Reset()"
            >Reset</a><!-- 검색 --></td>
            <!-- <td width = "500px"><a
              class="button"
              href="#/cp/vessel-location-present-condition"
              @click.prevent="makeExcelFile4()"
            >Excel</a></td> -->
        </tr>
    </tbody>
</table>
<div class="srgraph_container mt10">
  <div v-if="checkBool.table">
  <table>
    <tr class="srgraph_centertext vsl_port_under_table_frame">
      <div class = "vsl_port_under_table">구분</div> <div class = "vsl_port_under_table">정보</div>
    </tr>
    <div v-if="this.regParams.Type">
      <tr v-for="(item, key, idx) in focusVessel"
        :key="idx">
        <td class = "focus1_table">{{ item.name }}</td>
        <td class = "focus2_table">{{ item.vslCd_voyNo }}{{ item.vslNm }}{{ nvg[item.nvgSts] }}{{ item.aisEta }}{{ item.aisDest }}{{ item.spd }}{{ item.rteCd }}</td>
      </tr>
    </div>
    <div v-else>
      <tr v-for="(item, key, idx) in focusPort"
        :key="idx">
        <td class = "focus1_table">{{ item.name }}</td>
        <td class = "focus2_table">{{ item.portCd }}{{ item.portNm }}{{ item.ctrCd }}{{ item.portType }}</td>
      </tr>
  </div>
  </table>
  </div>
  <div style = "width: 70%; align-items: center;" v-if="checkBool.table">
    <digital-transform class = "ETA_ETD_under_table_tbody" v-if="this.checkTab.item1.check" :parent-info="this.portMarker"/>
    <customer-service class = "ETA_ETD_under_table_tbody" v-if="this.checkTab.item2.check" :parent-info="this.portMarker"/>
    <it-planning class = "ETA_ETD_under_table_tbody" v-if="this.checkTab.item3.check" :parent-info="this.portMarker"/>
    <data-analysis class = "ETA_ETD_under_table_tbody" v-if="this.checkTab.item4.check" :parent-info="this.portMarker"/>
    <liner-operation class = "ETA_ETD_under_table_tbody" v-if="this.checkTab.item5.check" :parent-info="this.portMarker"/>
  </div>
  <table v-if="checkBool.table">
    <tr v-for="(item, key, idx) in checkTab"
        :key="idx" :id="'srgraph_' + key"><a class="button" href="#/cp/vessel-location-present-condition" @click="tabClick(idx)" >{{ item.name }}</a></tr>
  </table>
  <div
      v-for="(item, key, idx) in itemDataE"
      :key="idx"
      class="srgraph_content"
      v-show = "checkBool.graph"
    >
    <h2 class="srgraph_unittitle srgraph_centertext">{{item.name}}</h2>
    <section class="bar-graph bar-graph-vertical bar-graph-two">
      <div :style="solVariable" class="bar-one bar-container" href="#" @click="integratedSearch(Top5[0][0])">
      {{Top5[0][1]}}
        <div style class="bar">Click</div>
        <span class="year">{{Top5[0][0]}}</span>
      </div>
      <div :style="solVariable" class="bar-two bar-container" href="#" @click="integratedSearch(Top5[1][0])">
      {{Top5[1][1]}}
        <div style class="bar">Click</div>
        <span class="year">{{Top5[1][0]}}</span>
      </div>
      <div :style="solVariable" class="bar-three bar-container" href="#" @click="integratedSearch(Top5[2][0])">
      {{Top5[2][1]}}
        <div style class="bar">Click</div>
        <span class="year">{{Top5[2][0]}}</span>
      </div>
      <div :style="solVariable" class="bar-four bar-container" href="#" @click="integratedSearch(Top5[3][0])">
        <div style class="bar">Click</div>
        {{Top5[3][1]}}
        <span class="year">{{Top5[3][0]}}</span>
      </div>
      <div :style="solVariable" class="bar-five bar-container" href="#" @click="integratedSearch(Top5[4][0])">
        <div style class="bar">Click</div>
        <span>{{Top5[4][1]}}</span>
        <span class="year">{{Top5[4][0]}}</span>
      </div>
    </section>
  </div>
</div>
</div>
</div>
</template>

<script>

import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo2'
import { latLng, icon } from 'leaflet'
import { LMap, LTileLayer, LMarker, LControl, LTooltip, LPopup, LPolyline } from 'vue2-leaflet'
import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker'
import 'leaflet/dist/leaflet.css'

export default {
  name: 'LocationInfo',
  computed: {
    solVariable () {
      return {
        '--barHeight1': this.Top5[0][1],
        '--barHeight2': this.Top5[1][1],
        '--barHeight3': this.Top5[2][1],
        '--barHeight4': this.Top5[3][1],
        '--barHeight5': this.Top5[4][1]
      }
    }
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    LTooltip,
    LPopup,
    LPolyline,
    'v-rotated-marker': Vue2LeafletRotatedMarker,
    EAutoCompleteVessel2: () => import('@/components/common/EAutoCompleteVessel2'),
    DigitalTransform: () => import('@/pages/trans/DigitalTransform'),
    CustomerService: () => import('@/pages/trans/CustomerService'),
    ItPlanning: () => import('@/pages/trans/ItPlanning'),
    DataAnalysis: () => import('@/pages/trans/DataAnalysis'),
    LinerOperation: () => import('@/pages/trans/LinerOperation')
  },
  data: function () {
    return {
      RteList: [],
      UnoList: [],
      selRte: '',
      itemDataE: {
        item1: { valA: 10, valB: 40, rate: 25, name: 'POD' },
        item2: { valA: 10, valB: 40, rate: 25, name: 'What you want to see' }
        // item3: { valA: 10, valB: 40, rate: 25, name: 'HAN' },
        // item4: { valA: 10, valB: 40, rate: 25, name: 'SOL' }
      },
      checkBool: {
        graph: true,
        table: false
      },
      portMarkerA: [{
          tableVslCdA: '', tableVoyNoA: '', tableTrmlCdA: '', tableSchEta: ''
      }],
      portMarkerB: [{
          tableVslCdB: '', tableVoyNoB: '', tableTrmlCdB: '', tableSchEtd: ''
      }],
      portMarkerC: [{
          tableVslCdC: '', tableVoyNoC: '', tableAisEta: ''
      }],
      portMarkerD: [{
          tableVslCdD: '', tableVoyNoD: ''
      }],
      portMarker: [{
          tableVslCdA: '', tableVoyNoA: '', tableTrmlCdA: '', tableSchEta: '', tableVslCdB: '', tableVoyNoB: '', tableTrmlCdB: '', tableSchEtd: '', tableVslCdC: '', tableVoyNoC: '', tableAisEta: '', tableVslCdD: '', tableVoyNoD: ''
      }],
      checkTab: {
        item1: { check: true, name: 'DT' },
        item2: { check: false, name: 'CS' },
        item3: { check: false, name: 'IP' },
        item4: { check: false, name: 'DA' },
        item5: { check: false, name: 'LO' }
      },
      clickDetailSearch: 'N',
      keywordClaerBtn: false,
      // 통합검색 결과 데이터
      integratedSearchResult: {
        //  메뉴 리스트(메뉴 검색 결과)
        menuList: []
      },
      focusPort: [
        { name: '포트 코드', portCd: '' },
        { name: '포트 이름', portNm: '' },
        { name: '국가', ctrCd: '' },
        { name: '포트 타입', portType: 'SeaPort' }
      ],
      focusVessel: [
        { name: '선명/항차', vslCd_voyNo: '' },
        { name: '선박 이름', vslNm: '' },
        { name: '선박 상태', nvgSts: '' },
        { name: 'AIS ETA', aisEta: '' },
        { name: 'AIS DEST', aisDest: '' },
        { name: 'SPEED', spd: '' },
        { name: '서비스 루트', rteCd: '' }
      ],
      vslCd: undefined,

      vslNm: undefined,

      mapHeight: 500,

      // 지도 확대정도
      zoom: 5,
      // 지도 중앙 위/경도(default - 서울)
      center: latLng(37.564214, 127.001699),
      // 지도 호출 url
      url: 'https://api.maptiler.com/maps/openstreetmap/256/{z}/{x}/{y}.jpg?key=DiDJ0YR81yQKSEioZPoD',
      // 우측 하단 표시
      attribution: '<a href="http://www.kmtc.co.kr/" target="_blank">&copy; KMTC</a> <a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
      // 지도 옵션
      mapOptions: {
        zoomSnap: 0.5,
        minZoom: 2
      },
      // 아이콘 옵션
      iconP: icon({
        iconUrl: '/img/port3.png',
        iconSize: [12, 12]
      }),
      iconTB: icon({
        iconUrl: '/img/typhoon_blue.gif',
        iconSize: [30, 30]
      }),
      iconTR: icon({
        iconUrl: '/img/typhoon_red.gif',
        iconSize: [30, 30]
      }),
      iconDot: icon({
        iconUrl: '/img/iconDot.png',
        iconSize: [10, 10]
      }),
      iconK: icon({
        iconUrl: '/img/blue_ship.png',
        iconSize: [20, 20]
      }),
      iconKS: icon({
        iconUrl: '/img/blue_ankle.png',
        iconSize: [20, 20]
      }),
      iconJ: icon({
        iconUrl: '/img/orangered_ship.png',
        iconSize: [20, 20]
      }),
      iconJS: icon({
        iconUrl: '/img/orangered_ankle.png',
        iconSize: [20, 20]
      }),
      iconF: icon({
        iconUrl: '/img/yellowgreen_ship.png',
        iconSize: [20, 20]
      }),
      iconFS: icon({
        iconUrl: '/img/yellowgreen_ankle.png',
        iconSize: [20, 20]
      }),
      markers: [
        {
          opacity: 1.0,
          type: 'V',
          markerLatLng: latLng(37.564214, 127.001699),
          rotationAngle: 0,
          vslNm: 'N/A',
          aisRecvDtm: 'N/A',
          vslCd: 'N/A',
          spd: 'N/A',
          voyNo: 'N/A',
          rteCd: 'N/A'
        }
      ],
      markers2: [
        {
          type: 'P',
          markerLatLng: latLng(37.564214, 127.001699),
          ctrCd: 'N/A',
          plcCd: 'N/A',
          portNm: 'N/A'
        }
      ],
      markers3: [
        {
          type: 'T',
          markerLatLng: latLng(-3000, -3000),
          typ_power: 'N/A',
          typ_en_name: 'N/A',
          typ_loc: 'N/A',
          typ_spd: 'N/A',
          typ_rem: 'N/A',
          typ_tmfc: 'N/A',
          typ_tmseq: 'N/A',
          tyP_dir: 'N/A',
          typ_tm: 'N/A'
        }
      ],
      itemOrder: [
        'item1',
        'item2',
        'item3',
        'item4'
      ],
      nvg: {
        0: 'Under way using engine',
        1: 'At anchor',
        2: 'Not under command',
        3: 'Restricted manoeuverability',
        4: 'Constrained by her draught',
        5: 'Moored',
        6: 'Aground',
        7: 'Engaged in Fishing',
        8: 'Under way sailing',
        9: 'Reserved for future amendment of Navigational Status for HSC',
        10: 'Reserved for future amendment of Navigational Status for WIG',
        11: 'Reserved for future use',
        12: 'Reserved for future use',
        13: 'Reserved for future use',
        14: 'AIS-SART is active',
        15: 'Not defined (default)'
        },
      regParams: {
        nvgType: 'A',
        vslType: 'A',
        Type: true,
        rteCd: 'A'
      },
      Top5: [],
      polyline_typhoon: {
        typ1: { latlngs: [], color: 'indigo', dashArray: '1, 5', opacity: 0.6, weight: 3, max: [] },
        typ2: { latlngs: [], color: 'indigo', dashArray: '1, 5', opacity: 0.6, weight: 3, max: [] }
      },
      polyline: {
        latlngs: [],
        color: 'indigo',
        opacity: 0.6,
        weight: 3
      },
      polylineN: {
        latlngs: [],
        color: 'gray',
        dashArray: '1, 5',
        opacity: 1,
        weight: 2
      },
      minIndex: 0
    }
  },
  created () {
    this.mapHeight = $('body').height() - 200
    if (this.mapHeight < 500) {
      this.mapHeight = 500
    }
    this.getLocationToMap(this.regParams, 0)
    this.getWeatherTyphoon()
    },
  methods: {
    onMapClick (e) {
      alert('You clicked the map at ' + e.latlng.toString())
    },
    checkSelectRte () {
      if (this.selRte !== '') {
        if (this.selPo === 'POL') {
          this.gridList = this.list.filter(vo => vo.porCtrCd === this.selRte)
        } else if (this.selPo === 'POD') {
          this.gridList = this.list.filter(vo => vo.dlyCtrCd === this.selRte)
        }
        this.totalCount = this.gridList.length
        provider.setRows(this.gridList)
      } else {
        this.gridList = this.list
        this.totalCount = this.gridList.length
        provider.setRows(this.gridList)
      }
    },
    tabClick (idx) {
      this.checkTab.item1.check = false
      this.checkTab.item2.check = false
      this.checkTab.item3.check = false
      this.checkTab.item4.check = false
      this.checkTab.item5.check = false
      if (idx === 0) {
        this.checkTab.item1.check = true
      } else if (idx === 1) {
        this.checkTab.item2.check = true
      } else if (idx === 2) {
        this.checkTab.item3.check = true
      } else if (idx === 3) {
        this.checkTab.item4.check = true
      } else if (idx === 4) {
        this.checkTab.item5.check = true
      }
    },
    makeExcelFile4 () {
      if (this.checkBool.table === true) {
        let tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">'
        tab_text += '<head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">'
        tab_text += '<xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>'
        tab_text += '<x:Name>Test Sheet</x:Name>'
        tab_text += '<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>'
        tab_text += '</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>'
        tab_text += '<table>'
        const temp = document.getElementById('table').innerHTML
        tab_text += temp
        tab_text += '</table></body></html>'
        const fileName = 'exampleTable.xls'
        const a_tag = document.createElement('a')
        const blob = new Blob([tab_text], { type: 'application/vnd.ms-excel;charset=utf-8;' })
        a_tag.href = window.URL.createObjectURL(blob)
        a_tag.download = fileName
        a_tag.click()
        this.openAlert('엑셀 파일이 다운로드 완료!!')
      } else {
        this.openAlert('배를 클릭하세요.')
      }
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: '확인',
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    change1 (vo) {
      this.vslNm = vo.vslNm
      this.vslCd = vo.vslCd
    },
    async Reset () {
      this.vslCd = undefined
      this.vslNm = undefined
      this.regParams.nvgType = 'A'
      this.regParams.vslType = 'A'
      this.regParams.rteCd = 'A'
      this.checkBool.table = false
      this.checkBool.graph = true
      this.markers = []
      this.polyline.latlngs = []
      this.polylineN.latlngs = []
      await this.integratedSearch(2)
    },
    async integratedSearch (searchParam) {
      if (searchParam !== 0) {
        this.vslCd = undefined
        this.vslNm = undefined
      }
      if (searchParam === 9) {
        this.vslCd = this.focusVessel[0].vslCd_voyNo.split('/')[0]
      }
      if (this.vslCd === undefined || this.vslCd === '' || (this.vslCd !== '' && this.vslCd.length < 2)) {
        await this.getLocationToMap(this.regParams, searchParam)
        this.center = latLng(37.564214, 127.001699)
        return
      }
      this.getLocationToMapFiltering(this.vslCd, searchParam)
    },
    async getMarkerTop5 (markers) {
      const tmp = []
      const result = {}
      for (const element of markers) {
        tmp.push(element.aisDest)
      }
      for (const element of new Set(tmp)) {
        result[element] = tmp.filter(e => element === e).length
      }
      var items = Object.keys(result).map(function (key) {
            return [key, result[key]]
          })
      items.sort(function (first, second) {
            return second[1] - first[1]
          })
      if (items.length < 5) {
        for (let i = items.length; i < 5; i++) {
          items[i] = 0
        }
        this.Top5 = items.slice(0, items.length)
      } else {
        this.Top5 = items.slice(0, 5)
      }
    },
    async getWeatherTyphoon () {
    vslScheduleInfo.getAllWeatherTyphoonInfo()
    .then(response => {
      if (response.status === 200) {
        const resData = response.data
        const allWeatherTyphoonInfo = resData.allWeatherTyphoonInfo
        if (allWeatherTyphoonInfo !== undefined && allWeatherTyphoonInfo.length > 0) {
          this.markers3 = []
          var temp = allWeatherTyphoonInfo[0].typen
          allWeatherTyphoonInfo.forEach(weatherTyphoonInfo => {
            const lttd = weatherTyphoonInfo.typlat
            const lngtd = weatherTyphoonInfo.typlon
            const power_15 = weatherTyphoonInfo.typ15
            const spd = weatherTyphoonInfo.typsp
            const max = weatherTyphoonInfo.typen + weatherTyphoonInfo.typ_tm

            this.markers3.push({
              markerLatLng: latLng(lttd, lngtd),
              typ_power: this.$ekmtcCommon.isNotEmpty(power_15) ? power_15 : 'N/A',
              typ_en_name: weatherTyphoonInfo.typen,
              typ_loc: weatherTyphoonInfo.typloc,
              typ_spd: this.$ekmtcCommon.isNotEmpty(spd) ? spd : 'N/A',
              typ_rem: weatherTyphoonInfo.rem.slice(0, -7),
              typ_tmfc: weatherTyphoonInfo.tmfc,
              typ_tmseq: weatherTyphoonInfo.tmseq,
              typ_dir: weatherTyphoonInfo.typdir,
              typ_typtm: weatherTyphoonInfo.typtm
            })
            temp = weatherTyphoonInfo.typen
          })
          this.getTyphoonPolyline(this.markers3)
        }
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    async getTyphoonPolyline () {
      var typhoon = []
      for (const element of this.markers3) {
        typhoon.push([element.typ_en_name, element.markerLatLng, element.typ_tmseq])
      }
      typhoon.sort(function (first, second) {
            return second[8] - first[8]
          })
      const tmp = typhoon[0][0]
      for (let i = 0; i < typhoon.length; i++) {
        if (tmp === typhoon[i][0]) {
          this.polyline_typhoon.typ1.latlngs.push([typhoon[i][1].lat, typhoon[i][1].lng])
          this.polyline_typhoon.typ1.max.push(typhoon[i][2])
        } else {
          this.polyline_typhoon.typ2.latlngs.push([typhoon[i][1].lat, typhoon[i][1].lng])
          this.polyline_typhoon.typ2.max.push(typhoon[i][2])
        }
      }
      const Max1 = Math.max.apply(null, this.polyline_typhoon.typ1.max)
      const Max2 = Math.max.apply(null, this.polyline_typhoon.typ2.max)
    },
    async getLocationToMap (regParams, searchParam) {
      // 선박 위치 API 호출
      if (regParams.vslType !== 'P') {
        this.markers2 = []
        vslScheduleInfo.getAllVslLocationInfo()
        .then(response => {
          if (response.status === 200) {
            const resData = response.data
            const allVesselLocationInfo = resData.allVesselLocationInfo
            if (allVesselLocationInfo !== undefined && allVesselLocationInfo.length > 0) {
              this.markers = []
              this.RteList = []
              this.UnoList = []

              allVesselLocationInfo.forEach(vesselLocationInfo => {
                const lttd = vesselLocationInfo.lttd
                const lngtd = vesselLocationInfo.lngtd
                const vslCd = vesselLocationInfo.vslCd
                const nvgSts = vesselLocationInfo.nvgSts
                const rteCd = vesselLocationInfo.rteCd
                if (rteCd !== undefined && rteCd.length > 0 && !this.RteList.includes(rteCd)) {
                  this.RteList.push(rteCd)
                }

                if (!'0,1,2,3'.includes(searchParam) && vesselLocationInfo.aisDest !== searchParam) {
                  return
                }

                if (vslCd.substring(0, 1) === 'J') {
                  if (regParams.vslType === 'K') {
                    return
                  }
                  if (regParams.nvgType !== 'A') {
                    if (regParams.nvgType !== 'I' && !regParams.nvgType.includes(nvgSts)) {
                      return
                    } else if (regParams.nvgType === 'I' && '0,1,5,8'.includes(nvgSts)) {
                      return
                    }
                  }
                  if (regParams.rteCd !== 'A') {
                    if (!regParams.rteCd.includes(rteCd)) {
                      return
                    }
                  }
                } else {
                  if (regParams.vslType === 'J') {
                    return
                  }
                  if (regParams.nvgType !== 'A') {
                    if (!regParams.nvgType.includes(nvgSts) && regParams.nvgType !== 'I') {
                      return
                    } else if (regParams.nvgType === 'I' && '0,1,5,8'.includes(nvgSts)) {
                      return
                    }
                  }
                  if (regParams.rteCd !== 'A') {
                    if (!regParams.rteCd.includes(rteCd)) {
                      return
                    }
                  }
                }

                if (this.$ekmtcCommon.isNotEmpty(lttd) && this.$ekmtcCommon.isNotEmpty(lngtd)) {
                  let aisEta = 'N/A'
                  let aisRecvDtm = 'N/A'
                  if (this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.aisEta) && vesselLocationInfo.aisEta.length >= 8) {
                    aisEta = `${vesselLocationInfo.aisEta.substring(0, 2)}/${vesselLocationInfo.aisEta.substring(2, 4)} ${vesselLocationInfo.aisEta.substring(4, 6)}:${vesselLocationInfo.aisEta.substring(6, 8)}`
                  }
                  if (this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.aisRecvDtm) && vesselLocationInfo.aisRecvDtm.length >= 14) {
                    aisRecvDtm = `${vesselLocationInfo.aisRecvDtm.substring(0, 4)}/${vesselLocationInfo.aisRecvDtm.substring(4, 6)}/${vesselLocationInfo.aisRecvDtm.substring(6, 8)} ${vesselLocationInfo.aisRecvDtm.substring(8, 10)}:${vesselLocationInfo.aisRecvDtm.substring(10, 12)}:${vesselLocationInfo.aisRecvDtm.substring(12, 14)}`
                  }
                  this.markers.push({
                    type: 'V',
                    markerLatLng: latLng(lttd, lngtd),
                    rotationAngle: this.$ekmtcCommon.isEmpty(vesselLocationInfo.truHdg) || Number(vesselLocationInfo.truHdg) === 511 ? 0 : Number(vesselLocationInfo.truHdg),
                    vslNm: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.vslNm) ? vesselLocationInfo.vslNm : 'N/A',
                    spdOvgGrnd: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.spdOvgGrnd) ? vesselLocationInfo.spdOvgGrnd : 'N/A',
                    aisEta: aisEta,
                    aisDest: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.aisDest) ? vesselLocationInfo.aisDest : 'N/A',
                    aisRecvDtm: aisRecvDtm,
                    vslCd: vslCd,
                    nvgSts: nvgSts,
                    voyNo: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.voyNo) ? vesselLocationInfo.voyNo : 'N/A',
                    rteCd: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.rteCd) ? vesselLocationInfo.rteCd : 'N/A',
                    oprUno: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.oprUno) ? vesselLocationInfo.oprUno : 'N/A'
                    // oprUno: oprVslCd === vslCd ? oprUno : 'N/A'
                  })
                }
              })
            }
            if ('0,1,2'.includes(searchParam)) {
              this.getMarkerTop5(this.markers)
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
      }
      if (regParams.vslType === 'A' || regParams.vslType === 'P') {
        if (regParams.vslType === 'P') {
          this.markers = []
        }
        await vslScheduleInfo.getAllPortLocationInfo()
        .then(response => {
          if (response.status === 200) {
            const resData = response.data
            const allPortLocationInfo = resData.allPortLocationInfo
            if (allPortLocationInfo !== undefined && allPortLocationInfo.length > 0) {
              this.markers2 = []

              allPortLocationInfo.forEach(portLocationInfo => {
                const lttd = portLocationInfo.lttd
                const lngtd = portLocationInfo.lngtd
                const ctrCd = portLocationInfo.ctrCd
                const plcCd = portLocationInfo.plcCd
                const portNm = portLocationInfo.portNm

                this.markers2.push({
                    type: 'P',
                    markerLatLng: latLng(lttd, lngtd),
                    ctrCd: ctrCd,
                    plcCd: plcCd,
                    portNm: portNm
                    })
                  })
                  }
                }
              })
        .catch(error => {
          console.log(error)
        })
      }
    },
    getLocationToMapFiltering (vslCd, searchParam) {
      this.markers = []
      const data = {
        catCd: 'V',
          // 선박위치 관련 데이터
          vslCd: vslCd,
          voyNo: '',
          vslNm: ''
      }
      vslScheduleInfo.getVslLocationInfo(data)
          .then(response => {
            if (response.status === 200) {
              const resData = response.data
              const lttd = resData.lttd
              const lngtd = resData.lngtd
              const vslCd = resData.vslCd
              const nvgSts = resData.nvgSts
              this.center = latLng(lttd, lngtd)

              if (this.$ekmtcCommon.isNotEmpty(lttd) && this.$ekmtcCommon.isNotEmpty(lngtd)) {
                  let aisEta = 'N/A'
                  let aisRecvDtm = 'N/A'
                  if (this.$ekmtcCommon.isNotEmpty(resData.aisEta) && resData.aisEta.length >= 8) {
                    aisEta = `${resData.aisEta.substring(0, 2)}/${resData.aisEta.substring(2, 4)} ${resData.aisEta.substring(4, 6)}:${resData.aisEta.substring(6, 8)}`
                  }
                  if (this.$ekmtcCommon.isNotEmpty(resData.aisRecvDtm) && resData.aisRecvDtm.length >= 14) {
                    aisRecvDtm = `${resData.aisRecvDtm.substring(0, 4)}/${resData.aisRecvDtm.substring(4, 6)}/${resData.aisRecvDtm.substring(6, 8)} ${resData.aisRecvDtm.substring(8, 10)}:${resData.aisRecvDtm.substring(10, 12)}:${resData.aisRecvDtm.substring(12, 14)}`
                  }

                  this.markers.push({
                    markerLatLng: latLng(lttd, lngtd),
                    rotationAngle: this.$ekmtcCommon.isEmpty(resData.truHdg) || Number(resData.truHdg) === 511 ? 0 : Number(resData.truHdg),
                    vslNm: this.$ekmtcCommon.isNotEmpty(resData.vslNm) ? resData.vslNm : 'N/A',
                    spdOvgGrnd: this.$ekmtcCommon.isNotEmpty(resData.spdOvgGrnd) ? resData.spdOvgGrnd : 'N/A',
                    aisEta: aisEta,
                    aisDest: this.$ekmtcCommon.isNotEmpty(resData.aisDest) ? resData.aisDest : 'N/A',
                    aisRecvDtm: aisRecvDtm,
                    vslCd: vslCd,
                    nvgSts: nvgSts,
                    voyNo: this.$ekmtcCommon.isNotEmpty(resData.voyNo) ? resData.voyNo : 'N/A',
                    rteCd: this.$ekmtcCommon.isNotEmpty(resData.rteCd) ? resData.rteCd : 'N/A'
                    })
              }
              if (searchParam === 9) {
                vslScheduleInfo.getAllVslLocationInfo(searchParam)
                .then(response => {
                  if (response.status === 200) {
                    const resData = response.data
                    const allVesselLocationInfo = resData.allVesselLocationInfo
                    if (searchParam !== 9) {
                      this.markers = []
                    }

                    allVesselLocationInfo.forEach((vesselLocationInfo, index) => {
                      const lttd = vesselLocationInfo.lttd
                      const lngtd = vesselLocationInfo.lngtd
                      const vslCd = vesselLocationInfo.vslCd
                      const nvgSts = vesselLocationInfo.nvgSts

                      if (vslCd.substring(0, 1) === 'J') {
                        if (this.regParams.vslType === 'K') {
                          return
                        }
                        if (this.regParams.nvgType !== 'A') {
                          if (this.regParams.nvgType !== 'I' && !this.regParams.nvgType.includes(nvgSts)) {
                            return
                          } else if (this.regParams.nvgType === 'I' && '0,1,5,8'.includes(nvgSts)) {
                            return
                          }
                        }
                      } else {
                        if (this.regParams.vslType === 'J') {
                          return
                        }
                        if (this.regParams.nvgType !== 'A') {
                          if (!this.regParams.nvgType.includes(nvgSts) && this.regParams.nvgType !== 'I') {
                            return
                          } else if (this.regParams.nvgType === 'I' && '0,1,5,8'.includes(nvgSts)) {
                            return
                          }
                        }
                      }

                      if (this.$ekmtcCommon.isNotEmpty(lttd) && this.$ekmtcCommon.isNotEmpty(lngtd)) {
                        let aisEta = 'N/A'
                        let aisRecvDtm = 'N/A'
                        if (this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.aisEta) && vesselLocationInfo.aisEta.length >= 8) {
                          aisEta = `${vesselLocationInfo.aisEta.substring(0, 2)}/${vesselLocationInfo.aisEta.substring(2, 4)} ${vesselLocationInfo.aisEta.substring(4, 6)}:${vesselLocationInfo.aisEta.substring(6, 8)}`
                        }
                        if (this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.aisRecvDtm) && vesselLocationInfo.aisRecvDtm.length >= 14) {
                          aisRecvDtm = `${vesselLocationInfo.aisRecvDtm.substring(0, 4)}/${vesselLocationInfo.aisRecvDtm.substring(4, 6)}/${vesselLocationInfo.aisRecvDtm.substring(6, 8)} ${vesselLocationInfo.aisRecvDtm.substring(8, 10)}:${vesselLocationInfo.aisRecvDtm.substring(10, 12)}:${vesselLocationInfo.aisRecvDtm.substring(12, 14)}`
                        }

                        this.markers.push({
                          opacity: 0.3,
                          type: 'V',
                          markerLatLng: latLng(lttd, lngtd),
                          rotationAngle: this.$ekmtcCommon.isEmpty(vesselLocationInfo.truHdg) || Number(vesselLocationInfo.truHdg) === 511 ? 0 : Number(vesselLocationInfo.truHdg),
                          vslNm: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.vslNm) ? vesselLocationInfo.vslNm : 'N/A',
                          spdOvgGrnd: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.spdOvgGrnd) ? vesselLocationInfo.spdOvgGrnd : 'N/A',
                          aisEta: aisEta,
                          aisDest: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.aisDest) ? vesselLocationInfo.aisDest : 'N/A',
                          aisRecvDtm: aisRecvDtm,
                          vslCd: vslCd,
                          nvgSts: nvgSts,
                          voyNo: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.voyNo) ? vesselLocationInfo.voyNo : 'N/A',
                          rteCd: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.rteCd) ? vesselLocationInfo.rteCd : 'N/A'
                          })
                      }
                    })
                }
                })
                .catch(error => {
                  console.log(error)
                })
              }
            }
          })
          .catch(error => {
          console.log(error)
        })
      },
      async fnFocusMarker (markerLatLng, index, type) {
        if (type === 'P') {
          const data = {
            portCd: this.markers2[index].plcCd
          }
          this.regParams.Type = false
          this.focusPort[0].portCd = this.markers2[index].plcCd
          this.focusPort[1].portNm = this.markers2[index].portNm
          this.focusPort[2].ctrCd = this.markers2[index].ctrCd
          await vslScheduleInfo.getPortDetailETA(data)
            .then(response => {
              if (response.status === 200) {
                const resData = response.data
                const portDetailETAInfo = resData.portDetailETAInfo
                this.portMarkerA = []
                if (portDetailETAInfo !== undefined && portDetailETAInfo.length > 0) {
                  portDetailETAInfo.forEach(portDetailETAInfo => {
                    const vslCd = portDetailETAInfo.vslCd
                    const voyNo = portDetailETAInfo.voyNo
                    const trmlCd = portDetailETAInfo.trmlCd
                    const rvsdEta = portDetailETAInfo.rvsdEta

                    this.portMarkerA.push({
                      tableVslCdA: vslCd,
                      tableVoyNoA: voyNo,
                      tableTrmlCdA: trmlCd,
                      tableSchEta: rvsdEta
                        })
                      })
                      }
                    }
                  })
            .catch(error => {
              console.log(error)
            })
          await vslScheduleInfo.getPortDetailETD(data)
          .then(response => {
            if (response.status === 200) {
              const resData = response.data
              const portDetailETDInfo = resData.portDetailETDInfo
              this.portMarkerB = []
              if (portDetailETDInfo !== undefined && portDetailETDInfo.length > 0) {
                portDetailETDInfo.forEach(portDetailETDInfo => {
                  const vslCd = portDetailETDInfo.vslCd
                  const voyNo = portDetailETDInfo.dpoVoyNo
                  const trmlCd = portDetailETDInfo.trmlCd
                  const rvsdEtd = portDetailETDInfo.rvsdEtd

                  this.portMarkerB.push({
                    tableVslCdB: vslCd,
                    tableVoyNoB: voyNo,
                    tableTrmlCdB: trmlCd,
                    tableSchEtd: rvsdEtd
                      })
                    })
                    }
                  }
                })
          .catch(error => {
            console.log(error)
          })
          await vslScheduleInfo.getPortAisETA(data)
          .then(response => {
            if (response.status === 200) {
              const resData = response.data
              const portAisETAInfo = resData.portAisETAInfo
              this.portMarkerC = []
              if (portAisETAInfo !== undefined && portAisETAInfo.length > 0) {
                portAisETAInfo.forEach(portAisETAInfo => {
                  const vslCd = portAisETAInfo.vslCd
                  const voyNo = portAisETAInfo.voyNo
                  const aisEta = portAisETAInfo.aisEta

                  this.portMarkerC.push({
                    tableVslCdC: vslCd,
                    tableVoyNoC: voyNo,
                    tableAisEta: aisEta
                      })
                    })
                    }
                  }
                })
          .catch(error => {
            console.log(error)
          })
          await vslScheduleInfo.getPortAisETD(data)
          .then(response => {
            if (response.status === 200) {
              const resData = response.data
              const portAisETDInfo = resData.portAisETDInfo
              this.portMarkerD = []
              if (portAisETDInfo !== undefined && portAisETDInfo.length > 0) {
                portAisETDInfo.forEach(portAisETDInfo => {
                  const vslCd = portAisETDInfo.vslCd
                  const voyNo = portAisETDInfo.voyNo

                  this.portMarkerD.push({
                    tableVslCdD: vslCd,
                    tableVoyNoD: voyNo
                      })
                    })
                    }
                  }
                })
          .catch(error => {
            console.log(error)
          })
          const lengthEta = this.portMarkerA.length
          const lengthEtd = this.portMarkerB.length
          const lengthAisEta = this.portMarkerC.length
          const lengthAisEtd = this.portMarkerD.length
          const tmpArr = [lengthEta, lengthEtd, lengthAisEta, lengthAisEtd]
          const Max = Math.max.apply(null, tmpArr)
          this.portMarker = []
          for (var i = 0; i < Max; i++) {
            this.portMarker.push({
              tableVslCdA: this.portMarkerA[i] !== undefined ? this.portMarkerA[i].tableVslCdA : '',
              tableVoyNoA: this.portMarkerA[i] !== undefined ? this.portMarkerA[i].tableVoyNoA : '',
              tableTrmlCdA: this.portMarkerA[i] !== undefined ? this.portMarkerA[i].tableTrmlCdA : '',
              tableSchEta: this.portMarkerA[i] !== undefined ? this.portMarkerA[i].tableSchEta : '',
              tableVslCdB: this.portMarkerB[i] !== undefined ? this.portMarkerB[i].tableVslCdB : '',
              tableVoyNoB: this.portMarkerB[i] !== undefined ? this.portMarkerB[i].tableVoyNoB : '',
              tableTrmlCdB: this.portMarkerB[i] !== undefined ? this.portMarkerB[i].tableTrmlCdB : '',
              tableSchEtd: this.portMarkerB[i] !== undefined ? this.portMarkerB[i].tableSchEtd : '',
              tableVslCdC: this.portMarkerC[i] !== undefined ? this.portMarkerC[i].tableVslCdC : '',
              tableVoyNoC: this.portMarkerC[i] !== undefined ? this.portMarkerC[i].tableVoyNoC : '',
              tableAisEta: this.portMarkerC[i] !== undefined ? this.portMarkerC[i].tableAisEta : '',
              tableVslCdD: this.portMarkerD[i] !== undefined ? this.portMarkerD[i].tableVslCdD : '',
              tableVoyNoD: this.portMarkerD[i] !== undefined ? this.portMarkerD[i].tableVoyNoD : ''
          })
          }
      } else if (type === 'V') {
          // this.checkTab.item2.check = true
          this.regParams.Type = true
          this.portMarkerA = []
          this.portMarkerB = []
          this.portMarkerC = []
          this.portMarkerD = []
          this.portMarker = []
          this.vslCd = this.markers[index].vslCd
          this.center = markerLatLng
          this.focusVessel.markerLatLng = this.center
          this.focusVessel[0].vslCd_voyNo = this.markers[index].vslCd + '/' + this.markers[index].voyNo
          this.focusVessel[1].vslNm = this.markers[index].vslNm
          this.focusVessel[2].nvgSts = this.markers[index].nvgSts
          this.focusVessel[3].aisEta = this.markers[index].aisEta
          this.focusVessel[4].aisDest = this.markers[index].aisDest
          this.focusVessel[5].spd = this.markers[index].spdOvgGrnd
          this.focusVessel[6].rteCd = this.markers[index].rteCd
          const data = {
            vslCd: this.markers[index].vslCd,
            rteCd: this.markers[index].rteCd,
            voyNo: this.markers[index].voyNo,
            portCd: this.markers[index].aisDest,
            drctn: ['E', 'W', 'N', 'S'].includes(this.markers[index].voyNo.slice(-1)) ? this.markers[index].voyNo.slice(-1) : this.markers[index].voyNo.slice(0, 1)
          }
          await vslScheduleInfo.getVesselPreiousRoute(data)
            .then(response => {
              if (response.status === 200) {
                const resData = response.data
                const VesselPreiousRoute = resData.vesselPreiousRoute
                this.polyline.latlngs = []
                var tmp = 'N/A'
                VesselPreiousRoute.forEach(item => {
                  for (let i = 191; i <= this.markers2.length - 1; i++) {
                    var ais_dest = item.aisDest
                    ais_dest = ais_dest.replace(/ /g, '')
                    ais_dest = ais_dest.substring(ais_dest.indexOf('>') + 1, ais_dest.length + 1)
                    if (ais_dest === this.markers2[i].ctrCd) {
                      tmp = this.markers2[i].plcCd
                      item.aisDest = tmp
                      break
                    }
                  }
                })
                VesselPreiousRoute.forEach(item => {
                  const lttd = item.lttd
                  const lngtd = item.lngtd
                  var ais_dest = item.aisDest
                  if (ais_dest === tmp) {
                    this.polyline.latlngs.push([lttd, lngtd])
                  }
                })
                }
              })
            .catch(error => {
              console.log(error)
            })
            const tmp = []
            await vslScheduleInfo.getVesselNextRoute(data)
            .then(response => {
              if (response.status === 200) {
                const resData = response.data
                const VesselNextRoute = resData.vesselNextRoute
                this.polylineN.latlngs = []
                if (VesselNextRoute !== undefined && VesselNextRoute.length > 0) {
                  VesselNextRoute.forEach(item => {
                    for (let i = 191; i <= this.markers2.length - 1; i++) {
                      var ais_dest = item.aisDest
                      ais_dest = ais_dest.replace(/ /g, '')
                      ais_dest = ais_dest.substring(ais_dest.indexOf('>') + 1, ais_dest.length + 1)
                      if (ais_dest === this.markers2[i].ctrCd) {
                        item.aisDest = this.markers2[i].plcCd
                        break
                      }
                    }
                  })
                }
                VesselNextRoute.forEach(item => {
                  const lttd = item.lttd
                  const lngtd = item.lngtd
                  var ais_dest = item.aisDest
                  if (ais_dest === this.focusVessel[4].aisDest) {
                    tmp.push(Math.sqrt(Math.pow((this.focusVessel.markerLatLng.lat - lttd), 2) + Math.pow((this.focusVessel.markerLatLng.lng - lngtd), 2)))
                    this.polylineN.latlngs.push([lttd, lngtd])
                  }
                })
                }
              })
            .catch(error => {
              console.log(error)
            })
            this.minIndex = tmp.indexOf(Math.min.apply(null, tmp))
            this.polylineN.latlngs = this.polylineN.latlngs.slice(0, this.minIndex)
            this.polylineN.latlngs.push([this.focusVessel.markerLatLng.lat, this.focusVessel.markerLatLng.lng])
        } else {
          const data = {
            portCd: this.markers2[index].plcCd
          }
          this.regParams.Type = false
          this.focusPort[0].portCd = this.markers2[index].plcCd
          this.focusPort[1].portNm = this.markers2[index].portNm
          this.focusPort[2].ctrCd = this.markers2[index].ctrCd
        }
        this.checkBool.graph = false
        this.checkBool.table = true
      }
    }
  }
</script>

<style scoped>
@import "../../assets/css/sol.css"
</style>
